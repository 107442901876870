import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Close from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core";
import Donate from './';
import withAuthorisation from "../../App/withAuthorisation";
import {isDE} from "../../../constants/domains";
import {useTranslation} from "react-i18next";
import DonateStep1 from "./donateStep1";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "2rem 1rem",
    },
    closeButton: {
        alignSelf: "flex-end",
        fontSize: "1rem",
        fontWeight: 600,
        letterSpacing: "0.05rem",
        marginBottom: "2.5rem",
        minHeight: 36
    },
    donationContainer: {
        maxWidth: 400,
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingBottom: 100
    },
    closeIcon: {
        marginLeft: "0.5rem",
    },
    titleText: {
        marginLeft: 24,
        marginRight: 24
    },
    title: {
        marginBottom: "1rem",
        fontSize: "2.5rem",
        fontWeight: 600,
        letterSpacing: "0.1rem",
    },
    step: {
        color: "#494949",
        fontSize: 22
    },
    donate: {
        alignSelf: "center",
        textAlign: "center",
    }
}));

const condition = authUser => !!authUser;

const DialogWithAuthorisation = withAuthorisation(condition)(Dialog);

export default ({open, handleClose, amount, user, campaign}) => {
    const classes = useStyles();
    const [step, setStep] = useState(isDE ? 1 : 2);

    const {t} = useTranslation();

    if (!user && !open) {
        return null;
    }

    return <DialogWithAuthorisation fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box className={classes.container}>
            <Button onClick={handleClose} className={classes.closeButton}>
                Close
                <Close className={classes.closeIcon}/>
            </Button>
        </Box>
        <Box className={classes.donationContainer}>
            {step === 1 && <>
                <Box className={classes.titleText}>
                    <Typography variant="caption" className={classes.step}>STEP 1 OF 2</Typography>
                    <Typography className={classes.title}>Your details</Typography>
                    <Typography color="textPrimary">
                        Thank you for choosing to support our campaign. In order to continue please verify the following:
                    </Typography>
                </Box>
                <DonateStep1 setStep={setStep}/>
            </>
            }
            {step === 2 && <>
                <Box className={classes.titleText}>
                    {isDE &&
                    <Typography variant="caption" className={classes.step}>STEP 2 OF 2</Typography>
                    }
                    <Typography className={classes.title}>{t('campaign.donate.title')}</Typography>
                    <Typography color="textPrimary">Thank you for choosing to support this campaign. Please enter your
                        details below.</Typography>
                </Box>
                <Donate className={classes.donate} handleClose={handleClose} amount={amount} user={user}
                        campaign={campaign}/>
            </>
            }
        </Box>

    </DialogWithAuthorisation>
};
