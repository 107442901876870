import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import deepPurple from '@material-ui/core/colors/deepPurple';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    avatarItem: {
        margin: 'auto'
    },
    avatar: {
        backgroundColor: deepPurple[900]
    },
    padded: {
        marginBottom: 20,
        width: '100%',
    }
}));

/**
 * 
 * @param {Object} props
 * @param {{name: string, amount: number, created: number}[]} props.donations 
 */
export default function DonationsList({ donations }) {
    const classes = useStyles();
    if (!donations || !donations.length) {
        return null;
    }

    return (
        <Paper className={classes.padded}>
            <List className={classes.root}>
                {(donations || []).reduce((result, {name, created, amount}, i) => {
                    const avatar = name.split(' ').map((nPart)=>{
                        return nPart.substr(0, 1).toUpperCase()
                    }).join('').substr(0, 2);
                    
                    if (i !== 0) {
                        result = result.concat(<Divider key={`divider${i}`} component="li" />)
                    }

                    return result.concat(<ListItem key={`listItem${i}`} alignItems="flex-start">
                        <ListItemAvatar className={classes.avatarItem}>
                            <Avatar className={classes.avatar}>{avatar}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={`$${amount}`}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {name}
                                    </Typography>
                                    <Typography>
                                        {moment(created * 1000).fromNow()}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>)
                }, [])}
            </List>
        </Paper>
    );
}
