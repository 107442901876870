import React from "react";

export const useStateWithSessionStorage = sessionStorageKey => {
    const [value, setValue] = React.useState(
        sessionStorage.getItem(sessionStorageKey) || ''
    );

    React.useEffect(() => {
        sessionStorage.setItem(sessionStorageKey, value || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return [value, setValue];
};