import {
    LIST_EVENT_EMAILS_BEGIN,
    LIST_EVENT_EMAILS_FAILURE,
    LIST_EVENT_EMAILS_SUCCESS,
    UPDATE_EVENT_EMAIL_BEGIN,
    UPDATE_EVENT_EMAIL_FAILURE,
    UPDATE_EVENT_EMAIL_SUCCESS,
} from "./eventEmailActions";

const initialState = {
    emails: null,
    loading: false,
    error: null,
};

export default function eventEmailsReducer(state = initialState, action) {

    switch (action.type) {
        case LIST_EVENT_EMAILS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LIST_EVENT_EMAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                emails: action.payload.eventEmails
            };
        case LIST_EVENT_EMAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case UPDATE_EVENT_EMAIL_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case UPDATE_EVENT_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                emails: [action.payload.eventEmail]
            };

        case UPDATE_EVENT_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        default:
            return state;
    }
}
