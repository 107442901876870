import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {Dialog, IconButton, makeStyles} from "@material-ui/core";
import signalLogo from "../../images/SignalLogo.png";
import {copyToClipboard} from "../../utils/clipboard";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
    shareLabel: {
        fontSize: '0.85rem',
        color: "#a2a2a2",
        marginTop: 5,
        textAlign: 'center',
    },
    signalLogo: {
        width: 48,
        height: 48,
        borderRadius: 24,
        cursor: "pointer",
        marginTop: 0
    },
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    container: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    button: {
        width: "100%"
    },
    centered: {
        display: "flex",
        justifyContent: "center"
    }
}));
const OpenSignalModal = ({open, onOK, onCancel}) => {

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onCancel}>
            <Typography variant="h6" color="textPrimary" align={"right"}>
                <IconButton onClick={onCancel}><CloseIcon/></IconButton>
            </Typography>
            <Grid container className={classes.root}>
                <Grid container item xs={12} spacing={4}
                      className={classnames(classes.container, classes.centered)}>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textPrimary">
                            The messages has been copied to clipboard,
                            if you have <a href={'https://signal.org'} target={"_blank"}>Signal App</a> installed press
                            Open to switch to Signal App
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onOK}
                                size="large"
                                variant="contained"
                                color="primary"
                                className={classes.button}>
                            Open Signal App
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
};
const SignalShareButton = ({link, message, beforeOnClick, className}) => {
    const [open, setOpenModal] = useState(false)
    const classes = useStyles();
    const handleClick = () => {
        if (link) {
            const text = `${message}\n${link}`
            copyToClipboard(text)
            setOpenModal(true)
        }
        return false;
    }

    const hideModal = () => setOpenModal(() => false)

    const openSignal = () => {
        beforeOnClick && beforeOnClick()
        window.open(` sgnl://signal.group/?message=${encodeURIComponent(message)}}#-1`, '_blank')
        hideModal()
        return false;
    }
    return (<>
            <OpenSignalModal open={open} onCancel={hideModal} onOK={openSignal}/>
            <div className={className}
                 onClick={handleClick}>
                <img src={signalLogo} className={classes.signalLogo} alt="signal"/>
                <Typography className={classes.shareLabel}>Signal</Typography>
            </div>
        </>

    )
}

export default SignalShareButton;