import { apolloClient } from '../../graphql'
import gql from "graphql-tag";
import { push } from 'connected-react-router'
import * as routes from '../../constants/routes';
import { replaceParams } from '../../utils/link-utils';

export const LOAD_CAMPAIGN_EMAIL_BEGIN = "LOAD_CAMPAIGN_EMAIL_BEGIN";
export const LOAD_CAMPAIGN_EMAIL_SUCCESS = "LOAD_CAMPAIGN_EMAIL_SUCCESS";
export const LOAD_CAMPAIGN_EMAIL_FAILURE = "LOAD_CAMPAIGN_EMAIL_FAILURE";
export const LIST_CAMPAIGN_EMAILS_BEGIN = "LIST_CAMPAIGN_EMAILS_BEGIN";
export const LIST_CAMPAIGN_EMAILS_SUCCESS = "LIST_CAMPAIGN_EMAILS_SUCCESS";
export const LIST_CAMPAIGN_EMAILS_FAILURE = "LIST_CAMPAIGN_EMAILS_FAILURE";
export const UPDATE_CAMPAIGN_EMAIL_BEGIN = "UPDATE_CAMPAIGN_EMAIL_BEGIN";
export const UPDATE_CAMPAIGN_EMAIL_SUCCESS = "UPDATE_CAMPAIGN_EMAIL_SUCCESS";
export const UPDATE_CAMPAIGN_EMAIL_FAILURE = "UPDATE_CAMPAIGN_EMAIL_FAILURE";
export const CREATE_CAMPAIGN_EMAIL_BEGIN = "CREATE_CAMPAIGN_EMAIL_BEGIN";
export const CREATE_CAMPAIGN_EMAIL_SUCCESS = "CREATE_CAMPAIGN_EMAIL_SUCCESS";
export const CREATE_CAMPAIGN_EMAIL_FAILURE = "CREATE_CAMPAIGN_EMAIL_FAILURE";
export const SEND_TEST_EMAIL_BEGIN = "SEND_TEST_EMAIL_BEGIN";
export const SEND_TEST_EMAIL_SUCCESS = "SEND_TEST_EMAIL_SUCCESS";
export const SEND_TEST_EMAIL_FAILURE = "SEND_TEST_EMAIL_FAILURE";
export const SEND_CAMPAIGN_EMAIL_BEGIN = "SEND_CAMPAIGN_EMAIL_BEGIN";
export const SEND_CAMPAIGN_EMAIL_SUCCESS = "SEND_CAMPAIGN_EMAIL_SUCCESS";
export const SEND_CAMPAIGN_EMAIL_FAILURE = "SEND_CAMPAIGN_EMAIL_FAILURE";
export const DELETE_CAMPAIGN_EMAIL_BEGIN = "DELETE_CAMPAIGN_EMAIL_BEGIN";
export const DELETE_CAMPAIGN_EMAIL_SUCCESS = "DELETE_CAMPAIGN_EMAIL_SUCCESS";
export const DELETE_CAMPAIGN_EMAIL_FAILURE = "DELETE_CAMPAIGN_EMAIL_FAILURE";

export const loadCampaignEmailBegin = () => ({
    type: LOAD_CAMPAIGN_EMAIL_BEGIN
});

export const loadCampaignEmailSuccess = (campaignWithEmailAndCounts) => ({
    type: LOAD_CAMPAIGN_EMAIL_SUCCESS,
    payload: { campaignWithEmailAndCounts }
});

export const loadCampaignEmailFailure = error => ({
    type: LOAD_CAMPAIGN_EMAIL_FAILURE,
    payload: { error }
});

const LOAD_CAMPAIGN_EMAIL_QUERY = gql`
  query LoadCampaignEmail($organisationId: Int!, $campaignId: Int!, $campaignEmailId: Int!) {
    campaign(organisationId: $organisationId, campaignId: $campaignId) {
        id
        campaignEmails(campaignEmailId: $campaignEmailId) {
            id
            campaignId
            subject
            title
            message
            status
            replyTo
            sendOption
            attachMediaKit
            customContacts {
                email,
                firstName
            }
            stats {
                sentCount,
                openCount,
                clickCount,
                deliveryCount,
                openRatio,
                clickRatio,
                deliveryRatio,
            }
            html
        }
        organisation {
            emailContactsCount
            emailDailyLimit
        }
    }
  }
`;

export function loadCampaignEmail(organisationId, campaignId, campaignEmailId) {
    return async dispatch => {
        dispatch(loadCampaignEmailBegin());
        try {
            const campaignEmailResponse = await apolloClient.query({
                query: LOAD_CAMPAIGN_EMAIL_QUERY,
                variables: {
                    organisationId,
                    campaignId,
                    campaignEmailId
                }
            });
            const campaignWithEmailAndCounts = campaignEmailResponse.data.campaign;
            dispatch(loadCampaignEmailSuccess(campaignWithEmailAndCounts));
        } catch (error) {
            dispatch(loadCampaignEmailFailure(error));
        }
    };
}

export const listCampaignEmailsBegin = () => ({
    type: LIST_CAMPAIGN_EMAILS_BEGIN
});

export const listCampaignEmailsSuccess = (campaignEmails) => ({
    type: LIST_CAMPAIGN_EMAILS_SUCCESS,
    payload: {campaignEmails}
});

export const listCampaignEmailsFailure = error => ({
    type: LIST_CAMPAIGN_EMAILS_FAILURE,
    payload: {error}
});

const LIST_CAMPAIGN_EMAILS_QUERY = gql`
  query ListCampaignEmails($organisationId: Int!, $campaignId: Int!) {
    campaign(organisationId: $organisationId, campaignId: $campaignId) {
        campaignEmails {
            id
            campaignId
            subject
            title
            message
            status
            sendOption
            replyTo
            attachMediaKit
            customContacts {
                email,
                firstName
            }
            stats {
                sentCount,
                openCount,
                clickCount,
                deliveryCount,
                openRatio,
                clickRatio,
                deliveryRatio,
            }
        }
    }
  }
`;

export function listCampaignEmails(organisationId, campaignId) {
    return async dispatch => {
        dispatch(listCampaignEmailsBegin());
        try {
            const listCampaignEmailsResponse = await apolloClient.query({
                query: LIST_CAMPAIGN_EMAILS_QUERY,
                variables: {
                    organisationId,
                    campaignId
                }
            });
            const campaignEmails = listCampaignEmailsResponse.data.campaign.campaignEmails;
            dispatch(listCampaignEmailsSuccess(campaignEmails));
        } catch (error) {
            dispatch(listCampaignEmailsFailure(error));
        }
    };
}


const CREATE_CAMPAIGN_EMAIL_MUTATION = gql`
  mutation CreateCampaignEmail($organisationId: Int!, $campaignId: Int!) {
    createCampaignEmail(organisationId: $organisationId, campaignId: $campaignId) {
        id
    }
  }
`;

export const createCampaignEmailBegin = () => ({
    type: CREATE_CAMPAIGN_EMAIL_BEGIN
});

export const createCampaignEmailSuccess = (campaignEmail) => ({
    type: CREATE_CAMPAIGN_EMAIL_SUCCESS,
    payload: { campaignEmail }
});

export const createCampaignEmailFailure = error => ({
    type: CREATE_CAMPAIGN_EMAIL_FAILURE,
    payload: {error}
});

export function createDraftCampaignEmail(organisationId, campaignId) {
    return async dispatch => {
        dispatch(createCampaignEmailBegin());
        try {
            const createCampaignEmailResponse = await apolloClient.mutate({
                mutation: CREATE_CAMPAIGN_EMAIL_MUTATION,
                variables: {
                    organisationId: organisationId,
                    campaignId: campaignId
                }
            });
            const createdCampaignEmail = createCampaignEmailResponse.data.createCampaignEmail;
            dispatch(createCampaignEmailSuccess(createdCampaignEmail));
            dispatch(push(replaceParams(routes.CAMPAIGN_EMAIL, {
                ":id": campaignId,
                ":emailId": createdCampaignEmail.id
            })));
        } catch (error) {
            console.log(error);
            dispatch(createCampaignEmailFailure(error));
        }
    };
}

const SEND_TEST_CAMPAIGN_EMAIL_MUTATION = gql`
  mutation TestCampaignEmail($organisationId: Int!, $campaignId: Int!, $campaignEmailId: Int!, $testEmail: String!) {
    testCampaignEmail(organisationId: $organisationId, campaignId: $campaignId, campaignEmailId: $campaignEmailId, testEmail: $testEmail) {
        success
    }
  }
`;

export const sendTestEmailBegin = () => ({
    type: SEND_TEST_EMAIL_BEGIN
});

export const sendTestEmailSuccess = (campaignEmail) => ({
    type: SEND_TEST_EMAIL_SUCCESS,
    payload: { campaignEmail }
});

export const sendTestEmailFailure = error => ({
    type: SEND_TEST_EMAIL_FAILURE,
    payload: {error}
});

export function sendTestEmail(organisationId, campaignId, campaignEmailId, testEmail) {
    return async dispatch => {
        dispatch(sendTestEmailBegin());
        try {
            const sendTestEmailResponse = await apolloClient.mutate({
                mutation: SEND_TEST_CAMPAIGN_EMAIL_MUTATION,
                variables: {
                    organisationId: organisationId,
                    campaignId: campaignId,
                    campaignEmailId: campaignEmailId,
                    testEmail: testEmail
                }
            });
            const emailSent = sendTestEmailResponse.data.testCampaignEmail.success;
            dispatch(sendTestEmailSuccess(emailSent));
            return emailSent;
        } catch (error) {
            dispatch(sendTestEmailFailure(error));
            throw new Error(error);
        }
    };
}

const DELETE_CAMPAIGN_EMAIL_MUTATION = gql`
  mutation DeleteCampaignEmail($organisationId: Int!, $campaignId: Int!, $campaignEmailId: Int!) {
    deleteCampaignEmail(organisationId: $organisationId, campaignId: $campaignId, campaignEmailId: $campaignEmailId) {
        success
    }
  }
`;

export const deleteCampaignEmailBegin = () => ({
    type: DELETE_CAMPAIGN_EMAIL_BEGIN
});

export const deleteCampaignEmailSuccess = (success) => ({
    type: DELETE_CAMPAIGN_EMAIL_SUCCESS,
});

export const deleteCampaignEmailFailure = error => ({
    type: DELETE_CAMPAIGN_EMAIL_FAILURE,
    payload: {error}
});

export function deleteCampaignEmail(organisationId, campaignId, campaignEmailId) {
    return async dispatch => {
        dispatch(deleteCampaignEmailBegin());
        try {
            const sendTestEmailResponse = await apolloClient.mutate({
                mutation: DELETE_CAMPAIGN_EMAIL_MUTATION,
                variables: {
                    organisationId: organisationId,
                    campaignId: campaignId,
                    campaignEmailId: campaignEmailId
                }
            });
            const emailDeleted = sendTestEmailResponse.data.deleteCampaignEmail.success;
            dispatch(deleteCampaignEmailSuccess(emailDeleted));
            return emailDeleted;
        } catch (error) {
            console.log(error);
            dispatch(deleteCampaignEmailFailure(error));
            throw new Error(error);
        }
    };
}


const SEND_CAMPAIGN_EMAIL_MUTATION = gql`
  mutation DeleteCampaignEmail($organisationId: Int!, $campaignId: Int!, $campaignEmailId: Int!) {
    sendCampaignEmail(organisationId: $organisationId, campaignId: $campaignId, campaignEmailId: $campaignEmailId) {
        success,
        errors {
            code,
            message
        }
    }
  }
`;

export const sendCampaignEmailBegin = () => ({
    type: SEND_CAMPAIGN_EMAIL_BEGIN
});

export const sendCampaignEmailSuccess = (success) => ({
    type: SEND_CAMPAIGN_EMAIL_SUCCESS,
});

export const sendCampaignEmailFailure = error => ({
    type: SEND_CAMPAIGN_EMAIL_FAILURE,
    payload: {error}
});

export function sendCampaignEmail(organisationId, campaignId, campaignEmailId) {
    return async dispatch => {
        dispatch(sendCampaignEmailBegin());
        try {
            const sendCampaignEmailResponse = await apolloClient.mutate({
                mutation: SEND_CAMPAIGN_EMAIL_MUTATION,
                variables: {
                    organisationId: organisationId,
                    campaignId: campaignId,
                    campaignEmailId: campaignEmailId
                }
            });
            const response = sendCampaignEmailResponse.data.sendCampaignEmail;
            if (response.errors) {
                throw response.errors[0]
            }
            return response;
        } catch (error) {
            dispatch(sendCampaignEmailFailure(error));
            throw error;
        }
    };
}


const UPDATE_CAMPAIGN_EMAIL_MUTATION = gql`
  mutation UpdateCampaignEmail($organisationId: Int!, $campaignId: Int!, $campaignEmailInput: CampaignEmailInput!) {
    updateCampaignEmail(organisationId: $organisationId, campaignId: $campaignId, campaignEmailInput: $campaignEmailInput) {
        id
    }
  }
`;

export const updateCampaignEmailBegin = () => ({
    type: UPDATE_CAMPAIGN_EMAIL_BEGIN
});

export const updateCampaignEmailSuccess = (success) => ({
    type: UPDATE_CAMPAIGN_EMAIL_SUCCESS,
});

export const updateCampaignEmailFailure = error => ({
    type: UPDATE_CAMPAIGN_EMAIL_FAILURE,
    payload: { error }
});

export function updateCampaignEmail(organisationId, campaignId, campaignEmail) {
    return async dispatch => {
        dispatch(updateCampaignEmailBegin());
        try {
            const input = {...campaignEmail};
            delete input.stats;
            delete input.html;
            const updateCampaignEmailResponse = await apolloClient.mutate({
                mutation: UPDATE_CAMPAIGN_EMAIL_MUTATION,
                variables: {
                    organisationId,
                    campaignId,
                    campaignEmailInput: input,
                }
            });
            const updatedCampaign = updateCampaignEmailResponse.data.updateCampaignEmail;
            dispatch(updateCampaignEmailSuccess(updatedCampaign));
        } catch (error) {
            console.log(error);
            dispatch(updateCampaignEmailFailure(error));
        }
    };
}
