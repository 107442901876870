import { apolloClient } from '../../graphql'
import gql from "graphql-tag";

export const LOAD_USER_NOTIFICATION_SETTINGS_SUCCESS = "LOAD_USER_NOTIFICATION_SETTINGS_SUCCESS";
export const LOAD_USER_NOTIFICATION_SETTINGS_BEGIN = "LOAD_USER_NOTIFICATION_SETTINGS_BEGIN";
export const LOAD_USER_NOTIFICATION_SETTINGS_FAILURE = "LOAD_USER_NOTIFICATION_SETTINGS_FAILURE";
export const UPDATE_USER_NOTIFICATION_SETTINGS_BEGIN = "UPDATE_USER_NOTIFICATION_SETTINGS_BEGIN";
export const UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE = "UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE";
export const UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS = "UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS";

const GET_USER_NOTIFICATION_SETTINGS_QUERY = gql`
    query GetUser($userId: String!) {
        user(userId: $userId) {
            userNotificationSettings {
                organisationId
                campaignEmailsEnabled
                campaignSmsEnabled
            }
        }
    }
`
export const loadUserNotificationSettingsSuccess = (settings) => ({
    type: LOAD_USER_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { settings }
});

export const loadUserNotificationSettingsBegin = (settings) => ({
    type: LOAD_USER_NOTIFICATION_SETTINGS_BEGIN,
    payload: { settings }
});

export const loadUserNotificationSettingsFailure = error => ({
    type: LOAD_USER_NOTIFICATION_SETTINGS_FAILURE,
    payload: { error }
});

export function getUserNotificationSettings(userId) {
    return async dispatch => {
        try {
            dispatch(loadUserNotificationSettingsBegin());
            const response = await apolloClient.query({
                query: GET_USER_NOTIFICATION_SETTINGS_QUERY,
                variables: {
                    userId
                }
            });
            const settings = response.data.user.userNotificationSettings;
            dispatch(loadUserNotificationSettingsSuccess(settings));
        } catch (error) {
            dispatch(loadUserNotificationSettingsFailure(error));
        }
    };
}


const UPDATE_USER_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation UpdateUserNotificationSettings($userNotificationSettings: UserNotificationSettingsInput!) {
    updateUserNotificationSettings(userNotificationSettings: $userNotificationSettings) {
        campaignEmailsEnabled
        campaignSmsEnabled
        organisationId
    }
  }
`;

export const updateUserNotificationSettingsSuccess = (settings) => ({
    type: UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { settings }
});

export const updateUserNotificationSettingsBegin = (settings) => ({
    type: UPDATE_USER_NOTIFICATION_SETTINGS_BEGIN,
    payload: { settings }
});

export const updateUserNotificationSettingsFailure = error => ({
    type: UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE,
    payload: { error }
});

export function updateUserNotificationSettings(organisationId, campaignEmailsEnabled, campaignSmsEnabled, optInProperties) {
    return async (dispatch) => {
        dispatch(updateUserNotificationSettingsBegin());
        try {
            const response = await apolloClient.mutate({
                mutation: UPDATE_USER_NOTIFICATION_SETTINGS_MUTATION,
                variables: {
                    userNotificationSettings: {
                        organisationId,
                        campaignEmailsEnabled,
                        campaignSmsEnabled,
                        ...optInProperties
                    },
                }
            });
            dispatch(updateUserNotificationSettingsSuccess(response.data.updateUserNotificationSettings));
        } catch (error) {
            dispatch(updateUserNotificationSettingsFailure(error));
        }
    };
}

export const SET_SKIP_SIGN_IN_REDIRECT = "SET_SKIP_SIGN_IN_REDIRECT";

export const setSkipSignInRedirect = (skipSignInRedirect) => ({
    type: SET_SKIP_SIGN_IN_REDIRECT,
    payload: { skipSignInRedirect }
});
