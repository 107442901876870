import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import gabLogo from "../../images/GabLogo.svg";

const useStyles = makeStyles(() => ({
    shareLabel: {
        fontSize: '0.85rem',
        color: "#a2a2a2",
        marginTop: 5,
        textAlign: 'center',
    },
    gabLogo: {
        width: 48,
        height: 48,
        borderRadius: 24,
        cursor: "pointer",
        marginTop: 0
    }
}));

const GabShareButton = ({link, message, beforeOnClick, className}) => {
    const classes = useStyles();
    const handleClick = () => {

        if (link) {
            beforeOnClick && beforeOnClick()
            window.open(`https://gab.com/compose?url=${encodeURIComponent(link)}&text=${encodeURIComponent(message)}`, '_blank', 'width=500,height=500')
        }
        return false;
    }
    return (
        <div className={className}
             onClick={handleClick}>
            <img src={gabLogo} className={classes.gabLogo} alt="gab"/>
            <Typography className={classes.shareLabel}>Gab</Typography>
        </div>
    )
}

export default GabShareButton;