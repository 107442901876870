import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/styles';
import {Route} from 'react-router-dom';
import {Switch} from 'react-router';

import withAuthentication from "./withAuthentication";
import * as ROUTES from '../../constants/routes';
import ViewCampaignPage from "../ViewCampaign/page";
import CampaignIntro from "../ViewCampaign/intro";
import useTheme from './themes';
import LoadableAsyncRoutes from './routes'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-162568307-1', {
    alwaysSendToDefaultTracker: false,
    gaOptions: {
        name: 'freerootsTracker'
    }
});

const App = () => {

    useEffect(() => {
        LoadableAsyncRoutes.preload();
    }, []);

    const [theme] = useTheme();

    return <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Route path="/" render={({location}) => {
            ReactGA.pageview(location.pathname + location.search, ['freerootsTracker']);
            return null;
        }} />
        <Switch>
            <Route exact path={ROUTES.VIEW_CAMPAIGN_SHORT} component={ViewCampaignPage}/>
            <Route exact path={ROUTES.VIEW_CAMPAIGN} component={ViewCampaignPage}/>
            <Route exact path={ROUTES.VIEW_INITIATIVE} component={ViewCampaignPage}/>
            <Route exact path={ROUTES.CAMPAIGN_INTRO} component={CampaignIntro}/>
            <Route path="*" component={LoadableAsyncRoutes}/>
        </Switch>
    </ThemeProvider>
};

export default withAuthentication(App);
