import {apolloClient} from '../../graphql'
import gql from "graphql-tag";

const LOAD_INDIVIDUAL_POLICYMAKERS_TREE_QUERY = gql`
  query LoadIndividualPolicymakersTree($organisationId: Int!) {
    individualPolicymakersByCategoryTree {
        individualsNoCategory {
          id
          name
          role
        }
        categories {
          name
          individuals {
            id
            name
            role
          }
        }
    }
    organisation(organisationId: $organisationId) {
        individualPolicymakers {
            id
            name
            greetingName
            role
            email
            phone
            twitter
            facebook
            imageUrl
            displayOrder
            categoryId
            deleted
        }
    }
  }
`;

export function loadIndividualPolicymakersTree(organisationId) {
    return async dispatch => {
        dispatch(loadIndividualPolicymakersBegin());
        try {
            const loadIndividualPolicymakersResponse = await apolloClient.query({
                query: LOAD_INDIVIDUAL_POLICYMAKERS_TREE_QUERY,
                variables: {
                    organisationId
                }
            });
            const response = loadIndividualPolicymakersResponse.data;
            dispatch(loadIndividualPolicymakersSuccess(response));
        } catch (error) {
            dispatch(loadIndividualPolicymakersFailure(error));
        }
    };
}

const CREATE_INDIVIDUAL_POLICYMAKER_MUTATION = gql`
  mutation CreateIndividualPolicymakerForOrganisation($organisationId: Int!, $individualPolicymakerInput: IndividualPolicymakerInput!) {
    createIndividualPolicymakerForOrganisation(organisationId: $organisationId, individualPolicymakerInput: $individualPolicymakerInput) {
        id
        name
        greetingName
        role
        email
        phone
        twitter
        facebook
        imageUrl
        displayOrder
        categoryId
        deleted
    }
  }
`;

export function createIndividualPolicymaker(organisationId, policymaker) {
    return async dispatch => {
        dispatch(createIndividualPolicymakerBegin());
        try {
            const createIndividualPolicymakerResponse = await apolloClient.mutate({
                mutation: CREATE_INDIVIDUAL_POLICYMAKER_MUTATION,
                variables: {
                    organisationId: organisationId,
                    individualPolicymakerInput: policymaker
                }
            });
            const createdPolicymaker = createIndividualPolicymakerResponse.data.createIndividualPolicymakerForOrganisation;
            dispatch(createIndividualPolicymakerSuccess(createdPolicymaker));
        } catch (error) {
            dispatch(createIndividualPolicymakerFailure(error));
        }
    };
}

const UPDATE_INDIVIDUAL_POLICYMAKER_MUTATION = gql`
  mutation UpdateIndividualPolicymakerForOrganisation($organisationId: Int!, $updateIndividualPolicymakerInput: UpdateIndividualPolicymakerInput!) {
    updateIndividualPolicymakerForOrganisation(organisationId: $organisationId, updateIndividualPolicymakerInput: $updateIndividualPolicymakerInput) {
        id
        name
        greetingName
        role
        email
        phone
        twitter
        facebook
        imageUrl
        displayOrder
        categoryId
        deleted
    }
  }
`;

export function updateIndividualPolicymaker(organisationId, policymaker) {
    return async dispatch => {
        dispatch(updateIndividualPolicymakerBegin());
        try {
            const updateIndividualPolicymakerResponse = await apolloClient.mutate({
                mutation: UPDATE_INDIVIDUAL_POLICYMAKER_MUTATION,
                variables: {
                    organisationId: organisationId,
                    updateIndividualPolicymakerInput: policymaker
                }
            });
            const updatedPolicymaker = updateIndividualPolicymakerResponse.data.updateIndividualPolicymakerForOrganisation;
            dispatch(updateIndividualPolicymakerSuccess(updatedPolicymaker));
        } catch (error) {
            dispatch(updateIndividualPolicymakerFailure(error));
        }
    };
}

export const LOAD_INDIVIDUAL_POLICYMAKERS_BEGIN = "LOAD_INDIVIDUAL_POLICYMAKERS_BEGIN";
export const LOAD_INDIVIDUAL_POLICYMAKERS_SUCCESS = "LOAD_INDIVIDUAL_POLICYMAKERS_SUCCESS";
export const LOAD_INDIVIDUAL_POLICYMAKERS_FAILURE = "LOAD_INDIVIDUAL_POLICYMAKERS_FAILURE";

export const CREATE_INDIVIDUAL_POLICYMAKER_BEGIN = "CREATE_INDIVIDUAL_POLICYMAKER_BEGIN";
export const CREATE_INDIVIDUAL_POLICYMAKER_SUCCESS = "CREATE_INDIVIDUAL_POLICYMAKER_SUCCESS";
export const CREATE_INDIVIDUAL_POLICYMAKER_FAILURE = "CREATE_INDIVIDUAL_POLICYMAKER_FAILURE";

export const UPDATE_INDIVIDUAL_POLICYMAKER_BEGIN = "UPDATE_INDIVIDUAL_POLICYMAKER_BEGIN";
export const UPDATE_INDIVIDUAL_POLICYMAKER_SUCCESS = "UPDATE_INDIVIDUAL_POLICYMAKER_SUCCESS";
export const UPDATE_INDIVIDUAL_POLICYMAKER_FAILURE = "UPDATE_INDIVIDUAL_POLICYMAKER_FAILURE";

export const loadIndividualPolicymakersSuccess = (response) => ({
    type: LOAD_INDIVIDUAL_POLICYMAKERS_SUCCESS,
    payload: {
        individualPolicymakersTree: response.individualPolicymakersByCategoryTree,
        individualPolicymakersForOrganisation: response.organisation.individualPolicymakers
    }
});

export const loadIndividualPolicymakersFailure = error => ({
    type: LOAD_INDIVIDUAL_POLICYMAKERS_FAILURE,
    payload: {error}
});

export const loadIndividualPolicymakersBegin = () => ({
    type: LOAD_INDIVIDUAL_POLICYMAKERS_BEGIN
});

export const createIndividualPolicymakerBegin = () => ({
    type: CREATE_INDIVIDUAL_POLICYMAKER_BEGIN
});

export const createIndividualPolicymakerSuccess = (policymaker) => ({
    type: CREATE_INDIVIDUAL_POLICYMAKER_SUCCESS,
    payload: {policymaker}
});

export const createIndividualPolicymakerFailure = error => ({
    type: CREATE_INDIVIDUAL_POLICYMAKER_FAILURE,
    payload: {error}
});

export const updateIndividualPolicymakerBegin = () => ({
    type: UPDATE_INDIVIDUAL_POLICYMAKER_BEGIN
});

export const updateIndividualPolicymakerSuccess = (policymaker) => ({
    type: UPDATE_INDIVIDUAL_POLICYMAKER_SUCCESS,
    payload: {policymaker}
});

export const updateIndividualPolicymakerFailure = error => ({
    type: UPDATE_INDIVIDUAL_POLICYMAKER_FAILURE,
    payload: {error}
});

const UPLOAD_CSV_MUTATION = gql`
    mutation UploadIndividualPolicymakersCsv ($organisationId: Int!, $file: Upload!) { 
        uploadIndividualPolicymakersCsv(organisationId: $organisationId, file: $file) {
            success
            errors {
              code
              message
            }
        }
    }
`;

export async function uploadCsv(organisationId, file) {
    const uploadCsvResponse = await apolloClient.mutate({
        mutation: UPLOAD_CSV_MUTATION,
        variables: {
            organisationId,
            file
        }
    });
    const result = uploadCsvResponse.data.uploadIndividualPolicymakersCsv;
    if (result.success) {
        return true
    } else {
        throw result.errors[0];
    }
}