import 'intersection-observer';
import React, {useEffect, useState} from 'react';
import {compose} from 'recompose';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import IntersectionVisible from 'react-intersection-visible';
import {
    CopyButton,
    EmailButton,
    FacebookButton,
    GabButton,
    LinkedinButton,
    ParlerButton, SignalButton, TelegramButton,
    TwitterButton
} from "./shareButtons";
import {ActionsSection} from "./common";
import {useCountUp} from "react-countup/build";
import Link from "@material-ui/core/Link";
import images from "../../images/domainSpecificImages";
import DonationsList from './donations/donationsList';
import {isDonationsEnabled} from './donations/utils';
import * as ROUTES from "../../constants/routes";
import FreeRootsLogo from "../Landing/freeRootsLogo";
import classNames from "classnames";
import verifiedbadge from "../../images/verifiedbadge.svg";
import {useTranslation} from "react-i18next";
import LinkifyExternal from "../Common/Linkify";

const colors = {
    greyish: '#a2a2a2',
    darkGreyish: '#595c63',
};

const useStyles = makeStyles(theme => ({
    container: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    backgroundImage: {
        position: 'fixed',
        top: '0',
        objectFit: 'cover',
        width: '100vw',
        height: '100vh',
        zIndex: -10,
    },
    campaignContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#ffffff',
        padding: '0 20px 40px 20px',
        background: theme.VividTemplate.campaignContainer.background,
    },
    spacer: {
        [theme.breakpoints.up('sm')]: {
            width: 181
        },
        [theme.breakpoints.down('xs')]: {
            width: 34
        },
    },
    actionsTakenBox: {
        marginTop: 15,
    },
    actionsTakenNumber: {
        display: "inline",
        fontWeight: 600,
        marginRight: "0.5ch",
    },
    actionsTakenLabel: {
        display: "inline",
        fontWeight: 600,
    },
    descriptionAndQuote: {
        background: '#ffffff',
        padding: '50px 4px',
    },
    donationsContainer: {
        padding: '40px 4px',
        color: '#ffffff',
        background: theme.VividTemplate.donationsContainer.background,
    },
    donationsComboSection: {
        maxWidth: 300,
        alignSelf: 'flex-start'
    },
    donationsContainerActions: {
        background: '#ffffff',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 4,
        paddingRight: 4
    },
    white: {
        color: '#ffffff !important',
    },
    notchedOutline: {
        borderColor: '#ffffff !important',
    },
    joinButton: {
        background: theme.VividTemplate.joinButton.background,
        width: 230,
        '&:hover': {
            backgroundColor: theme.VividTemplate.joinButton.backgroundHover,
        },
    },
    joinButtonLarge: {
        fontSize: "18px",
    },
    width800marginAuto: {
        maxWidth: 800,
        margin: 'auto',
        justifyContent: 'space-between'
    },
    title: {
        maxWidth: 800,
        textAlign: 'center',
        fontSize: '4rem',
        fontWeight: 600,
        letterSpacing: '0rem',
        marginTop: '5rem',
        marginBottom: 20,
    },
    organisation: {
        maxWidth: 150,
    },
    organisationSection: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
    },
    organisationLogo: {
        width: 80,
        height: 80,
        marginRight: 10,
        objectFit: 'cover',
        borderRadius: "50%"
    },
    arrowForward: {
        fontSize: "1.25rem",
        marginLeft: '0.5rem',
    },
    description: {
        maxWidth: 400,
        marginLeft: 'auto',
        whiteSpace: "pre-line",
        [theme.breakpoints.up('xs')]: {
            marginBottom: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
        },
    },
    quoteWrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 300,
        marginLeft: 50,
    },
    quotePersonWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '1rem',
        paddingLeft: '1rem',
    },
    quote: {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: 1.1,
        paddingLeft: '1rem',
    },
    quoteImage: {
        width: 60,
        height: 60,
        objectFit: 'cover',
        marginRight: '0.25rem',
        borderRadius: '50%',
    },
    quoteNameWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    quoteName: {
        color: colors.darkGreyish,
    },
    donationsTitleContainer: {
        maxWidth: 400,
        marginLeft: 'auto',
        marginBottom: 20,
    },
    donationsTitle: {
        fontSize: '3rem',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '3rem',
        [theme.breakpoints.up('xs')]: {
            width: 800,
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
    },
    donationsLeftPart: {
        display: 'flex',
        flexDirection: 'column',
        width: 400,
        marginLeft: 'auto',
    },
    donationsRightPart: {
        marginLeft: 50,
    },
    donationsCountBox: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        right: "40px",
        top: 0,
        marginTop: "-25px",
        background: theme.palette.background.counter,
        borderRadius: "50%",
        width: "135px",
        height: "135px",
    },
    donationsCountNumber: {
        fontWeight: 600,
        color: "#ffffff",
        display: 'inline',
        fontSize: "1.5rem",
        marginBottom: 20,
    },
    donationsCountLabel: {
        fontWeight: 600,
        color: "#ffffff",
        display: 'inline',
        fontSize: "1rem",
    },
    donationsDescription: {
        maxWidth: 400,
        marginRight: "auto",
        whiteSpace: "pre-line",
    },
    donationsButton: {
        height: 56,
        margin: '20px 0 30px 0',
        width: 120
    },
    textField: {
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
    shareBox: {
        backgroundColor: '#F3F3F3',
        margin: '0 auto',
        [theme.breakpoints.up('xs')]: {
            padding: '10px 0 60px 0',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '10px 0 25px 0',
            justifyContent: 'flex-start',
        },
    },
    shareContainer: {
        backgroundColor: '#F3F3F3',
        paddingTop: 40,
    },
    shareTitle: {
        fontSize: '2.25rem',
        fontWeight: 600,
        marginBottom: '1rem',
        textAlign: 'center',
    },
    shareLabel: {
        fontSize: '0.85rem',
        color: colors.greyish,
        marginTop: '5px',
        textAlign: 'center',
    },
    shareIcon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.up('xs')]: {
            marginBottom: 10,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
        },
    },
    lastShareIcon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    empoweredBox: {
        padding: 10,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('xs')]: {
            paddingBottom: 40,
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 70,
        },
    },
    empoweredBy: {
        display: 'inline',
        fontSize: '1rem',
        fontWeight: 400,
        marginRight: '0.5ch',
    },
    freeRoots: {
        display: 'inline',
        fontSize: '1rem',
        fontWeight: 500,
        cursor: 'pointer',
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    fixedJoinButtonBar: {
        position: 'fixed',
        margin: '0 auto',
        width: '100%',
        height: 50,
        boxShadow: '0 6px 4px -5px rgba(0,0,0,0.5)',
        background: '#ffffff',
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            top: 0,
            bottom: 'auto',
            boxShadow: '0 5px 4px -5px rgba(0,0,0,0.5)',
        },
        [theme.breakpoints.down('xs')]: {
            top: 'auto',
            bottom: 0,
            boxShadow: '0 -5px 4px -5px rgba(0,0,0,0.5)',
        },
    },
    fixedJoinButtonBarFreeRoots: {
        margin: '0 30px',
        fontWeight: 500,
        fontSize: '1.25rem',
    },
    fixedJoinButtonBarTitle: {
        fontWeight: 500
    },
    fixedJoinButtonBarButtonWrapper: {
        display: 'flex',
        flexGrow: 1,
        marginRight: '5px',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'initial',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            justifyContent: 'center',
        },
    },
    verifiedLogo: {
        marginLeft: theme.spacing(4),
        marginRight: 5
    },
    verifiedText: {
        color: "#1AE3FF"
    },
    sponsorsTitle: {
        textAlign: 'center',
        fontSize: '1.75rem',
        fontWeight: 600,
        marginBottom: '1rem',
    },
    img: {
        top: 0,
        position: "absolute",
        width: '100%',
        objectFit: 'cover',
        height: "100%",
    },
    sponsorsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap"
    },
    logoImageContainer: {
        position: "relative",
        width: 100,
        height: 100,
        marginBottom: theme.spacing(1)
    },
    sponsorLogoAndTitle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 120
    },
    circular: {
        borderRadius: "50%"
    },
}));

const VividTemplate = ({campaign, actionsTakenCount, handleJoinCampaign, isLoadingCampaign, donationProps}) => {
    const classes = useStyles();
    const [isJoinButtonVisible, setJoinButtonVisible] = useState(true);

    return (
        <Box className={classes.container}>
            <img alt={campaign.title} src={campaign.bannerImageUrl} className={classes.backgroundImage} />
            <FixedJoinButtonBar
                campaign={campaign}
                isVisible={!isJoinButtonVisible}
                handleJoinCampaign={handleJoinCampaign}
                classes={classes}
            />
            <CampaignSection
                campaign={campaign}
                handleJoinCampaign={handleJoinCampaign}
                setJoinButtonVisible={setJoinButtonVisible}
                actionsTakenCount={actionsTakenCount}
                isLoadingCampaign={isLoadingCampaign}
                classes={classes}
            />
            <DescriptionAndQuoteSection campaign={campaign} classes={classes} />
            <DonationSection
                campaign={campaign}
                donationsCount={actionsTakenCount}
                classes={classes}
                donationProps={donationProps}
            />
            <ShareSection classes={classes} campaign={campaign}/>
            {campaign.sponsorsEnabled && !!campaign.sponsors && campaign.sponsors.length && <>
                <SponsorsSection classes={classes} campaign={campaign}/>
            </>}
            <EmpoweredSection organisation={campaign.organisation} classes={classes} />
        </Box>
    );
};

const FixedJoinButtonBar = ({campaign, isVisible, handleJoinCampaign, classes}) => {
    const {t} = useTranslation();
    if (!isVisible) {
        return null;
    }

    return(
        <Box className={classes.fixedJoinButtonBar}>
            <Hidden xsDown>
                <Typography className={classes.fixedJoinButtonBarFreeRoots}>
                    {t('common.name')}
                </Typography>
                <Typography className={classes.fixedJoinButtonBarTitle}>{campaign.title}</Typography>
            </Hidden>
            <Box className={classes.fixedJoinButtonBarButtonWrapper}>
                <JoinButton
                    size="small"
                    campaign={campaign}
                    handleJoinCampaign={handleJoinCampaign}
                    classes={classes}
                />
            </Box>
        </Box>
    );
};

const CampaignSection = ({classes, campaign, handleJoinCampaign, setJoinButtonVisible}) => {
    const {t} = useTranslation()
    const logoImageUrl = campaign.logoImageUrl || campaign.organisation.logoImageUrl || images.orgPlaceholderImg;

    return (
        <Box className={classes.campaignContainer}>
            <Typography variant="h1" className={classes.title}>{campaign.title}</Typography>
            <div className={classes.organisationSection}>
                <img src={logoImageUrl} alt="organization logo"
                     className={classes.organisationLogo}/>
                <Typography variant="h5" className={classes.organisation}>{campaign.organisationNameOverride || campaign.organisation.name}</Typography>
                {campaign.organisation.verified && <>
                    <img src={verifiedbadge} alt={`verified by ${t('common.name')}`}
                         className={classes.verifiedLogo}/>
                    <Typography className={classes.verifiedText}>Verified</Typography>
                </>}
            </div>
            <Box style={{width: 230}} />
            <IntersectionVisible
                onHide={() =>setJoinButtonVisible(false)}
                onShow={() => setJoinButtonVisible(true)}
            >
                <JoinButton
                    campaign={campaign}
                    handleJoinCampaign={handleJoinCampaign}
                    classes={classes}
                />
            </IntersectionVisible>
            {campaign.actionsCountEnabled &&
            <ActionsSection campaign={campaign} classes={classes}/>
            }
        </Box>
    );
};

const DescriptionAndQuoteSection = ({campaign, classes}) =>
    <Grid container spacing={0} className={classes.descriptionAndQuote}>
        <Grid item xs={12} sm={6}>
            <Typography className={classes.description}><LinkifyExternal>{campaign.description}</LinkifyExternal></Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.quoteWrapper}>
            {!!campaign.quote && campaign.quoteEnabled &&
            <>
                <Typography className={classes.quote}>"{campaign.quote}"</Typography>
                <Box className={classes.quotePersonWrapper}>
                    {campaign.quoteImageUrl &&
                        <img
                            alt={campaign.quoteName}
                            className={classes.quoteImage}
                            src={campaign.quoteImageUrl}
                        />
                    }
                    <Box className={classes.quoteNameWrapper}>
                        <Typography className={classes.quoteName}>
                            {campaign.quoteName}{campaign.quoteTitle ? ',' : ''}
                        </Typography>
                        <Typography className={classes.quoteName}>{campaign.quoteTitle}</Typography>
                    </Box>
                </Box>
            </>
            }
        </Grid>
    </Grid>;

const DonationsCount = ({ campaign, classes }) => {
    const totalAmount = (campaign.analytics.donations.publicCampaignTotals.totalAmount || 0) + (campaign.donationsAmountStart || 0);
    const { countUp: donationsCount, update } = useCountUp({ end: totalAmount, duration: 3.5, separator: "," });

    useEffect(() => {
        update(totalAmount);
    });

    return <Box className={classes.donationsLeftPart}>
        <Typography className={classes.donationsCountNumber}>${donationsCount || 0} Donated</Typography>
        <Typography className={classes.donationsDescription}>
            {campaign.donationsConfig.donationsDescription}
        </Typography>
    </Box>
};

const DonationSection = ({ campaign, classes, donationProps: { onChangeSelectedDonation, selectedDonation, onDonateClick } }) => {
    const { type, amount } = selectedDonation;    

    if (!isDonationsEnabled(campaign)) {
        return null;
    }

    const handleCheckbox = (option) => {
        onChangeSelectedDonation({ type: 'radio', amount: option });
    };

    const handleTextField = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        if (value > 0 || value === '') {
            onChangeSelectedDonation({ type: 'freeform', amount: value })
        }
    };
    const donationsList = (campaign.analytics.donations.publicPayments && campaign.analytics.donations.publicPayments.content) || [];
    const donateDisabled = !selectedDonation.amount;

    return (
        <>
            <Grid container spacing={0} className={classes.donationsContainer}>
                <Grid item xs={12} sm={6}>
                    <Box className={classes.donationsTitleContainer}>
                        <Typography className={classes.donationsTitle}>
                            {campaign.donationsConfig.donationsTitle}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} />
                {campaign.donationsAmountEnabled &&
                <Grid container item xs={12} style={{padding: '0 auto'}}>
                    <Grid item xs={12} sm={6}>
                        <DonationsCount campaign={campaign} classes={classes}/>
                    </Grid>                    
                </Grid>
                }
            </Grid>
            <Grid container spacing={0} className={classes.donationsContainerActions} >
                <Grid container item xs={12} className={classes.width800marginAuto}>
                    <Grid container item xs={12} sm={6} className={classes.donationsComboSection}>
                        {campaign.donationsConfig.donationAmounts.map((option, i) =>
                            <Grid key={i} item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<RadioButtonUnchecked />}
                                            checkedIcon={<RadioButtonChecked />}
                                            checked={amount === option && type === 'radio'}
                                            color={'primary'}
                                            onChange={() => handleCheckbox(option)}
                                        />
                                    }
                                    label={`$${option}`}
                                />
                            </Grid>
                        )}
                        {campaign.donationsConfig.allowAnyAmount &&
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    label="Enter other amount"
                                    variant="outlined"
                                    margin="dense"
                                    value={type === 'freeform' ? amount : ''}
                                    onChange={(event) => handleTextField(event)}
                                />
                            </Grid>
                        }
                        <Button variant="contained" color="primary" className={classes.donationsButton} onClick={onDonateClick} disabled={donateDisabled}>
                            DONATE
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <DonationsList donations={donationsList} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const ShareSection = ({classes, campaign}) =>
    <Box className={classes.shareContainer}>
        <Typography className={classes.shareTitle}>
            Spread the word!
        </Typography>
        <Box>
            <Grid container spacing={0}  className={classes.shareBox}>
                {campaign.shareFacebookEnabled &&
                <Grid item xs={4} sm={3} md={1} className={classes.shareIcon} >
                    <FacebookButton campaign={campaign}/>
                </Grid>
                }
                {campaign.organisation.id !== 12812 && campaign.organisation.id !== 564024 && campaign.shareParlerEnabled &&
                <Grid item xs={4} sm={3} md={1} className={classes.shareIcon}>
                    <ParlerButton campaign={campaign}/>
                </Grid>
                }
                {campaign.shareTwitterEnabled &&
                <Grid item xs={4} sm={3} md={1} className={classes.shareIcon} >
                    <TwitterButton campaign={campaign}/>
                </Grid>
                }
                {campaign.shareGabEnabled &&
                <Grid item xs={4} sm={3} md={1} className={classes.shareIcon} >
                    <GabButton campaign={campaign}/>
                </Grid>
                }
                {campaign.shareLinkedinEnabled &&
                <Grid item xs={4} sm={3} md={1}  className={classes.shareIcon} >
                    <LinkedinButton campaign={campaign}/>
                </Grid>
                }
                {campaign.shareTelegramEnabled &&
                <Grid item xs={4} sm={3} md={1} className={classes.shareIcon} >
                    <TelegramButton campaign={campaign}/>
                </Grid>
                }
                {campaign.shareEmailEnabled &&
                <Grid item xs={4} sm={3} md={1} className={classes.shareIcon} >
                    <EmailButton campaign={campaign}/>
                </Grid>
                }
                {campaign.shareSignalEnabled &&
                <Grid item xs={4} sm={3} md={1}  className={classes.shareIcon} >
                    <SignalButton campaign={campaign}/>
                </Grid>
                }
                {campaign.shareCopyEnabled &&
                <Grid item xs={4} sm={3} md={1} className={classes.lastShareIcon} >
                    <CopyButton campaign={campaign}/>
                </Grid>
                }
            </Grid>
        </Box>
    </Box>;

const SponsorsSection = ({classes, campaign}) =>
    <Grid container spacing={0} className={classes.shareBox}>
        <Grid item xs={12}>
            <Typography className={classes.sponsorsTitle}>
                {campaign.sponsorsHeadingText || 'Thank you to our sponsors:'}
            </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sponsorsContainer}>
            {campaign.sponsors.map(sponsor => <div className={classes.sponsorLogoAndTitle}>
                <div className={classes.logoImageContainer}>
                    <img src={sponsor.logoImageUrl} alt="sponsor logo"
                         className={classNames(classes.circular, classes.img)}/>
                </div>
                <Typography variant="caption" color="textSecondary" align="center">
                    {sponsor.name}
                </Typography>
            </div>)}
        </Grid>
    </Grid>;

const EmpoweredSection = ({organisation, classes}) => {
    const {t} = useTranslation();

    return <Box className={classes.empoweredBox}>
        {!organisation.whiteLabelLogoImageUrl &&
        <Link href={ROUTES.ROOT}>
            <Hidden smUp>
                <FreeRootsLogo variant="image-only" color="#000000"/>
            </Hidden>
            <Hidden xsDown>
                <FreeRootsLogo color="#000000"/>
            </Hidden>
        </Link>
        }
        {!!organisation.whiteLabelLogoImageUrl &&
        <img src={organisation.whiteLabelLogoImageUrl} height={100} alt="logo"/>
        }
        <div>
            <Typography className={classes.empoweredBy}>{t('campaign.empoweredBy')}</Typography>
            <Link href="/" className={classes.freeRoots}>{t('common.name')}</Link>
        </div>
        <div className={classes.spacer}>&nbsp;</div>
    </Box>;
}

const JoinButton = ({size, campaign, handleJoinCampaign, classes}) =>
    <Button
        onClick={handleJoinCampaign}
        variant="contained"
        color="primary"
        size="large"
        className={size === 'small' ? classes.joinButton : classNames(classes.joinButton, classes.joinButtonLarge)}
        style={{height: size === 'small' ? 40 : 70}}
    >
        Take Action Now
        <ArrowForward className={classes.arrowForward}/>
    </Button>;

export default compose(
)(VividTemplate);
