import {
    LOAD_PREVIEW_CAMPAIGN_BEGIN,
    LOAD_PREVIEW_CAMPAIGN_FAILURE,
    LOAD_PREVIEW_CAMPAIGN_SUCCESS
} from "./previewCampaignActions";

const initialState = {
    campaign: null,
    loading: false,
    error: null
};

export default function previewCampaignReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_PREVIEW_CAMPAIGN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LOAD_PREVIEW_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                campaign: action.payload.campaign
            };

        case LOAD_PREVIEW_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        default:
            return state;
    }
}
