import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import ParlerLogo from "../../images/parler_logo.png";

const useStyles = makeStyles(() => ({
    shareLabel: {
        fontSize: '0.85rem',
        color: "#a2a2a2",
        marginTop: 5,
        textAlign: 'center',
    },
    parlerLogo: {
        width: 48,
        height: 48,
        borderRadius: 24,
        cursor: "pointer",
        marginTop: 0
    }
}));

const ParlerShareButton = ({link, message, beforeOnClick, className}) => {
    const classes = useStyles();
    const handleClick = () => {
        if (link) {
            beforeOnClick && beforeOnClick()
            window.open(`https://parler.com/new-post?message=${encodeURIComponent(message)}&url=${encodeURIComponent(link)}`, '_blank', 'width=500,height=500')
        }
        return false;
    }
    return (
        <div className={className}
             onClick={handleClick}>
            <img src={ParlerLogo} className={classes.parlerLogo} alt="parler"/>
            <Typography className={classes.shareLabel}>Parler</Typography>
        </div>
    )
}

export default ParlerShareButton;