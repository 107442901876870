import {apolloClient} from '../../graphql'
import gql from "graphql-tag";
import {push} from "connected-react-router";
import * as routes from "../../constants/routes";
import {uploadImage} from "../../imageupload/uploadImage";

const UPDATE_ORGANISATION_MUTATION = gql`
  mutation UpdateOrganisation($updateOrganisationInput: UpdateOrganisationInput!) {
    updateOrganisation(updateOrganisationInput: $updateOrganisationInput) {
        id
        name
        logoMediaId
        logoImageUrl
        whiteLabelLogoImageUrl
        googleAnalyticsId
        facebookPixelId
        verified
        verificationSubmitted
    }
  }
`;

async function performUpdateOrganisationMutation(organisation) {
    const updateOrganisationResponse = await apolloClient.mutate({
        mutation: UPDATE_ORGANISATION_MUTATION,
        variables: {
            updateOrganisationInput: organisation
        }
    });
    return updateOrganisationResponse.data.updateOrganisation;
}

export function updateOrganisation(organisation, advanceToCampaigns, advanceToHome) {
    return async dispatch => {
        dispatch(updateOrganisationBegin());
        try {
            const updatedOrganisation = await performUpdateOrganisationMutation(organisation);
            dispatch(updateOrganisationSuccess(updatedOrganisation));
            if (advanceToHome) {
                dispatch(push(routes.HOME));
            } else if (advanceToCampaigns) {
                dispatch(push(routes.CAMPAIGN_WELCOME));
            }
        } catch (error) {
            dispatch(updateOrganisationFailure(error));
        }
    };
}

export function uploadOrganisationLogoImage(organisation, imageFile) {
    return async dispatch => {
        dispatch(uploadLogoImageBegin());
        try {
            const mediaEntry = await uploadImage(organisation.id, imageFile, {
                width: 100
            });

            organisation.logoMediaId = mediaEntry.id;
            organisation.logoImageUrl = mediaEntry.s3GetUrl;

            const updatedOrganisation = await performUpdateOrganisationMutation(organisation);
            dispatch(uploadLogoImageSuccess(updatedOrganisation));
        } catch (error) {
            dispatch(uploadLogoImageFailure(error));
        }
    };
}

export function uploadOrganisationWhiteLabelLogoImage(organisation, imageFile) {
    return async dispatch => {
        dispatch(uploadWhiteLabelLogoImageBegin());
        try {
            const mediaEntry = await uploadImage(organisation.id, imageFile, {
                width: 100
            });

            organisation.whiteLabelLogoMediaId = mediaEntry.id;
            organisation.whiteLabelLogoImageUrl = mediaEntry.s3GetUrl;

            const updatedOrganisation = await performUpdateOrganisationMutation(organisation);
            dispatch(uploadWhiteLabelLogoImageSuccess(updatedOrganisation));
        } catch (error) {
            dispatch(uploadWhiteLabelLogoImageFailure(error));
        }
    };
}


export const UPDATE_ORGANISATION_BEGIN = "UPDATE_ORGANISATION_BEGIN";
export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_FAILURE = "UPDATE_ORGANISATION_FAILURE";

export const UPLOAD_LOGO_IMAGE_BEGIN = "UPLOAD_LOGO_IMAGE_BEGIN";
export const UPLOAD_LOGO_IMAGE_SUCCESS = "UPLOAD_LOGO_IMAGE_SUCCESS";
export const UPLOAD_LOGO_IMAGE_FAILURE = "UPLOAD_LOGO_IMAGE_FAILURE";

export const UPLOAD_WHITE_LABEL_LOGO_IMAGE_BEGIN = "UPLOAD_WHITE_LABEL_LOGO_IMAGE_BEGIN";
export const UPLOAD_WHITE_LABEL_LOGO_IMAGE_SUCCESS = "UPLOAD_WHITE_LABEL_LOGO_IMAGE_SUCCESS";
export const UPLOAD_WHITE_LABEL_LOGO_IMAGE_FAILURE = "UPLOAD_WHITE_LABEL_LOGO_IMAGE_FAILURE";

export const updateOrganisationBegin = () => ({
    type: UPDATE_ORGANISATION_BEGIN
});

export const updateOrganisationSuccess = (organisation) => ({
    type: UPDATE_ORGANISATION_SUCCESS,
    payload: {organisation}
});

export const updateOrganisationFailure = error => ({
    type: UPDATE_ORGANISATION_FAILURE,
    payload: {error}
});

export const uploadLogoImageFailure = error => ({
    type: UPLOAD_LOGO_IMAGE_FAILURE,
    payload: {error}
});

export const uploadLogoImageBegin = () => ({
    type: UPLOAD_LOGO_IMAGE_BEGIN
});

export const uploadLogoImageSuccess = (organisation) => ({
    type: UPLOAD_LOGO_IMAGE_SUCCESS,
    payload: {organisation}
});

export const uploadWhiteLabelLogoImageFailure = error => ({
    type: UPLOAD_WHITE_LABEL_LOGO_IMAGE_FAILURE,
    payload: {error}
});

export const uploadWhiteLabelLogoImageBegin = () => ({
    type: UPLOAD_WHITE_LABEL_LOGO_IMAGE_BEGIN
});

export const uploadWhiteLabelLogoImageSuccess = (organisation) => ({
    type: UPLOAD_WHITE_LABEL_LOGO_IMAGE_SUCCESS,
    payload: {organisation}
});