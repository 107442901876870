import {apolloClient} from '../../graphql'
import gql from "graphql-tag";
import {uploadImage} from '../../imageupload/uploadImage';
import {push} from 'connected-react-router'
import {replaceParams} from "../../utils/link-utils";
import * as routes from '../../constants/routes';

const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($organisationId: Int!) {
    createEvent(organisationId: $organisationId) {
        id
        status
        organisation {
            id
            name
            logoImageUrl
        }
    }
  }
`;

export function createEvent(organisationId) {
    return async dispatch => {
        dispatch(createEventBegin());
        try {
            const createEventResponse = await apolloClient.mutate({
                mutation: CREATE_EVENT_MUTATION,
                variables: {
                    organisationId: organisationId
                }
            });
            const createdEvent = createEventResponse.data.createEvent;
            dispatch(createEventSuccess(createdEvent));
            dispatch(push(replaceParams(routes.CREATE_EVENT_DESCRIBE, {
                ":id": createdEvent.id
            })));
        } catch (error) {
            dispatch(createEventFailure(error));
        }
    };
}

const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($organisationId: Int!, $updateEventInput: UpdateEventInput!) {
    updateEvent(organisationId: $organisationId, updateEventInput: $updateEventInput) {
        id
        organisationNameOverride
        logoMediaId
        logoImageUrl
        status
        title
        bannerMediaId
        bannerImageUrl
        location
        conferenceUrl
        timeZone
        startDate
        startTime
        organisation {
            id
            name
            logoImageUrl
        }
    }
  }
`;

async function performUpdateEventMutation(event, organisationId) {
    let updateEventInput = {...event};
    delete updateEventInput.organisation;
    delete updateEventInput.status;
    const updateEventResponse = await apolloClient.mutate({
        mutation: UPDATE_EVENT_MUTATION,
        variables: {
            organisationId,
            updateEventInput: updateEventInput
        }
    });
    return updateEventResponse.data.updateEvent;
}

export function updateEvent(organisationId, event) {
    return async dispatch => {
        dispatch(updateEventBegin());
        try {
            const updatedEvent = await performUpdateEventMutation(event, organisationId);
            dispatch(updateEventSuccess(updatedEvent));
        } catch (error) {
            dispatch(updateEventFailure(error));
        }
    };
}

export function updateEventStatus(organisationId, event, status, nextUrl) {
    return async dispatch => {
        dispatch(updateEventBegin());
        try {
            const {id} = event;
            const updateEventResponse = await apolloClient.mutate({
                mutation: UPDATE_EVENT_MUTATION,
                variables: {
                    organisationId,
                    updateEventInput: {id, status}
                }
            });
            dispatch(updateEventSuccess(updateEventResponse.data.updateEvent));
            if (nextUrl) {
                dispatch(push(nextUrl));
            }
        } catch (error) {
            dispatch(updateEventFailure(error));
        }
    };
}

const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEvent($organisationId: Int!, $eventId: Int!) {
    deleteEvent(organisationId: $organisationId, eventId: $eventId) {
      success
    }
  }
`;

export function deleteEvent(organisationId, event) {
    return async dispatch => {
        dispatch(deleteEventBegin());
        try {
            const {id} = event;
            await apolloClient.mutate({
                mutation: DELETE_EVENT_MUTATION,
                variables: {
                    organisationId,
                    eventId: id
                }
            });
            dispatch(deleteEventSuccess(event));
        } catch (error) {
            dispatch(deleteEventFailure(error));
        }
    };
}

export function uploadEventBanner(organisationId, event, imageFile) {
    return async dispatch => {
        dispatch(uploadEventBannerBegin());
        try {
            const mediaEntry = await uploadImage(organisationId, imageFile, {
                width: 1000
            });

            event.bannerMediaId = mediaEntry.id;
            event.bannerImageUrl = mediaEntry.s3GetUrl;

            const updatedEvent = await performUpdateEventMutation(event, organisationId);
            dispatch(uploadEventBannerSuccess(updatedEvent));
        } catch (error) {
            dispatch(uploadEventBannerFailure(error));
        }
    };
}

export function uploadEventLogo(organisationId, event, imageFile) {
    return async dispatch => {
        dispatch(uploadEventLogoBegin());
        try {
            const mediaEntry = await uploadImage(organisationId, imageFile, {
                width: 1000
            });

            event.logoMediaId = mediaEntry.id;
            event.logoImageUrl = mediaEntry.s3GetUrl;

            const updatedEvent = await performUpdateEventMutation(event, organisationId);
            dispatch(uploadEventLogoSuccess(updatedEvent));
        } catch (error) {
            dispatch(uploadEventLogoFailure(error));
        }
    };
}

const LOAD_EVENT_QUERY = gql`
  query GetEvent($organisationId: Int!, $eventId: Int!) {
    event(organisationId: $organisationId, eventId: $eventId) {
        id
        organisationNameOverride
        logoMediaId
        logoImageUrl
        status
        title
        bannerMediaId
        bannerImageUrl
        location
        conferenceUrl
        timeZone
        startDate
        startTime
        organisation {
            id
            name
            logoImageUrl
        }
    }
  }
`;

export function loadEvent(organisationId, eventId) {
    return async dispatch => {
        dispatch(loadEventBegin());
        try {
            const loadEventResponse = await apolloClient.query({
                query: LOAD_EVENT_QUERY,
                variables: {
                    organisationId,
                    eventId
                }
            });
            const event = loadEventResponse.data.event;
            dispatch(loadEventSuccess(event));
        } catch (error) {
            dispatch(loadEventFailure(error));
        }
    };
}

const LIST_EVENTS_QUERY = gql`
  query ListEvents($organisationId: Int!) {
    organisation(organisationId: $organisationId) {
        events {
            id
            status
            title
            organisationNameOverride
            bannerMediaId
            bannerImageUrl
            location
            conferenceUrl
            timeZone
            startDate
            startTime
            organisation {
              id
              name
            }
        }
    }
  }
`;

export function listEvents(organisationId) {
    return async dispatch => {
        dispatch(listEventsBegin());
        try {
            const listEventsResponse = await apolloClient.query({
                query: LIST_EVENTS_QUERY,
                variables: {
                    organisationId
                }
            });
            const events = listEventsResponse.data.organisation.events;
            dispatch(listEventsSuccess(events));
        } catch (error) {
            dispatch(listEventsFailure(error));
        }
    };
}

export const CREATE_EVENT_BEGIN = "CREATE_EVENT_BEGIN";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

export const UPDATE_EVENT_BEGIN = "UPDATE_EVENT_BEGIN";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

export const DELETE_EVENT_BEGIN = "DELETE_EVENT_BEGIN";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";

export const UPLOAD_EVENT_BANNER_BEGIN = "UPLOAD_EVENT_BANNER_BEGIN";
export const UPLOAD_EVENT_BANNER_SUCCESS = "UPLOAD_EVENT_BANNER_SUCCESS";
export const UPLOAD_EVENT_BANNER_FAILURE = "UPLOAD_EVENT_BANNER_FAILURE";

export const UPLOAD_EVENT_LOGO_BEGIN = "UPLOAD_EVENT_LOGO_BEGIN";
export const UPLOAD_EVENT_LOGO_SUCCESS = "UPLOAD_EVENT_LOGO_SUCCESS";
export const UPLOAD_EVENT_LOGO_FAILURE = "UPLOAD_EVENT_LOGO_FAILURE";

export const LOAD_EVENT_BEGIN = "LOAD_EVENT_BEGIN";
export const LOAD_EVENT_SUCCESS = "LOAD_EVENT_SUCCESS";
export const LOAD_EVENT_FAILURE = "LOAD_EVENT_FAILURE";

export const LIST_EVENTS_BEGIN = "LIST_EVENTS_BEGIN";
export const LIST_EVENTS_SUCCESS = "LIST_EVENTS_SUCCESS";
export const LIST_EVENTS_FAILURE = "LIST_EVENTS_FAILURE";

export const createEventBegin = () => ({
    type: CREATE_EVENT_BEGIN
});

export const createEventSuccess = (event) => ({
    type: CREATE_EVENT_SUCCESS,
    payload: {event}
});

export const createEventFailure = error => ({
    type: CREATE_EVENT_FAILURE,
    payload: {error}
});

export const updateEventBegin = () => ({
    type: UPDATE_EVENT_BEGIN
});

export const updateEventSuccess = (event) => ({
    type: UPDATE_EVENT_SUCCESS,
    payload: {event}
});

export const updateEventFailure = error => ({
    type: UPDATE_EVENT_FAILURE,
    payload: {error}
});

export const deleteEventBegin = () => ({
    type: DELETE_EVENT_BEGIN
});

export const deleteEventSuccess = (event) => ({
    type: DELETE_EVENT_SUCCESS,
    payload: {event}
});

export const deleteEventFailure = error => ({
    type: DELETE_EVENT_FAILURE,
    payload: {error}
});

export const uploadEventBannerBegin = () => ({
    type: UPLOAD_EVENT_BANNER_BEGIN
});

export const uploadEventBannerSuccess = (event) => ({
    type: UPLOAD_EVENT_BANNER_SUCCESS,
    payload: {event}
});

export const uploadEventBannerFailure = error => ({
    type: UPLOAD_EVENT_BANNER_FAILURE,
    payload: {error}
});

export const uploadEventLogoBegin = () => ({
    type: UPLOAD_EVENT_LOGO_BEGIN
});

export const uploadEventLogoSuccess = (event) => ({
    type: UPLOAD_EVENT_LOGO_SUCCESS,
    payload: {event}
});

export const uploadEventLogoFailure = error => ({
    type: UPLOAD_EVENT_LOGO_FAILURE,
    payload: {error}
});

export const loadEventBegin = () => ({
    type: LOAD_EVENT_BEGIN
});

export const loadEventSuccess = (event) => ({
    type: LOAD_EVENT_SUCCESS,
    payload: {event}
});

export const loadEventFailure = error => ({
    type: LOAD_EVENT_FAILURE,
    payload: {error}
});

export const listEventsBegin = () => ({
    type: LIST_EVENTS_BEGIN
});

export const listEventsSuccess = (events) => ({
    type: LIST_EVENTS_SUCCESS,
    payload: {events}
});

export const listEventsFailure = error => ({
    type: LIST_EVENTS_FAILURE,
    payload: {error}
});