import store from "../store";
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client'
import {setContext} from 'apollo-link-context';
import {ApolloLink} from 'apollo-link';
import {prependDomainToAuthToken} from "../constants/domains";

const _localUrl = "http://localhost:9080/graphql";

const _stagingUrl = "https://api-staging.freeroots.com/graphql";

const _prodUrl = "https://api.freeroots.com/graphql";

export const baseUrl =
    process.env.REACT_APP_ENV === "production"
        ? _prodUrl
        : process.env.REACT_APP_ENV === "staging"
        ? _stagingUrl
        : _localUrl;


const httpLink = createUploadLink({
    uri: baseUrl
});

const authLink = setContext((_, {headers}) => {
    const authUser = store.getState().session.authUser;
    if (!authUser || (headers && headers.Authorization)) return;
    return authUser.getIdToken().then(token => ({
        headers: {
            ...headers,
            authorization: token ? `Bearer ${prependDomainToAuthToken(token)}` : "",
        }
    }));
});

// https://github.com/apollographql/apollo-client/issues/1564
const cleanTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = _omitDeep(operation.variables, "__typename")
    }
    return forward(operation).map((data) => {
        return data;
    })
});

function _omitDeep(obj, key) {
    // ommiting File from object recreating
    if (obj instanceof File) {
        return obj;
    }
    const keys = Object.keys(obj);
    const newObj = {};
    keys.forEach((i) => {
        if (i !== key) {
            const val = obj[i];
            if (Array.isArray(val)) newObj[i] = _omitDeepArrayWalk(val, key);
            else if (typeof val === 'object' && val !== null) newObj[i] = _omitDeep(val, key);
            else newObj[i] = val
        }
    });
    return newObj
}

function _omitDeepArrayWalk(arr, key) {
    return arr.map((val) => {
        if (Array.isArray(val)) return _omitDeepArrayWalk(val, key);
        else if (typeof val === 'object') return _omitDeep(val, key);
        return val
    })
}

const appLink = ApolloLink.from ([
    cleanTypenameLink,
    authLink,
    httpLink
]);

export const apolloClient = new ApolloClient({
    link: appLink,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
           fetchPolicy: 'no-cache',
        },
        query: {
           fetchPolicy: 'no-cache',
        },
    }
});
