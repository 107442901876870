import {apolloClient} from '../../graphql'
import gql from "graphql-tag";
import {push} from 'connected-react-router'
import * as routes from '../../constants/routes';
import {replaceParams} from '../../utils/link-utils';
import {uploadImage} from "../../imageupload/uploadImage";

export const LOAD_CAMPAIGN_SMS_BEGIN = "LOAD_CAMPAIGN_SMS_BEGIN";
export const LOAD_CAMPAIGN_SMS_SUCCESS = "LOAD_CAMPAIGN_SMS_SUCCESS";
export const LOAD_CAMPAIGN_SMS_FAILURE = "LOAD_CAMPAIGN_SMS_FAILURE";
export const LIST_CAMPAIGN_SMS_BEGIN = "LIST_CAMPAIGN_SMS_BEGIN";
export const LIST_CAMPAIGN_SMS_SUCCESS = "LIST_CAMPAIGN_SMS_SUCCESS";
export const LIST_CAMPAIGN_SMS_FAILURE = "LIST_CAMPAIGN_SMS_FAILURE";
export const UPDATE_CAMPAIGN_SMS_BEGIN = "UPDATE_CAMPAIGN_SMS_BEGIN";
export const UPDATE_CAMPAIGN_SMS_SUCCESS = "UPDATE_CAMPAIGN_SMS_SUCCESS";
export const UPDATE_CAMPAIGN_SMS_FAILURE = "UPDATE_CAMPAIGN_SMS_FAILURE";
export const UPLOAD_CAMPAIGN_SMS_IMAGE_BEGIN = "UPLOAD_CAMPAIGN_SMS_IMAGE_BEGIN";
export const UPLOAD_CAMPAIGN_SMS_IMAGE_SUCCESS = "UPLOAD_CAMPAIGN_SMS_IMAGE_SUCCESS";
export const UPLOAD_CAMPAIGN_SMS_IMAGE_FAILURE = "UPLOAD_CAMPAIGN_SMS_IMAGE_FAILURE";
export const CREATE_CAMPAIGN_SMS_BEGIN = "CREATE_CAMPAIGN_SMS_BEGIN";
export const CREATE_CAMPAIGN_SMS_SUCCESS = "CREATE_CAMPAIGN_SMS_SUCCESS";
export const CREATE_CAMPAIGN_SMS_FAILURE = "CREATE_CAMPAIGN_SMS_FAILURE";
export const DELETE_CAMPAIGN_SMS_BEGIN = "DELETE_CAMPAIGN_SMS_BEGIN";
export const DELETE_CAMPAIGN_SMS_SUCCESS = "DELETE_CAMPAIGN_SMS_SUCCESS";
export const DELETE_CAMPAIGN_SMS_FAILURE = "DELETE_CAMPAIGN_SMS_FAILURE";

export const loadCampaignSmsBegin = () => ({
    type: LOAD_CAMPAIGN_SMS_BEGIN
});

export const loadCampaignSmsSuccess = (campaignWithSmsAndCounts) => ({
    type: LOAD_CAMPAIGN_SMS_SUCCESS,
    payload: {campaignWithSmsAndCounts}
});

export const loadCampaignSmsFailure = error => ({
    type: LOAD_CAMPAIGN_SMS_FAILURE,
    payload: {error}
});

const LOAD_CAMPAIGN_SMS_QUERY = gql`
  query LoadCampaignSms($organisationId: Int!, $campaignId: Int!, $campaignSmsId: Int!) {
    campaign(organisationId: $organisationId, campaignId: $campaignId) {
        id
        campaignSms(campaignSmsId: $campaignSmsId) {
            id
            campaignId
            subject
            imageUrl
            message
            status
            sendOption
            mms
            stats {
                sentCount
            }
        }
    }
  }
`;

export function loadCampaignSms(organisationId, campaignId, campaignSmsId) {
    return async dispatch => {
        dispatch(loadCampaignSmsBegin());
        try {
            const campaignSmsResponse = await apolloClient.query({
                query: LOAD_CAMPAIGN_SMS_QUERY,
                variables: {
                    organisationId,
                    campaignId,
                    campaignSmsId
                }
            });
            const campaignWithSmsAndCounts = campaignSmsResponse.data.campaign;
            dispatch(loadCampaignSmsSuccess(campaignWithSmsAndCounts));
        } catch (error) {
            dispatch(loadCampaignSmsFailure(error));
        }
    };
}

export const listCampaignSmsBegin = () => ({
    type: LIST_CAMPAIGN_SMS_BEGIN
});

export const listCampaignSmsSuccess = (campaignWithSmsAndCounts) => ({
    type: LIST_CAMPAIGN_SMS_SUCCESS,
    payload: {campaignWithSmsAndCounts}
});

export const listCampaignSmsFailure = error => ({
    type: LIST_CAMPAIGN_SMS_FAILURE,
    payload: {error}
});

const LIST_CAMPAIGN_SMS_QUERY = gql`
  query ListCampaignSms($organisationId: Int!, $campaignId: Int!) {
    campaign(organisationId: $organisationId, campaignId: $campaignId) {
        campaignSms {
            id
            campaignId
            subject
            message
            imageUrl
            status
            sendOption
            mms
            stats {
                sentCount
            }
        }
        organisation {
            smsDailyLimit
        }
    }
  }
`;

export function listCampaignSms(organisationId, campaignId) {
    return async dispatch => {
        dispatch(listCampaignSmsBegin());
        try {
            const listCampaignSmsResponse = await apolloClient.query({
                query: LIST_CAMPAIGN_SMS_QUERY,
                variables: {
                    organisationId,
                    campaignId
                }
            });
            const campaignWithSmsAndCounts = listCampaignSmsResponse.data.campaign;
            dispatch(listCampaignSmsSuccess(campaignWithSmsAndCounts));
        } catch (error) {
            dispatch(listCampaignSmsFailure(error));
        }
    };
}


const CREATE_CAMPAIGN_SMS_MUTATION = gql`
  mutation CreateCampaignSms($organisationId: Int!, $campaignId: Int!) {
    createCampaignSms(organisationId: $organisationId, campaignId: $campaignId) {
        id
    }
  }
`;

export const createCampaignSmsBegin = () => ({
    type: CREATE_CAMPAIGN_SMS_BEGIN
});

export const createCampaignSmsuccess = (campaignSms) => ({
    type: CREATE_CAMPAIGN_SMS_SUCCESS,
    payload: {campaignSms}
});

export const createCampaignSmsFailure = error => ({
    type: CREATE_CAMPAIGN_SMS_FAILURE,
    payload: {error}
});

export function createDraftCampaignSms(organisationId, campaignId) {
    return async dispatch => {
        dispatch(createCampaignSmsBegin());
        try {
            const createCampaignSmsResponse = await apolloClient.mutate({
                mutation: CREATE_CAMPAIGN_SMS_MUTATION,
                variables: {
                    organisationId: organisationId,
                    campaignId: campaignId
                }
            });
            const createdCampaignSms = createCampaignSmsResponse.data.createCampaignSms;
            dispatch(createCampaignSmsuccess(createdCampaignSms));
            dispatch(push(replaceParams(routes.CAMPAIGN_SMS, {
                ":id": campaignId,
                ":smsId": createdCampaignSms.id
            })));
        } catch (error) {
            console.log(error);
            dispatch(createCampaignSmsFailure(error));
        }
    };
}

const SEND_TEST_CAMPAIGN_SMS_MUTATION = gql`
  mutation TestCampaignSms($organisationId: Int!, $campaignId: Int!, $campaignSmsId: Int!, $testPhone: String!) {
    testCampaignSms(organisationId: $organisationId, campaignId: $campaignId, campaignSmsId: $campaignSmsId, testPhone: $testPhone) {
        success
    }
  }
`;

export async function sendTestSms(organisationId, campaignId, campaignSmsId, testPhone) {
    const sendTestSmsResponse = await apolloClient.mutate({
        mutation: SEND_TEST_CAMPAIGN_SMS_MUTATION,
        variables: {
            organisationId,
            campaignId,
            campaignSmsId,
            testPhone
        }
    });
    return sendTestSmsResponse.data.testCampaignSms.success;
}

const DELETE_CAMPAIGN_SMS_MUTATION = gql`
  mutation DeleteCampaignSms($organisationId: Int!, $campaignId: Int!, $campaignSmsId: Int!) {
    deleteCampaignSms(organisationId: $organisationId, campaignId: $campaignId, campaignSmsId: $campaignSmsId) {
        success
    }
  }
`;

export const deleteCampaignSmsBegin = () => ({
    type: DELETE_CAMPAIGN_SMS_BEGIN
});

export const deleteCampaignSmsuccess = (success) => ({
    type: DELETE_CAMPAIGN_SMS_SUCCESS,
});

export const deleteCampaignSmsFailure = error => ({
    type: DELETE_CAMPAIGN_SMS_FAILURE,
    payload: {error}
});

export function deleteCampaignSms(organisationId, campaignId, campaignSmsId) {
    return async dispatch => {
        dispatch(deleteCampaignSmsBegin());
        try {
            const sendTestSmsResponse = await apolloClient.mutate({
                mutation: DELETE_CAMPAIGN_SMS_MUTATION,
                variables: {
                    organisationId: organisationId,
                    campaignId: campaignId,
                    campaignSmsId: campaignSmsId
                }
            });
            const smsDeleted = sendTestSmsResponse.data.deleteCampaignSms.success;
            dispatch(deleteCampaignSmsuccess(smsDeleted));
            return smsDeleted;
        } catch (error) {
            console.log(error);
            dispatch(deleteCampaignSmsFailure(error));
            throw new Error(error);
        }
    };
}


const SEND_CAMPAIGN_SMS_MUTATION = gql`
  mutation DeleteCampaignSms($organisationId: Int!, $campaignId: Int!, $campaignSmsId: Int!) {
    sendCampaignSms(organisationId: $organisationId, campaignId: $campaignId, campaignSmsId: $campaignSmsId) {
        success,
        errors {
            code,
            message
        }
    }
  }
`;

export async function sendCampaignSms(organisationId, campaignId, campaignSmsId) {
    const sendCampaignSmsResponse = await apolloClient.mutate({
        mutation: SEND_CAMPAIGN_SMS_MUTATION,
        variables: {
            organisationId: organisationId,
            campaignId: campaignId,
            campaignSmsId: campaignSmsId
        }
    });
    const response = sendCampaignSmsResponse.data.sendCampaignSms;
    if (response.errors) {
        throw response.errors[0]
    }
    return response;
}


const UPDATE_CAMPAIGN_SMS_MUTATION = gql`
  mutation UpdateCampaignSms($organisationId: Int!, $campaignId: Int!, $campaignSmsInput: CampaignSmsInput!) {
    updateCampaignSms(organisationId: $organisationId, campaignId: $campaignId, campaignSmsInput: $campaignSmsInput) {
        id
        campaignId
        subject
        message
        imageUrl
        status
        sendOption
        stats {
            sentCount
        }
    }
  }
`;

export const updateCampaignSmsBegin = () => ({
    type: UPDATE_CAMPAIGN_SMS_BEGIN
});

export const updateCampaignSmsSuccess = (campaignSms) => ({
    type: UPDATE_CAMPAIGN_SMS_SUCCESS,
    payload: {campaignSms}
});

export const updateCampaignSmsFailure = error => ({
    type: UPDATE_CAMPAIGN_SMS_FAILURE,
    payload: {error}
});

async function performUpdateCampaignSms(organisationId, campaignId, campaignSms) {
    const input = {...campaignSms};
    delete input.stats;
    delete input.html;
    const updateCampaignSmsResponse = await apolloClient.mutate({
        mutation: UPDATE_CAMPAIGN_SMS_MUTATION,
        variables: {
            organisationId,
            campaignId,
            campaignSmsInput: input,
        }
    });
    return updateCampaignSmsResponse.data.updateCampaignSms;
}

export function updateCampaignSms(organisationId, campaignId, campaignSms) {
    return async dispatch => {
        dispatch(updateCampaignSmsBegin());
        try {
            const updatedCampaign = await performUpdateCampaignSms(organisationId, campaignId, campaignSms);
            dispatch(updateCampaignSmsSuccess(updatedCampaign));
        } catch (error) {
            console.log(error);
            dispatch(updateCampaignSmsFailure(error));
        }
    };
}

export const uploadCampaignSmsImageBegin = () => ({
    type: UPLOAD_CAMPAIGN_SMS_IMAGE_BEGIN
});

export const uploadCampaignSmsImageSuccess = (campaignSms) => ({
    type: UPLOAD_CAMPAIGN_SMS_IMAGE_SUCCESS,
    payload: {campaignSms}
});

export const uploadCampaignSmsImageFailure = error => ({
    type: UPLOAD_CAMPAIGN_SMS_IMAGE_FAILURE,
    payload: {error}
});

export function uploadCampaignSmsImage(organisationId, campaignId, campaignSms, imageFile) {
    return async dispatch => {
        dispatch(uploadCampaignSmsImageBegin());
        try {
            const mediaEntry = await uploadImage(organisationId, imageFile, {
                width: 1000
            });

            campaignSms.imageUrl = mediaEntry.s3GetUrl;

            const updatedCampaignSms = await performUpdateCampaignSms(organisationId, campaignId, campaignSms);
            dispatch(uploadCampaignSmsImageSuccess(updatedCampaignSms));
        } catch (error) {
            dispatch(uploadCampaignSmsImageFailure(error));
        }
    };
}