import {apolloClient} from '../graphql'
import gql from "graphql-tag";

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
      firstName
      lastName
      email
      phone
      signUpType
      emailOptOut
      jwt
      organisations {
        id
        name
        allowSocialSignup
      }
      address {
        number
        street
        city
        state
        country
        zip
      }
      legislators {
        type
        divisionId
        bioId
        name
        greetingName
        party
        phone
        email
        url
        photoUrl
        socialMediaHandles {
          socialMediaType
          handle
        }
      }
    }
  }
`;

export function updateUser(user) {
    return async dispatch => {
        dispatch(updateUserBegin());
        try {
            const updateUserInput = {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                address: user.address,
                emailOptOut: user.emailOptOut,
                phone: user.phone
            };
            const updateUserResponse = await apolloClient.mutate({
                mutation: UPDATE_USER_MUTATION,
                variables: {
                    updateUserInput
                }
            });
            const updatedUser = updateUserResponse.data.updateUser;
            dispatch(updateUserSuccess(updatedUser));
        } catch (error) {
            dispatch(updateUserFailure(error));
        }
    };
}

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const updateUserBegin = () => ({
    type: UPDATE_USER_BEGIN
});

export const updateUserSuccess = (user) => ({
    type: UPDATE_USER_SUCCESS,
    payload: {user}
});

export const updateUserFailure = error => ({
    type: UPDATE_USER_FAILURE,
    payload: {error}
});

export const AUTH_USER_SET = "AUTH_USER_SET";
export const ADMIN_ORGANISATION_SET = "ADMIN_ORGANISATION_SET";
export const SESSION_EMAIL_SET = "SESSION_EMAIL_SET";
export const REDIRECT_AFTER_SIGNIN_URL_SET = "REDIRECT_AFTER_SIGNIN_URL_SET";
