import {apolloClient} from '../../graphql'
import gql from "graphql-tag";
import {uploadImage} from '../../imageupload/uploadImage';
import {push} from 'connected-react-router'
import * as routes from '../../constants/routes';
import {replaceParams} from '../../utils/link-utils';

const CREATE_CAMPAIGN_MUTATION = gql`
  mutation CreateCampaign($organisationId: Int!) {
    createCampaign(organisationId: $organisationId) {
        id
        status
        organisation {
            id
            name
            logoImageUrl
            whiteLabelLogoImageUrl
        }
        targetLegislators
        targetIndividualPolicymakers {
          id
        }
    }
  }
`;

export function createCampaign(organisationId) {
    return async dispatch => {
        dispatch(createCampaignBegin());
        try {
            const createCampaignResponse = await apolloClient.mutate({
                mutation: CREATE_CAMPAIGN_MUTATION,
                variables: {
                    organisationId: organisationId
                }
            });
            const createdCampaign = createCampaignResponse.data.createCampaign;
            dispatch(createCampaignSuccess(createdCampaign));
            dispatch(push(replaceParams(routes.CREATE_CAMPAIGN_DESCRIBE, {
                ":id": createdCampaign.id
            })));
        } catch (error) {
            dispatch(createCampaignFailure(error));
        }
    };
}

const UPDATE_CAMPAIGN_MUTATION = gql`
  mutation UpdateCampaign($organisationId: Int!, $updateCampaignInput: UpdateCampaignInput!) {
    updateCampaign(organisationId: $organisationId, updateCampaignInput: $updateCampaignInput) {
        id
        organisationNameOverride
        logoMediaId
        logoImageUrl
        status
        title
        description
        quoteEnabled
        quote
        quoteName
        quoteTitle
        quoteMediaId
        quoteImageUrl
        statePolicymakersEnabled
        federalPolicymakersEnabled
        states
        targetLegislators
        targetIndividualPolicymakers {
          id
        }
        actionTypes
        bannerMediaId
        bannerImageUrl
        bannerEnabled
        banner
        fixedBanner
        link
        shareText
        actionsCountEnabled
        donationsAmountEnabled
        actionsCountStart
        donationsAmountStart
        template
        allowEditActions
        requestPhoneNumbers
        requestPhoneText
        showThankYouMessage
        thankYouMessage
        emailIntro
        addTwitterHashtags
        sponsorsEnabled
        sponsorsHeadingText
        sponsors {
            name
            logoImageUrl
            url
        }
        advocacyActions {
            actionType
            subject
            topic
            content
        }
        organisation {
            id
            name
            logoImageUrl
            whiteLabelLogoImageUrl
            isStripeConnectionValid
        }
        donationsConfig {
            donationsEnabled
            donationsTitle
            donationsDescription
            donationAmounts
            allowAnyAmount
        }
        shareFacebookEnabled
        shareTwitterEnabled
        shareLinkedinEnabled
        shareParlerEnabled
        shareTelegramEnabled
        shareGabEnabled
        shareSignalEnabled
        shareEmailEnabled
        shareCopyEnabled
        verified
        verificationSubmitted
    }
  }
`;

async function performUpdateCampaignMutation(campaign, organisationId) {
    let updateCampaignInput = {...campaign};
    delete updateCampaignInput.advocacyActions;
    delete updateCampaignInput.organisation;
    delete updateCampaignInput.status;
    delete updateCampaignInput.targetIndividualPolicymakers;
    const updateCampaignResponse = await apolloClient.mutate({
        mutation: UPDATE_CAMPAIGN_MUTATION,
        variables: {
            organisationId,
            updateCampaignInput: updateCampaignInput
        }
    });
    return updateCampaignResponse.data.updateCampaign;
}

export function updateCampaign(organisationId, campaign) {
    return async dispatch => {
        dispatch(updateCampaignBegin());
        try {
            const updatedCampaign = await performUpdateCampaignMutation(campaign, organisationId);
            dispatch(updateCampaignSuccess(updatedCampaign));
        } catch (error) {
            dispatch(updateCampaignFailure(error));
        }
    };
}

/**
 *
 * @param {number} organisationId
 * @param {Campaign} campaign
 * @param {Campaign['status']} status
 * @param nextUrl if given, the url to advance to after status is updated
 */
export function updateCampaignStatus(organisationId, campaign, status, nextUrl) {
    return async dispatch => {
        dispatch(updateCampaignBegin());
        try {
            const {id} = campaign;
            const updateCampaignInput = { id, status };
            if (status === 'ACTIVE') {
                updateCampaignInput.verificationSubmitted = true;
            }
            const updateCampaignResponse = await apolloClient.mutate({
                mutation: UPDATE_CAMPAIGN_MUTATION,
                variables: {
                    organisationId,
                    updateCampaignInput
                }
            });
            dispatch(updateCampaignSuccess(updateCampaignResponse.data.updateCampaign));
            if (nextUrl) {
                dispatch(push(nextUrl));
            }
        } catch (error) {
            dispatch(updateCampaignFailure(error));
        }
    };
}

const DELETE_CAMPAIGN_MUTATION = gql`
  mutation DeleteCampaign($organisationId: Int!, $campaignId: Int!) {
    deleteCampaign(organisationId: $organisationId, campaignId: $campaignId) {
      success
    }
  }
`;

export function deleteCampaign(organisationId, campaign) {
    return async dispatch => {
        dispatch(deleteCampaignBegin());
        try {
            const {id} = campaign;
            await apolloClient.mutate({
                mutation: DELETE_CAMPAIGN_MUTATION,
                variables: {
                    organisationId,
                    campaignId: id
                }
            });
            dispatch(deleteCampaignSuccess(campaign));
        } catch (error) {
            dispatch(deleteCampaignFailure(error));
        }
    };
}

export function uploadCampaignBanner(organisationId, campaign, imageFile) {
    return async dispatch => {
        dispatch(uploadCampaignBannerBegin());
        try {
            const mediaEntry = await uploadImage(organisationId, imageFile, {
                width: 1000
            });

            campaign.bannerMediaId = mediaEntry.id;
            campaign.bannerImageUrl = mediaEntry.s3GetUrl;

            const updatedCampaign = await performUpdateCampaignMutation(campaign, organisationId);
            dispatch(uploadCampaignBannerSuccess(updatedCampaign));
        } catch (error) {
            dispatch(uploadCampaignBannerFailure(error));
        }
    };
}

export function uploadCampaignLogo(organisationId, campaign, imageFile) {
    return async dispatch => {
        dispatch(uploadCampaignLogoBegin());
        try {
            const mediaEntry = await uploadImage(organisationId, imageFile, {
                width: 1000
            });

            campaign.logoMediaId = mediaEntry.id;
            campaign.logoImageUrl = mediaEntry.s3GetUrl;

            const updatedCampaign = await performUpdateCampaignMutation(campaign, organisationId);
            dispatch(uploadCampaignLogoSuccess(updatedCampaign));
        } catch (error) {
            dispatch(uploadCampaignLogoFailure(error));
        }
    };
}

export function uploadQuoteImage(organisationId, campaign, imageFile) {
    return async dispatch => {
        dispatch(uploadQuoteImageBegin());
        try {
            const mediaEntry = await uploadImage(organisationId, imageFile, {
                width: 100
            });

            campaign.quoteMediaId = mediaEntry.id;
            campaign.quoteImageUrl = mediaEntry.s3GetUrl;

            const updatedCampaign = await performUpdateCampaignMutation(campaign, organisationId);
            dispatch(uploadQuoteImageSuccess(updatedCampaign));
        } catch (error) {
            dispatch(uploadQuoteImageFailure(error));
        }
    };
}

const LOAD_CAMPAIGN_QUERY = gql`
  query GetCampaign($organisationId: Int!, $campaignId: Int!) {
    campaign(organisationId: $organisationId, campaignId: $campaignId) {
        id
        organisationNameOverride
        logoMediaId
        logoImageUrl
        status
        title
        description
        quoteEnabled
        quote
        quoteName
        quoteTitle
        quoteMediaId
        quoteImageUrl
        statePolicymakersEnabled
        federalPolicymakersEnabled
        states
        targetLegislators
        targetIndividualPolicymakers {
          id
        }
        actionTypes
        bannerMediaId
        bannerImageUrl
        bannerEnabled
        banner
        fixedBanner
        link
        shareText
        actionsCountEnabled
        donationsAmountEnabled
        actionsCountStart
        donationsAmountStart
        template
        allowEditActions
        requestPhoneNumbers
        requestPhoneText
        showThankYouMessage
        thankYouMessage
        emailIntro
        addTwitterHashtags
        sponsorsEnabled
        sponsorsHeadingText
        sponsors {
            name
            logoImageUrl
            url
        }
        advocacyActions {
            actionType
            subject
            topic
            content
        }
        organisation {
            id
            name
            logoImageUrl
            whiteLabelLogoImageUrl
            isStripeConnectionValid
            verified
        }
        donationsConfig {
            donationsEnabled
            donationsTitle
            donationsDescription
            donationAmounts
            allowAnyAmount
        }
        shareFacebookEnabled
        shareTwitterEnabled
        shareLinkedinEnabled
        shareParlerEnabled
        shareTelegramEnabled
        shareGabEnabled
        shareSignalEnabled
        shareEmailEnabled
        shareCopyEnabled
        verified
        verificationSubmitted
    }
  }
`;

export function loadCampaign(organisationId, campaignId) {
    return async dispatch => {
        dispatch(loadCampaignBegin());
        try {
            const loadCampaignResponse = await apolloClient.query({
                query: LOAD_CAMPAIGN_QUERY,
                variables: {
                    organisationId,
                    campaignId
                }
            });
            const campaign = loadCampaignResponse.data.campaign;
            dispatch(loadCampaignSuccess(campaign));
        } catch (error) {
            dispatch(loadCampaignFailure(error));
        }
    };
}

const LOAD_IS_STRIPE_CONNECTION_VALID_QUERY = gql`
  query GetOrganisation($organisationId: Int!) {
    organisation(organisationId: $organisationId) {
        id
        name
        isStripeConnectionValid
    }
  }
`;

export const isStripeConnectionValid = async (organisationId) => {
    const response = await apolloClient.query({
        query: LOAD_IS_STRIPE_CONNECTION_VALID_QUERY,
        variables: {
            organisationId
        }
    });
    return response.data.organisation.isStripeConnectionValid;
};

const LIST_CAMPAIGNS_QUERY = gql`
  query ListCampaigns($organisationId: Int!) {
    campaignsForOrganisation(organisationId: $organisationId) {
        campaigns {
            id
            status
            title
            link
            organisationNameOverride
            description
            actionTypes
            bannerMediaId
            bannerImageUrl
            verified
            verificationSubmitted
            organisation {
              id
              name
            }
        }
    }
  }
`;

export function listCampaigns(organisationId) {
    return async dispatch => {
        dispatch(listCampaignsBegin());
        try {
            const listCampaignsResponse = await apolloClient.query({
                query: LIST_CAMPAIGNS_QUERY,
                variables: {
                    organisationId
                }
            });
            const campaigns = listCampaignsResponse.data.campaignsForOrganisation.campaigns;
            dispatch(listCampaignsSuccess(campaigns));
        } catch (error) {
            dispatch(listCampaignsFailure(error));
        }
    };
}

const LIST_FOLLOWED_CAMPAIGNS_QUERY = gql`
  query GetUser($userId: String!) {
    user(userId: $userId) {
        id
        followedCampaigns(max: 10) {
            id
            title
            description
            actionTypes
            bannerMediaId
            bannerImageUrl
            organisation {
                id
                name
                logoImageUrl
                whiteLabelLogoImageUrl
            }
            link
        }
    }
  }
`;

export function listFollowedCampaigns(userId) {
    return async dispatch => {
        dispatch(listCampaignsBegin());
        try {
            /**
             * @type {{data: User}}
             */
            const listCampaignsResponse = await apolloClient.query({
                query: LIST_FOLLOWED_CAMPAIGNS_QUERY,
                variables: {
                    userId
                }
            });
            const campaigns = listCampaignsResponse.data.user.followedCampaigns || [];
            dispatch(listFollowedCampaignsSuccess(campaigns));
        } catch (error) {
            dispatch(listCampaignsFailure(error));
        }
    };
}

const UPDATE_ADVOCACY_ACTION_MUTATION = gql`
  mutation UpdateAdvocacyAction($organisationId: Int!, $campaignId: Int!, $updateAdvocacyActionInput: UpdateAdvocacyActionInput!) {
    updateAdvocacyAction(organisationId: $organisationId, campaignId: $campaignId, updateAdvocacyActionInput: $updateAdvocacyActionInput) {
        actionType
        subject
        topic
        content
    }
  }
`;

export function updateAdvocacyAction(organisationId, campaignId, advocacyAction) {
    return async dispatch => {
        dispatch(updateAdvocacyActionBegin());
        try {
            const updateAdvocacyActionResponse = await apolloClient.mutate({
                mutation: UPDATE_ADVOCACY_ACTION_MUTATION,
                variables: {
                    organisationId,
                    campaignId,
                    updateAdvocacyActionInput: advocacyAction
                }
            });
            const updatedAdvocacyAction = updateAdvocacyActionResponse.data.updateAdvocacyAction;
            dispatch(updateAdvocacyActionSuccess(updatedAdvocacyAction));
        } catch (error) {
            dispatch(updateAdvocacyActionFailure(error));
        }
    };
}

export const CREATE_CAMPAIGN_BEGIN = "CREATE_CAMPAIGN_BEGIN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILURE = "CREATE_CAMPAIGN_FAILURE";

export const UPDATE_CAMPAIGN_BEGIN = "UPDATE_CAMPAIGN_BEGIN";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILURE = "UPDATE_CAMPAIGN_FAILURE";

export const DELETE_CAMPAIGN_BEGIN = "DELETE_CAMPAIGN_BEGIN";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";

export const UPLOAD_CAMPAIGN_BANNER_BEGIN = "UPLOAD_CAMPAIGN_BANNER_BEGIN";
export const UPLOAD_CAMPAIGN_BANNER_SUCCESS = "UPLOAD_CAMPAIGN_BANNER_SUCCESS";
export const UPLOAD_CAMPAIGN_BANNER_FAILURE = "UPLOAD_CAMPAIGN_BANNER_FAILURE";

export const UPLOAD_CAMPAIGN_LOGO_BEGIN = "UPLOAD_CAMPAIGN_LOGO_BEGIN";
export const UPLOAD_CAMPAIGN_LOGO_SUCCESS = "UPLOAD_CAMPAIGN_LOGO_SUCCESS";
export const UPLOAD_CAMPAIGN_LOGO_FAILURE = "UPLOAD_CAMPAIGN_LOGO_FAILURE";

export const UPLOAD_QUOTE_IMAGE_BEGIN = "UPLOAD_QUOTE_IMAGE_BEGIN";
export const UPLOAD_QUOTE_IMAGE_SUCCESS = "UPLOAD_QUOTE_IMAGE_SUCCESS";
export const UPLOAD_QUOTE_IMAGE_FAILURE = "UPLOAD_QUOTE_IMAGE_FAILURE";

export const UPDATE_ADVOCACY_ACTION_BEGIN = "UPDATE_ADVOCACY_ACTION_BEGIN";
export const UPDATE_ADVOCACY_ACTION_SUCCESS = "UPDATE_ADVOCACY_ACTION_SUCCESS";
export const UPDATE_ADVOCACY_ACTION_FAILURE = "UPDATE_ADVOCACY_ACTION_FAILURE";

export const LOAD_CAMPAIGN_BEGIN = "LOAD_CAMPAIGN_BEGIN";
export const LOAD_CAMPAIGN_SUCCESS = "LOAD_CAMPAIGN_SUCCESS";
export const LOAD_CAMPAIGN_FAILURE = "LOAD_CAMPAIGN_FAILURE";

export const LIST_CAMPAIGNS_BEGIN = "LIST_CAMPAIGNS_BEGIN";
export const LIST_CAMPAIGNS_SUCCESS = "LIST_CAMPAIGNS_SUCCESS";
export const LIST_CAMPAIGNS_FAILURE = "LIST_CAMPAIGNS_FAILURE";

export const LIST_FOLLOWED_CAMPAIGNS_BEGIN = "LIST_FOLLOWED_CAMPAIGNS_BEGIN";
export const LIST_FOLLOWED_CAMPAIGNS_SUCCESS = "LIST_FOLLOWED_CAMPAIGNS_SUCCESS";
export const LIST_FOLLOWED_CAMPAIGNS_FAILURE = "LIST_FOLLOWED_CAMPAIGNS_FAILURE";

export const createCampaignBegin = () => ({
    type: CREATE_CAMPAIGN_BEGIN
});

export const createCampaignSuccess = (campaign) => ({
    type: CREATE_CAMPAIGN_SUCCESS,
    payload: {campaign}
});

export const createCampaignFailure = error => ({
    type: CREATE_CAMPAIGN_FAILURE,
    payload: {error}
});

export const updateCampaignBegin = () => ({
    type: UPDATE_CAMPAIGN_BEGIN
});

export const updateCampaignSuccess = (campaign) => ({
    type: UPDATE_CAMPAIGN_SUCCESS,
    payload: {campaign}
});

export const updateCampaignFailure = error => ({
    type: UPDATE_CAMPAIGN_FAILURE,
    payload: {error}
});

export const deleteCampaignBegin = () => ({
    type: DELETE_CAMPAIGN_BEGIN
});

export const deleteCampaignSuccess = (campaign) => ({
    type: DELETE_CAMPAIGN_SUCCESS,
    payload: {campaign}
});

export const deleteCampaignFailure = error => ({
    type: DELETE_CAMPAIGN_FAILURE,
    payload: {error}
});

export const uploadCampaignBannerBegin = () => ({
    type: UPLOAD_CAMPAIGN_BANNER_BEGIN
});

export const uploadCampaignBannerSuccess = (campaign) => ({
    type: UPLOAD_CAMPAIGN_BANNER_SUCCESS,
    payload: {campaign}
});

export const uploadCampaignBannerFailure = error => ({
    type: UPLOAD_CAMPAIGN_BANNER_FAILURE,
    payload: {error}
});

export const uploadCampaignLogoBegin = () => ({
    type: UPLOAD_CAMPAIGN_LOGO_BEGIN
});

export const uploadCampaignLogoSuccess = (campaign) => ({
    type: UPLOAD_CAMPAIGN_LOGO_SUCCESS,
    payload: {campaign}
});

export const uploadCampaignLogoFailure = error => ({
    type: UPLOAD_CAMPAIGN_LOGO_FAILURE,
    payload: {error}
});

export const uploadQuoteImageFailure = error => ({
    type: UPLOAD_QUOTE_IMAGE_FAILURE,
    payload: {error}
});

export const uploadQuoteImageBegin = () => ({
    type: UPLOAD_QUOTE_IMAGE_BEGIN
});

export const uploadQuoteImageSuccess = (campaign) => ({
    type: UPLOAD_QUOTE_IMAGE_SUCCESS,
    payload: {campaign}
});

export const updateAdvocacyActionBegin = () => ({
    type: UPDATE_ADVOCACY_ACTION_BEGIN
});

export const updateAdvocacyActionSuccess = (advocacyAction) => ({
    type: UPDATE_ADVOCACY_ACTION_SUCCESS,
    payload: {advocacyAction}
});

export const updateAdvocacyActionFailure = error => ({
    type: UPDATE_ADVOCACY_ACTION_FAILURE,
    payload: {error}
});

export const loadCampaignBegin = () => ({
    type: LOAD_CAMPAIGN_BEGIN
});

export const loadCampaignSuccess = (campaign) => ({
    type: LOAD_CAMPAIGN_SUCCESS,
    payload: {campaign}
});

export const loadCampaignFailure = error => ({
    type: LOAD_CAMPAIGN_FAILURE,
    payload: {error}
});

export const listCampaignsBegin = () => ({
    type: LIST_CAMPAIGNS_BEGIN
});

export const listCampaignsSuccess = (campaigns) => ({
    type: LIST_CAMPAIGNS_SUCCESS,
    payload: {campaigns}
});

export const listCampaignsFailure = error => ({
    type: LIST_CAMPAIGNS_FAILURE,
    payload: {error}
});

export const listFollowedCampaignsBegin = () => ({
    type: LIST_FOLLOWED_CAMPAIGNS_BEGIN
});

export const listFollowedCampaignsSuccess = (campaigns) => ({
    type: LIST_FOLLOWED_CAMPAIGNS_SUCCESS,
    payload: {campaigns}
});

export const listFollowedCampaignsFailure = error => ({
    type: LIST_FOLLOWED_CAMPAIGNS_FAILURE,
    payload: {error}
});