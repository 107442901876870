import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { connect } from 'react-redux';
import * as ROUTES from '../../constants/routes';

import { firebase } from "../../firebase";
import FullScreenProgress from "../Common/FullScreenProgress";
import {REDIRECT_AFTER_SIGNIN_URL_SET} from "../../session/sessionActions";

function validToken(idTokenResult) {
    return idTokenResult.claims.organisations;
}

const withAuthorisation = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            const {setRedirectAfterSigninUrl} = this.props;

            let currentUser = firebase.auth.currentUser;
            currentUser && currentUser.reload();
            currentUser && currentUser.getIdToken(true);

            this.listener = firebase.auth.onIdTokenChanged(authUser => {
                // eslint-disable-next-line no-console
                console.log(authUser)
                if (authUser === null) {
                    setRedirectAfterSigninUrl(this.props.history.location);
                    this.props.history.replace(!this.props.authCampaign || this.props.authCampaign.allowSocialSignup ? ROUTES.SIGN_UP_OR_SIGN_IN : ROUTES.EMAIL_SIGNUP + "?start=true");
                } else {
                    authUser.getIdTokenResult()
                        .then((idTokenResult) => {
                            const {skipSignInRedirect} = this.props;
                            if ((!condition(authUser) || !validToken(idTokenResult)) && !skipSignInRedirect) {
                                setRedirectAfterSigninUrl(this.props.history.location);
                                this.props.history.replace(!this.props.authCampaign || this.props.authCampaign.allowSocialSignup ? ROUTES.SIGN_UP_OR_SIGN_IN : ROUTES.EMAIL_SIGNUP + "?start=true");
                            }
                        });
                }
            });
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return condition(this.props.authUser) ? (
                <Component {...this.props} />
            ) : <FullScreenProgress/>;
        }
    }

    const mapDispatchToProps = dispatch => ({
        setRedirectAfterSigninUrl: (redirectAfterSigninUrl) => dispatch({type: REDIRECT_AFTER_SIGNIN_URL_SET, redirectAfterSigninUrl})
    });

    const mapStateToProps = state => ({
        authUser: state.session.authUser,
        authCampaign: state.viewCampaign.campaign,
        skipSignInRedirect: state.accountSettings.skipSignInRedirect,
    });

    return compose(
        withRouter,
        connect(mapStateToProps, mapDispatchToProps),
    )(WithAuthorization);
};

export default withAuthorisation;
