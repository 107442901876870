import {
    CREATE_CAMPAIGN_SMS_BEGIN,
    CREATE_CAMPAIGN_SMS_FAILURE,
    CREATE_CAMPAIGN_SMS_SUCCESS,
    LIST_CAMPAIGN_SMS_BEGIN,
    LIST_CAMPAIGN_SMS_FAILURE,
    LIST_CAMPAIGN_SMS_SUCCESS,
    LOAD_CAMPAIGN_SMS_BEGIN,
    LOAD_CAMPAIGN_SMS_FAILURE,
    LOAD_CAMPAIGN_SMS_SUCCESS, UPDATE_CAMPAIGN_SMS_BEGIN, UPDATE_CAMPAIGN_SMS_FAILURE, UPDATE_CAMPAIGN_SMS_SUCCESS,
    UPLOAD_CAMPAIGN_SMS_IMAGE_BEGIN,
    UPLOAD_CAMPAIGN_SMS_IMAGE_FAILURE,
    UPLOAD_CAMPAIGN_SMS_IMAGE_SUCCESS,
} from "./campaignSmsActions";

const initialState = {
    currentSms: null,
    campaignSms: null,
    smsCounts: null,
    loading: false,
    updating: false,
    error: null,
    imageUploading: false
};

export default function campaignSmsReducer(state = initialState, action) {

    switch (action.type) {
        case LOAD_CAMPAIGN_SMS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LOAD_CAMPAIGN_SMS_SUCCESS:
            return {
                ...state,
                loading: false,
                currentSms: action.payload.campaignWithSmsAndCounts.campaignSms[0]
            };

        case LOAD_CAMPAIGN_SMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };
        case CREATE_CAMPAIGN_SMS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case CREATE_CAMPAIGN_SMS_SUCCESS:
            return {
                ...state,
                loading: false,
                currentSms: action.payload.campaignSms
            };

        case CREATE_CAMPAIGN_SMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case LIST_CAMPAIGN_SMS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LIST_CAMPAIGN_SMS_SUCCESS:
            return {
                ...state,
                loading: false,
                sms: action.payload.campaignWithSmsAndCounts.campaignSms,
                smsCounts: action.payload.campaignWithSmsAndCounts.organisation
            };

        case LIST_CAMPAIGN_SMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case UPDATE_CAMPAIGN_SMS_BEGIN:
            return {
                ...state,
                updating: true,
                error: null
            };

        case UPDATE_CAMPAIGN_SMS_SUCCESS:
            return {
                ...state,
                updating: false,
                currentSms: action.payload.campaignSms
            };

        case UPDATE_CAMPAIGN_SMS_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.payload.error.message
            };

        case UPLOAD_CAMPAIGN_SMS_IMAGE_BEGIN:
            return {
                ...state,
                imageUploading: true,
                error: null
            };

        case UPLOAD_CAMPAIGN_SMS_IMAGE_SUCCESS:
            return {
                ...state,
                imageUploading: false,
                currentSms: action.payload.campaignSms
            };

        case UPLOAD_CAMPAIGN_SMS_IMAGE_FAILURE:
            return {
                ...state,
                imageUploading: false,
                error: action.payload.error.message
            };

        default:
            return state;
    }
}
