import React from "react";
import {connect} from "react-redux";

import {firebase} from "../../firebase";
import gql from "graphql-tag";
import {apolloClient} from "../../graphql";
import {prependDomainToAuthToken} from "../../constants/domains";
import {AUTH_USER_SET} from "../../session/sessionActions";

const LOAD_USER_QUERY = gql`
  query GetUser($userId: String!) {
    user(userId: $userId) {
      id
      firstName
      lastName
      email
      phone
      signUpType
      emailOptOut
      jwt
      organisations {
        id
        name
        logoMediaId
        logoImageUrl
        whiteLabelLogoImageUrl
        googleAnalyticsId
        facebookPixelId
        verified
        verificationSubmitted
        allowSocialSignup
      }
      address {
        number
        street
        city
        state
        country
        zip
      }
      legislators {
        type
        divisionId
        bioId
        name
        greetingName
        party
        phone
        email
        url
        photoUrl
        socialMediaHandles {
          socialMediaType
          handle
        }
      }
    }
  }
`;

const loadUser = async (userId, token) => {
    const loadUserResponse = await apolloClient.query({
        query: LOAD_USER_QUERY,
        variables: {
            userId
        },
        context: {
            headers: {
                Authorization: `Bearer ${prependDomainToAuthToken(token)}`
            }
        }
    });
    return loadUserResponse.data.user;
};

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        componentDidMount() {
            const {onSetAuthUser} = this.props;

            firebase.auth.onIdTokenChanged(authUser => {
                // eslint-disable-next-line no-console
                console.log(authUser)
                if (!authUser) {
                    onSetAuthUser(null);
                } else {
                    authUser.getIdTokenResult()
                        .then((idTokenResult) => {
                            if (idTokenResult.claims.organisations) {
                                loadUser(authUser.uid, idTokenResult.token)
                                    .then(user => onSetAuthUser(authUser, user))
                            } else {
                                onSetAuthUser(null);
                            }
                        });
                }
            });
        }

        render() {
            return <Component {...this.props} />;
        }
    }

    const mapDispatchToProps = dispatch => ({
        onSetAuthUser: (authUser, user) => dispatch({type: AUTH_USER_SET, authUser, user}),
    });

    return connect(
        null,
        mapDispatchToProps
    )(WithAuthentication);
};

export default withAuthentication;
