export const replaceParams = (link, params) => {
    return link.replace(/:\w+/g, function (segment) {
        return params[segment] || segment;
    });
};

export const getCampaignUrl = (campaign) => {
    return `${window.location.origin}/go/${campaign.link}`;
}

export const getCopyCampaignUrl = (campaign) => `${window.location.origin}/copycampaign/${campaign.link}`;