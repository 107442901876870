import {combineReducers} from "redux";
import session from "./session/sessionReducer";
import {connectRouter} from 'connected-react-router';
import campaigns from "./components/CreateCampaign/campaignReducer";
import events from "./components/CreateEvent/eventReducer";
import policymakers from "./components/CreateCampaign/policymakerReducer";
import campaignEmail from './components/CreateCampaign/campaignEmailReducer';
import campaignSms from './components/CreateCampaign/campaignSmsReducer';
import eventEmail from './components/CreateEvent/eventEmailReducer';
import viewCampaign from "./components/ViewCampaign/viewCampaignReducer";
import previewCampaign from "./components/PreviewCampaign/previewCampaignReducer"
import advocateActions from "./components/AdvocateActions/reducer";
import accountSettings from './components/AccountSettings/accountSettingsReducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    session,
    campaigns,
    events,
    policymakers,
    campaignEmail,
    campaignSms,
    eventEmail,
    viewCampaign,
    previewCampaign,
    advocateActions,
    accountSettings
});
