import ReactGA from "react-ga";

function initialiseTracker(campaign) {
    ReactGA.initialize(campaign.organisation.googleAnalyticsId, {
        alwaysSendToDefaultTracker: false,
        gaOptions: {
            name: campaign.organisation.id.toString()
        }
    });
}

export const organisationGaPageView = (campaign) => {
    if (campaign.organisation.googleAnalyticsId) {
        initialiseTracker(campaign);
        ReactGA.pageview(window.location.pathname + window.location.search, [campaign.organisation.id.toString()]);
    }
};

export const gaEvent = (campaign, event) => {
    let trackerNames;
    if (campaign.organisation.googleAnalyticsId) {
        initialiseTracker(campaign);
        trackerNames = [campaign.organisation.id.toString(), 'freerootsTracker'];
    } else {
        trackerNames = ['freerootsTracker'];
    }

    ReactGA.event(event, trackerNames);
};