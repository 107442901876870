import React, {useState} from 'react';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {replaceParams} from "../../utils/link-utils";
import {advocateActionsRoute, viewCampaignRoute} from '../../constants/routes';
import SimpleTemplate from './simpleTemplate';
import VividTemplate from './vividTemplate';
import PhotoFocusTemplate from './photoFocusTemplate';
import DonateDialog from '../Payment/Donate/dialog';
import queryString from "query-string";
import DonationThanksModal from "../Payment/Donate/donateThanks";
import * as ActionType from "../../constants/actionTypes";
import {gatherPolicymakers} from "../../utils/gather-policymakers";
import {useStateWithSessionStorage} from "../../utils/sessionStorage";

const Template = {
    SIMPLE: "SIMPLE",
    VIVID: "VIVID",
    PHOTO_FOCUS: "PHOTO_FOCUS",
};

function firstActionType(campaign, user) {
    let actionTypes = campaign.actionTypes;
    if (!user) {
        return actionTypes[0];
    }
    let index = 0
    let valid = false;
    while (!valid && index < actionTypes.length) {
        const actionType = actionTypes[index]
        if (actionType === ActionType.PHONE && actionTypes.includes(actionType)) {
            const hasAtLeastOnePhone = !!gatherPolicymakers(campaign, user).find(policymaker => policymaker.phone);
            if (!hasAtLeastOnePhone) {
                index++;
                continue;
            }
        }
        if (actionType === ActionType.FACEBOOK && actionTypes.includes(ActionType.FACEBOOK)) {
            const hasAtLeastOneFacebook = !!gatherPolicymakers(campaign, user).find(policymaker => policymaker.facebook);
            if (!hasAtLeastOneFacebook) {
                index++;
                continue;
            }
        }
        if (actionType === ActionType.TWEET && actionTypes.includes(ActionType.TWEET)) {
            const hasAtLeastOneTwitter = !!gatherPolicymakers(campaign, user).find(policymaker => policymaker.twitter);
            if (!hasAtLeastOneTwitter) {
                index++;
                continue;
            }
        }
        return actionType
    }
    return "complete";
}

const donateHash = '#donate';
/**
 * 
 * @param {Object} params
 * @param {Campaign} params.campaign
 */
const ViewCampaign = ({ campaign, history, user, preview, onChange }) => {
    const defaultDonation = (campaign.donationsConfig.donationAmounts.length && campaign.donationsConfig.donationAmounts[0]) || 0;
    const [selectedDonation, setSelectedDonation] = useState({ type: defaultDonation ? 'radio' : 'freeform', amount: defaultDonation || '' });
    const [donationThanksOpen, setDonationThanksOpen] = useState(false);
    const [allowBlankPassword, setAllowBlankPassword] = useStateWithSessionStorage('allowBlankPassword',"false");

    const handleJoinCampaign = () => {
        if (!preview) {
            setAllowBlankPassword("true");
            setTimeout(  //set is queued and not ready when routing witaout a timeout
                () => history.push(replaceParams(advocateActionsRoute(), {":link": campaign.link, ":action": firstActionType(campaign, user).toLowerCase()}))
                , 200);
        }
    };

    const donateDialogOpen = history.location.hash === donateHash;
    const queryParams = queryString.parse(history.location.search);
    const donationAmount = queryParams.amount;

    const onDonateClick = () => {
        history.push(replaceParams(viewCampaignRoute(), { ":link": campaign.link }) + "?amount=" + selectedDonation.amount + donateHash);
    };
    const onDonationClose = (donationSucceeded) => {
        history.push(replaceParams(viewCampaignRoute(), { ":link": campaign.link }));
        onChange && onChange();
        if (donationSucceeded === true) {
            setDonationThanksOpen(true);
        }
    };
    const onChangeSelectedDonation = (change) => {
        setSelectedDonation(change);
    };

    let template;

    switch (campaign.template) {
        case Template.VIVID:
            template =
                <VividTemplate
                    campaign={campaign}
                    handleJoinCampaign={handleJoinCampaign}
                    donationProps={{
                        onChangeSelectedDonation,
                        selectedDonation,
                        onDonateClick
                    }}
                />;
            break;
        case Template.PHOTO_FOCUS:
            template =
                <PhotoFocusTemplate
                    preview={preview}
                    campaign={campaign}
                    handleJoinCampaign={handleJoinCampaign}
                    donationProps={{
                        onChangeSelectedDonation,
                        selectedDonation,
                        onDonateClick
                    }}
                />;
            break;
        case Template.SIMPLE:
        default:
            template =
                <SimpleTemplate
                    campaign={campaign}
                    handleJoinCampaign={handleJoinCampaign}
                    donationProps={{
                        onChangeSelectedDonation,
                        selectedDonation,
                        onDonateClick
                    }}
                />;
    }

    return <React.Fragment>
        {template}
        <DonateDialog open={donateDialogOpen} amount={donationAmount} handleClose={onDonationClose} user={user} campaign={campaign} />
        <DonationThanksModal open={donationThanksOpen} setOpen={setDonationThanksOpen} />
    </React.Fragment>;
};

export default compose(
    withRouter,
)(ViewCampaign);
