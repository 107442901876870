import firebase from "firebase/app";
import "firebase/auth";
import {DOMAIN_CONFIG, DOMAIN_DE, DOMAIN_FR, DOMAIN_AA, DOMAIN_AE} from "../constants/domains";


const firebaseConfig = {
    production: {
        [DOMAIN_FR]: {
            apiKey: "AIzaSyAKZeozsEjDFZLu9pUZSiU0SDg0GDnuZw4",
            authDomain: "freeroots-prod.firebaseapp.com",
            databaseURL: "https://freeroots-prod.firebaseio.com",
            projectId: "freeroots-prod",
            storageBucket: "",
            messagingSenderId: "784966133147",
            appId: "1:784966133147:web:622c66fe396e0d91"
        },
        [DOMAIN_DE]: {
            apiKey: "AIzaSyD0Z6P9-QTdnKXuKTN1cqXQ-L2MPF1X2i4",
            authDomain: "direct-engage-prod.firebaseapp.com",
            databaseURL: "https://direct-engage-prod.firebaseio.com",
            projectId: "direct-engage-prod",
            storageBucket: "direct-engage-prod.appspot.com",
            messagingSenderId: "803023478632",
            appId: "1:803023478632:web:172f83b339935d1156c0dd",
            measurementId: "G-3G142WDJ77"
        },
        // TODO: generate separate keys for prod
        [DOMAIN_AA]: {},
        [DOMAIN_AE]: {}
    },
    staging: {
        [DOMAIN_FR]: {
            apiKey: "AIzaSyDcsPtILewECHeEG_Kh7XjEQ2pONDuH80E",
            authDomain: "freeroots-staging.firebaseapp.com",
            databaseURL: "https://freeroots-staging.firebaseio.com",
            projectId: "freeroots-staging",
            storageBucket: "freeroots-staging.appspot.com",
            messagingSenderId: "512921452562"
        },
        [DOMAIN_DE]: {
            apiKey: "AIzaSyDuzMPPIU59QgnWUrv58s4SV0E7bZNBrNE",
            authDomain: "direct-engage-staging.firebaseapp.com",
            databaseURL: "https://direct-engage-staging.firebaseio.com",
            projectId: "direct-engage-staging",
            storageBucket: "direct-engage-staging.appspot.com",
            messagingSenderId: "915885839177",
            appId: "1:915885839177:web:8ec7735ac95e4d020e4098",
            measurementId: "G-DF51WHZ757"
        },
        [DOMAIN_AA]: {
            apiKey: "AIzaSyAs7QLQHnF0_dd8kFLxaVrvBOzlhVzy6Jc",
            authDomain: "alignact-staging.firebaseapp.com",
            projectId: "alignact-staging",
            storageBucket: "alignact-staging.appspot.com",
            messagingSenderId: "89718696737",
            appId: "1:89718696737:web:9976b735b783016835ec60",
            measurementId: "G-S5HN972V85"
        },
        [DOMAIN_AE]: {
            apiKey: "AIzaSyCAd8UfINf2-Qt9JHempvnJfEk2wPjkv48",
            authDomain: "alignevents-staging.firebaseapp.com",
            projectId: "alignevents-staging",
            storageBucket: "alignevents-staging.appspot.com",
            messagingSenderId: "1049248655493",
            appId: "1:1049248655493:web:d0713196b63f2037a3a1ec",
            measurementId: "G-42JET3RCBW"
        }    },
    development: {
        [DOMAIN_FR]: {
            apiKey: "AIzaSyCJIStYK6hojH7m6DRyPE7X2Mrjh2O0-Vo",
            authDomain: "freeroots-local.firebaseapp.com",
            databaseURL: "https://freeroots-local.firebaseio.com",
            projectId: "freeroots-local",
            storageBucket: "",
            messagingSenderId: "freeroots-local",
            appId: "1:962724768875:web:42e018abba6bfcd6"
        },
        [DOMAIN_DE]: {
            apiKey: "AIzaSyBaTNertTDeySFiUMrn-BWSYrle1v0KRb0",
            authDomain: "direct-engage-local.firebaseapp.com",
            databaseURL: "https://direct-engage-local.firebaseio.com",
            projectId: "direct-engage-local",
            storageBucket: "direct-engage-local.appspot.com",
            messagingSenderId: "176879706244",
            appId: "1:176879706244:web:561334eb2a466197cae2d1",
            measurementId: "G-02BGKCDX2Z"
        },
        [DOMAIN_AA]: {
            apiKey: "AIzaSyAOOKmywKoQyzBi_YqSJaxv6SY7UsAiD6I",
            authDomain: "alignevent-gurgen-local.firebaseapp.com",
            projectId: "alignevent-gurgen-local",
            storageBucket: "alignevent-gurgen-local.appspot.com",
            messagingSenderId: "870320293533",
            appId: "1:870320293533:web:059f26f04275d61c2fb674"
        },
        [DOMAIN_AE]: {
            apiKey: "AIzaSyAOOKmywKoQyzBi_YqSJaxv6SY7UsAiD6I",
            authDomain: "alignevent-gurgen-local.firebaseapp.com",
            projectId: "alignevent-gurgen-local",
            storageBucket: "alignevent-gurgen-local.appspot.com",
            messagingSenderId: "870320293533",
            appId: "1:870320293533:web:059f26f04275d61c2fb674"
        }
    }
}

const getFirebaseConfig = () => {
    const env = process.env.REACT_APP_ENV || "development";
    const conf = firebaseConfig[env][DOMAIN_CONFIG.domainKey];
    if (!conf) {
        throw Error(`Unsupported domain ${window.location.host} (${DOMAIN_CONFIG.domainKey})`);
    }
    return conf;
};

if (!firebase.apps.length) {
    firebase.initializeApp(getFirebaseConfig());
}

const auth = firebase.auth();

export {auth};