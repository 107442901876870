import React, {useState} from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import classNames from "classnames";

import './index.css';
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 24,
        marginRight: 24,
        maxWidth: 600
    },
    width500: {
        width: "100%",
        maxWidth: 500
    }
}));


const DonateStep1 = ({setStep}) => {
    const [is18, setIs18] = useState(false);
    const [isCitizen, setIsCitizen] = useState(false);
    const [isIndividual, setIsIndividual] = useState(false);
    const [isSelf, setIsSelf] = useState(false);
    const [showError, setShowError] = useState(false);

    const classes = useStyles();

    const handleSubmit = async () => {
        if (is18 && isCitizen && isIndividual && isSelf) {
            setStep(2);
        } else {
            setShowError(true);
        }
    };

    return (
        <div className={classNames(classes.root)}>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={is18}
                                onChange={(event, checked) => setIs18(checked)}
                            />
                        }
                        label={"I am 18 years or older."}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={isCitizen}
                                onChange={(event, checked) => setIsCitizen(checked)}
                            />
                        }
                        label={"I am a US citizen with permanent resident status in the United States."}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={isIndividual}
                                onChange={(event, checked) => setIsIndividual(checked)}
                            />
                        }
                        label={"I am not contributing using funds of a corporation, LLC taxed as a corporation, union, national bank, or a U.S government contractor."}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={isSelf}
                                onChange={(event, checked) => setIsSelf(checked)}
                            />
                        }
                        label={"I am not being paid or reimbursed for the contribution being made."}
                    />
                </Grid>
                {showError &&
                <Grid item xs={12}>
                    <Typography variant="body1" color="error">Please check all four boxes above. If you are unable to do so, federal law does not permit us to process your contribution. Thank you for your expression of support, and please contact us if you would like to volunteer to help our campaign.</Typography>
                </Grid>}
                <Grid item xs={12}>
                    <Button variant="contained" buttonClassName={classes.width500} className={classes.width500} size="large"
                                  color="primary" onClick={handleSubmit}>NEXT</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default DonateStep1;