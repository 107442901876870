import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {DOMAIN_FR, DOMAIN_DE, DOMAIN_AA, DOMAIN_AE, getDomain} from "../constants/domains";

import homeDeEn from "../translations/directengage/en/home.json"
import organisationDeEn from "../translations/directengage/en/organisation.json"
import campaignDeEn from "../translations/directengage/en/campaign.json"
import eventDeEn from "../translations/directengage/en/event.json"
import promoteDeEn from "../translations/directengage/en/promote.json"
import commonDeEn from "../translations/directengage/en/common.json"

import homeFrEn from "../translations/freeroots/en/home.json"
import organisationFrEn from "../translations/freeroots/en/organisation.json"
import campaignFrEn from "../translations/freeroots/en/campaign.json"
import eventFrEn from "../translations/freeroots/en/event.json"
import promoteFrEn from "../translations/freeroots/en/promote.json"
import commonFrEn from "../translations/freeroots/en/common.json"

import homeAAEn from "../translations/alignact/en/home.json"
import organisationAAEn from "../translations/alignact/en/organisation.json"
import campaignAAEn from "../translations/alignact/en/campaign.json"
import eventAAEn from "../translations/alignact/en/event.json"
import promoteAAEn from "../translations/alignact/en/promote.json"
import commonAAEn from "../translations/alignact/en/common.json"

import homeAEEn from "../translations/alignevent/en/home.json"
import organisationAEEn from "../translations/alignevent/en/organisation.json"
import campaignAEEn from "../translations/alignevent/en/campaign.json"
import eventAEEn from "../translations/alignevent/en/event.json"
import promoteAEEn from "../translations/alignevent/en/promote.json"
import commonAEEn from "../translations/alignevent/en/common.json"

let homeEn, organisationEn, campaignEn, eventEn, promoteEn, commonEn;
switch (getDomain()) {
    case DOMAIN_DE:
        // import module for side effects
        homeEn = homeDeEn;
        organisationEn = organisationDeEn;
        campaignEn = campaignDeEn;
        eventEn = eventDeEn;
        promoteEn = promoteDeEn;
        commonEn = commonDeEn;
        break;
    case DOMAIN_AE:
        homeEn = homeAEEn;
        organisationEn = organisationAEEn;
        campaignEn = campaignAEEn;
        eventEn = eventAEEn;
        promoteEn = promoteAEEn;
        commonEn = commonAEEn;
        break;
    case DOMAIN_AA:
        homeEn = homeAAEn;
        organisationEn = organisationAAEn;
        campaignEn = campaignAAEn;
        eventEn = eventAAEn;
        promoteEn = promoteAAEn;
        commonEn = commonAAEn;
        break;
    case DOMAIN_FR:
    default:
        homeEn = homeFrEn
        organisationEn = organisationFrEn
        campaignEn = campaignFrEn
        eventEn = eventFrEn
        promoteEn = promoteFrEn
        commonEn = commonFrEn
}

const resources = {
    en: {
        translation: {
            home: homeEn,
            organisation: organisationEn,
            campaign: campaignEn,
            event: eventEn,
            promote: promoteEn,
            common: commonEn
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });