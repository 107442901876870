// export const DONATION_METHOD_STRIPE = "STRIPE"
//     , DONATION_METHOD_NONE = "NONE";

export const DOMAIN_FR = "FREEROOTS"
    , DOMAIN_DE = "DIRECTENGAGE"
    , DOMAIN_AA = "ALIGNACT"
    , DOMAIN_AE = "ALIGNEVENT"
;

let DOMAINS = [
    {
        domainKey: DOMAIN_AA,
        matches: host => host.includes("alignact"),
        organizerVerificationEnabled: false,
        campaignsEnabled: true,
        // donationMethod: DONATION_METHOD_STRIPE,
        eventsEnabled: false
    },
    {
        domainKey: DOMAIN_AE,
        matches: host => host.includes("alignevent"),
        organizerVerificationEnabled: false,
        campaignsEnabled: false,
        // donationMethod: DONATION_METHOD_NONE,
        eventsEnabled: true
    },
    {
        domainKey: DOMAIN_FR,
        matches: host => host.includes("freeroots.com"),
        organizerVerificationEnabled: false,
        campaignsEnabled: true,
        // donationMethod: DONATION_METHOD_STRIPE,
        eventsEnabled: true
    },
    {
        domainKey: DOMAIN_DE,
        matches: host => host.includes("directengage.org") || host.includes("direct-engage.com"),
        organizerVerificationEnabled: true,
        campaignsEnabled: true,
        // donationMethod: DONATION_METHOD_STRIPE,
        eventsEnabled: true
    },
    // fallback
    {
        domainKey: DOMAIN_FR,
        matches: () => true,
        organizerVerificationEnabled: true,
        campaignsEnabled: true,
        // donationMethod: DONATION_METHOD_STRIPE,
        eventsEnabled: true
    }
];

export const DOMAIN_CONFIG = DOMAINS.find(d => d.matches(window.location.host));
DOMAINS = null


export const isDE = DOMAIN_CONFIG.domainKey === DOMAIN_DE;
export const isFE = DOMAIN_CONFIG.domainKey === DOMAIN_FR;
export const isAE = DOMAIN_CONFIG.domainKey === DOMAIN_AE;
export const isAA = DOMAIN_CONFIG.domainKey === DOMAIN_AA;

export function getDomain() {
    return DOMAIN_CONFIG.domainKey;
}

export function prependDomainToAuthToken(token) {
    return `${DOMAIN_CONFIG.domainKey}:${token}`
}

