import React, {useEffect} from 'react';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {loadCampaign} from '../viewCampaignActions';
import ViewCampaign from "../../ViewCampaign";
import {useStateWithSessionStorage} from "../../../utils/sessionStorage";
import FullScreenProgress from "../../Common/FullScreenProgress";
import {organisationGaPageView} from "../../../analytics/googleAnalytics";
import {organisationFacebookPixelPageView} from "../../../analytics/facebookPixel";

const ViewCampaignPage = ({match, campaign, campaignError, loadCampaign, user}) => {

    const [initialPage, setInitialPage] = useStateWithSessionStorage('initialPage');

    useEffect(() => {
        if (!initialPage) {
            // We use this link as our default link to redirect to if somehow redux session reducer state is lost
            setInitialPage(window.location.pathname);
        }
    });

    useEffect(() => {
        loadCampaign(match.params.link);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.link]);

    let campaignId = campaign ? campaign.id : null;
    useEffect(() => {
        if (campaignId) {
            organisationGaPageView(campaign);
            organisationFacebookPixelPageView(campaign);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId]);

    if (!campaign) {
        return <div>
            {campaignError}
            {!campaignError && <FullScreenProgress/>}
        </div>;
    }

    return (
        <ViewCampaign campaign={campaign} user={user} onChange={() => {
            loadCampaign(match.params.link);
        }}/>
    );
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadCampaign,
        },
        dispatch
    );

const mapStateToProps = state => ({
    campaign: state.viewCampaign.campaign,
    campaignError: state.viewCampaign.error,
    user: state.session.user
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(ViewCampaignPage);
