import gql from "graphql-tag";
import pica from "pica";
import {apolloClient} from '../graphql'

export function uploadImage(organisationId, imageFile, imageOptions) {

    return new Promise((resolve, reject) => {

        getOrientation(imageFile, (exifOrientation) => {
            const rotationDegrees = getRotationDegrees(exifOrientation);

            let reader = new FileReader();

            reader.onloadend = function () {
                let img = new Image();

                img.onload = function () {
                    rotateResizeAndUpload(img, rotationDegrees, imageOptions, organisationId, resolve, reject);
                };

                img.src = reader.result;
            };

            reader.readAsDataURL(imageFile);
        });
    });

}

function calculateHeightPreservingAspectRatio(width, img) {
    return img.height * width / img.width;
}

function getRotationDegrees(exifOrientation) {
    switch (exifOrientation) {
        case 3:
        case 4:
            return 180;
        case 5:
        case 6:
            return 90;
        case 7:
        case 8:
            return 270;
        default:
            return 0;
    }
}

function rotateResizeAndUpload(img, rotationDegrees, imageOptions, organisationId, resolve, reject) {
    if (rotationDegrees === 0) {
        return resizeAndUpload(imageOptions, img, organisationId, resolve, reject);
    }

    const rotatedCanvas = document.createElement('canvas');
    setRotationCanvasWidthAndHeight(rotationDegrees, rotatedCanvas, img);
    drawRotated(img, rotatedCanvas, rotationDegrees);
    const thePica = pica();
    thePica.toBlob(rotatedCanvas, 'image/jpeg')
        .then(blob => {
            let urlCreator = window.URL || window.webkitURL;
            let rotatedImage = new Image();

            rotatedImage.onload = function () {
                resizeAndUpload(imageOptions, rotatedImage, organisationId, resolve, reject);
            };

            rotatedImage.src = urlCreator.createObjectURL(blob);
        })
}

function setRotationCanvasWidthAndHeight(rotationDegrees, rotatedCanvas, img) {
    if (rotationDegrees === 90 || rotationDegrees === 270) {
        rotatedCanvas.width = img.height;
        rotatedCanvas.height = img.width;
    } else {
        rotatedCanvas.width = img.width;
        rotatedCanvas.height = img.height;
    }
}

function resizeAndUpload(imageOptions, img, organisationId, resolve, reject) {
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = imageOptions.width;
    resizedCanvas.height = calculateHeightPreservingAspectRatio(imageOptions.width, img);
    const thePica = pica();
    thePica.resize(img, resizedCanvas)
        .then(result => thePica.toBlob(result, 'image/jpeg', 0.9))
        .then(blob => _uploadImageBlob(organisationId, blob, resolve, reject))
        .catch(error => reject(error))
}

function drawRotated(image, canvas, degrees) {
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(degrees * Math.PI / 180);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);
    ctx.restore();
}

const CREATE_MEDIA_ENTRY_MUTATION = gql`
  mutation CreateMediaEntry($organisationId: Int!, $createMediaEntryInput: CreateMediaEntryInput!) {
    createMediaEntry(organisationId: $organisationId, createMediaEntryInput: $createMediaEntryInput) {
        id
        s3PutUrl
        s3GetUrl
    }
  }
`;

async function _uploadImageBlob(organisationId, blob, resolve, reject) {
    try {
        const createMediaEntryResponse = await apolloClient.mutate({
            mutation: CREATE_MEDIA_ENTRY_MUTATION,
            variables: {
                organisationId: organisationId,
                createMediaEntryInput: {
                    contentType: "image/png"
                }
            }
        });
        const mediaEntry = createMediaEntryResponse.data.createMediaEntry;

        await fetch(mediaEntry.s3PutUrl, {
            method: 'PUT',
            body: blob
        });

        resolve(mediaEntry);
    } catch (error) {
        reject(error);
    }
}

function getOrientation(file, callback) {
    var reader = new FileReader();
    reader.onload = function (e) {

        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) !== 0xFFD8) {
            return callback(-2);
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) {
            if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker === 0xFFE1) {
                if (view.getUint32(offset += 2, false) !== 0x45786966) {
                    return callback(-1);
                }

                var little = view.getUint16(offset += 6, false) === 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++) {
                    if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            } else if ((marker & 0xFF00) !== 0xFF00) {
                break;
            } else {
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file);
}