import ReactPixel from 'react-facebook-pixel';

function initialisePixel(campaign) {
    ReactPixel.init(campaign.organisation.facebookPixelId, null, {
        autoConfig: true,
        debug: true,
    });
}

export const organisationFacebookPixelPageView = (campaign) => {
    if (campaign.organisation.facebookPixelId) {
        initialisePixel(campaign);
        ReactPixel.pageView();
    }
};

export const facebookPixelEvent = (campaign, event, data) => {
    if (campaign.organisation.facebookPixelId) {
        initialisePixel(campaign);
        ReactPixel.trackCustom(event, data);
    }
};