import React from 'react';
import { makeStyles } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    wrapper: {
        position: 'relative',
        width: '100%'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

/**
 * @param {Object} props - props
 * @param {boolean} props.loading - if to show circular progress and disable the button
 * @param {()=>void)} props.onClick - click listener
 */
const ActionButton = ({ loading, className, onClick, children, buttonClassName, ...props }) => {
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.wrapper}>
                <Button
                    className={buttonClassName}
                    disabled={loading}
                    onClick={onClick}
                    {...props}
                >
                    {children}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
            </div>
        </div>
    );
};

export default ActionButton;
