import {
    FOLLOW_CAMPAIGN_BEGIN,
    FOLLOW_CAMPAIGN_FAILURE,
    FOLLOW_CAMPAIGN_SUCCESS,
    SET_POLICYMAKER_CALLED,
    SET_POLICYMAKER_TAGGED_ON_FACEBOOK
} from "./actions";

const initialState = {
    calledPolicymakers: new Set(),
    facebookTaggedPolicymakers: new Set(),
    loading: false,
    error: null,
};

export default function advocacyActionsReducer(state = initialState, action) {
    switch (action.type) {
        case FOLLOW_CAMPAIGN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FOLLOW_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case FOLLOW_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case SET_POLICYMAKER_CALLED:
            return {
                ...state,
                calledPolicymakers: new Set([...state.calledPolicymakers, action.payload.policymakerId])
            };

        case SET_POLICYMAKER_TAGGED_ON_FACEBOOK:
            return {
                ...state,
                facebookTaggedPolicymakers: new Set([...state.facebookTaggedPolicymakers, action.payload.policymakerId])
            };
        default:
            return state;
    }
}
