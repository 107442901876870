import {apolloClient} from '../../graphql'
import gql from "graphql-tag";
import {getDomain} from "../../constants/domains";

const PUBLIC_LOAD_CAMPAIGN_QUERY = gql`
  query GetPublicCampaign($domain: String, $link: String!) {
    publicCampaign(domain: $domain, link: $link) {
        id
        organisationNameOverride
        logoMediaId
        logoImageUrl
        status
        title
        description
        banner
        quoteEnabled
        quote
        quoteName
        quoteTitle
        quoteMediaId
        quoteImageUrl
        targetLegislators
        targetIndividualPolicymakers {
          id
          organisationId
          name
          greetingName
          role
          email
          phone
          facebook
          twitter
          imageUrl
          deleted
        }     
        donationsConfig {
            donationsEnabled
            donationsTitle
            donationsDescription        
            donationAmounts
            allowAnyAmount        
        }
        actionTypes
        bannerMediaId
        bannerImageUrl
        bannerEnabled
        banner
        fixedBanner
        link
        shareText
        actionsCountEnabled
        donationsAmountEnabled
        actionsCountStart
        donationsAmountStart
        template
        allowEditActions
        requestPhoneNumbers
        requestPhoneText
        showThankYouMessage
        thankYouMessage
        emailIntro
        addTwitterHashtags
        sponsorsEnabled
        sponsorsHeadingText
        sponsors {
            name
            logoImageUrl
            url
        }
        statePolicymakersEnabled
        federalPolicymakersEnabled
        states
        advocacyActions {
            actionType
            subject
            topic
            content
        }
        organisation {
            id
            name
            logoImageUrl
            whiteLabelLogoImageUrl
            googleAnalyticsId
            facebookPixelId
            verified
            allowSocialSignup
        }
        analytics {
            publicActionCount {
                doneCount
            }
            donations {
                publicCampaignTotals {
                    totalAmount
                }
                publicPayments(page: 0, size: 5, sort: "created,desc") {
                    metadata {
                        size
                        totalElements
                        totalPages
                        number
                    }
                    content {
                        name
                        amount
                        currency,
                        created
                    }
                }
            }
        }
        donationsConfig {
            donationsEnabled
            donationsTitle
            donationsDescription
            donationAmounts
            allowAnyAmount
        }
        shareFacebookEnabled
        shareTwitterEnabled
        shareLinkedinEnabled
        shareParlerEnabled
        shareEmailEnabled
        shareGabEnabled
        shareTelegramEnabled
        shareSignalEnabled
        shareCopyEnabled
    }
  }
`;

export function loadCampaign(link) {
    return async dispatch => {
        dispatch(loadCampaignBegin());
        try {
            const loadCampaignResponse = await apolloClient.query({
                query: PUBLIC_LOAD_CAMPAIGN_QUERY,
                variables: {
                    domain: getDomain(),
                    link,
                },
                fetchPolicy: "network-only",
            });
            const campaign = loadCampaignResponse.data.publicCampaign;
            if (campaign == null) {
                dispatch(loadCampaignFailure("Not found or not verified"));
            } else {
                dispatch(loadCampaignSuccess(campaign));
            }
        } catch (error) {
            dispatch(loadCampaignFailure(error));
        }
    };
}

export const PUBLIC_LOAD_CAMPAIGN_BEGIN = "PUBLIC_LOAD_CAMPAIGN_BEGIN";
export const PUBLIC_LOAD_CAMPAIGN_SUCCESS = "PUBLIC_LOAD_CAMPAIGN_SUCCESS";
export const PUBLIC_LOAD_CAMPAIGN_FAILURE = "PUBLIC_LOAD_CAMPAIGN_FAILURE";

export const loadCampaignBegin = () => ({
    type: PUBLIC_LOAD_CAMPAIGN_BEGIN
});

export const loadCampaignSuccess = (campaign) => ({
    type: PUBLIC_LOAD_CAMPAIGN_SUCCESS,
    payload: {campaign}
});

export const loadCampaignFailure = error => ({
    type: PUBLIC_LOAD_CAMPAIGN_FAILURE,
    payload: {error}
});
