import {apolloClient} from '../../graphql'
import gql from "graphql-tag";

const PREVIEW_CAMPAIGN_QUERY = gql`
  query GetCampaign($organisationId: Int!, $campaignId: Int!) {
    campaign(organisationId: $organisationId, campaignId: $campaignId) {
        id
        organisationNameOverride
        logoMediaId
        logoImageUrl
        status
        title
        description
        quoteEnabled
        quote
        quoteName
        quoteTitle
        quoteMediaId
        quoteImageUrl
        statePolicymakersEnabled
        federalPolicymakersEnabled
        states
        targetLegislators
        targetIndividualPolicymakers {
          organisationId
          id
          name
          greetingName
          role
          email
          phone
          facebook
          twitter
          imageUrl
          deleted
        }
        actionTypes
        bannerMediaId
        bannerImageUrl
        bannerEnabled
        banner
        fixedBanner
        link
        shareText
        actionsCountEnabled
        donationsAmountEnabled
        actionsCountStart
        donationsAmountStart
        template
        allowEditActions
        requestPhoneNumbers
        requestPhoneText
        showThankYouMessage
        thankYouMessage
        emailIntro
        addTwitterHashtags
        sponsorsEnabled
        sponsorsHeadingText
        sponsors {
            name
            logoImageUrl
            url
        }
        advocacyActions {
            actionType
            subject
            topic
            content
        }
        organisation {
            id
            name
            logoImageUrl
        }
        donationsConfig {
            donationsEnabled
            donationsTitle
            donationsDescription
            donationAmounts
            allowAnyAmount
        }
        analytics {
            publicActionCount {
                doneCount
            }
            donations {
                publicCampaignTotals {
                    totalAmount
                }
            }
        }
    }
  }
`;

export function loadCampaign(organisationId, campaignId) {
    return async dispatch => {
        dispatch(loadCampaignBegin());
        try {
            const loadCampaignResponse = await apolloClient.query({
                query: PREVIEW_CAMPAIGN_QUERY,
                variables: {
                    organisationId,
                    campaignId
                }
            });
            const campaign = loadCampaignResponse.data.campaign;
            dispatch(loadCampaignSuccess(campaign));
        } catch (error) {
            dispatch(loadCampaignFailure(error));
        }
    };
}

export const LOAD_PREVIEW_CAMPAIGN_BEGIN = "LOAD_PREVIEW_CAMPAIGN_BEGIN";
export const LOAD_PREVIEW_CAMPAIGN_SUCCESS = "LOAD_PREVIEW_CAMPAIGN_SUCCESS";
export const LOAD_PREVIEW_CAMPAIGN_FAILURE = "LOAD_PREVIEW_CAMPAIGN_FAILURE";

export const loadCampaignBegin = () => ({
    type: LOAD_PREVIEW_CAMPAIGN_BEGIN
});

export const loadCampaignSuccess = (campaign) => ({
    type: LOAD_PREVIEW_CAMPAIGN_SUCCESS,
    payload: {campaign}
});

export const loadCampaignFailure = error => ({
    type: LOAD_PREVIEW_CAMPAIGN_FAILURE,
    payload: {error}
});