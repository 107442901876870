import React, {useState} from 'react';
import {withStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import {CardElement, Elements, injectStripe} from 'react-stripe-elements';
import gql from "graphql-tag";
import classNames from "classnames";
import {apolloClient} from '../../../graphql'
import StripeDynamicProvider from '../stripeDynamicProvider';
import Snackbar from "@material-ui/core/Snackbar";
import ActionButton from '../../Common/ActionButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import poweredByStripe from '../../../images/powered_by_stripe.png';

import './index.css';
import {gaEvent} from "../../../analytics/googleAnalytics";
import {facebookPixelEvent} from "../../../analytics/facebookPixel";
import {isDE} from "../../../constants/domains";

const styles = () => ({
    root: {
        marginLeft: 24,
        marginRight: 24,
        maxWidth: 600
    },
    width500: {
        width: "100%",
        maxWidth: 500
    },
    hideNameLabel: {
        textAlign: 'left'
    },
    textAlignLeft: {
        textAlign: 'left'
    },
    textAlignRight: {
        textAlign: 'right'
    },
    divider: {
        width: "100%"
    },
    stripe: {
        width: 150,
        height: 41
    }
});

const FAILED_PAYMENT_MESSAGE = 'Failed to process your payment.';

const CREATE_STRIPE_PAYMENT = gql`
  mutation CreateStripePayment($createStripePaymentInput: StripePayment!) {
    createStripePayment(createStripePaymentInput: $createStripePaymentInput){
        transactionId,
        status,
        failureCode,
        failureReason
    }
  }
`;

const _CardDonateForm = ({classes, stripe, campaign, user, amount, className, handleClose}) => {
    const [cardHolderName, setCardHolderName] = useState(null);
    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [occupation, setOccupation] = useState(null);
    const [employer, setEmployer] = useState(null);
    const [zip, setZip] = useState(null);

    const [error, setError] = useState(null);
    const [anonymous, setAnonymous] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);

    const handleSubmit = async (ev) => {
        setProcessingPayment(true);
        try {
            ev.preventDefault();
            if (!stripe) {
                return;
            }

            if (!cardHolderName) {
                setCardHolderName("");
                setError("Please provide the name printed on your credit card");
                return;
            }
            if (!address) {
                setAddress("");
                setError("Please provide your street address");
                return;
            }
            if (!city) {
                setCity("");
                setError("Please provide your city");
                return;
            }
            if (!state) {
                setState("");
                setError("Please provide your state");
                return;
            }
            if (isDE && !employer) {
                setEmployer("");
                setError("Please provide your employer");
                return;
            }
            if (isDE && !occupation) {
                setOccupation("");
                setError("Please provide your occupation");
                return;
            }

            const additionalData = {
                name: cardHolderName || void 0,
                address_line1: address || void 0,
                address_city: city || void 0,
                address_state: state || void 0
            };

            const createTokenResult = await stripe.createToken(additionalData);
            if (createTokenResult.error) {
                console.log(createTokenResult.error);
                setError(createTokenResult.error.message || FAILED_PAYMENT_MESSAGE)
                return;
            }
            const token = createTokenResult.token.id;

            let paymentSucceeded = false;

            try {
                const createStripeConnectResponse = await apolloClient.mutate({
                    mutation: CREATE_STRIPE_PAYMENT,
                    variables: {
                        createStripePaymentInput: {
                            organisationId: campaign.organisation.id,
                            campaignId: campaign.id,
                            userId: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            phone: user.phone,
                            cardHolderName: cardHolderName,
                            street: address,
                            city: city,
                            state: state,
                            zip: zip,
                            employer: employer,
                            occupation: occupation,
                            anonymous: anonymous,
                            currency: 'usd',
                            amount: amount,
                            source: token,
                        }
                    }
                });
                if (createStripeConnectResponse.failureReason) {
                    setError(createStripeConnectResponse.failureReason);
                    return;
                }

                paymentSucceeded = true;

                gaEvent(campaign, {
                    category: 'Contributions',
                    action: 'Contribute',
                    label: window.location.pathname,
                    values: amount
                });

                facebookPixelEvent(campaign, 'Contributions', {
                    action: 'Contribute',
                    label: window.location.pathname,
                    values: amount
                });
            } catch (error) {
                console.log(error);
                setError(FAILED_PAYMENT_MESSAGE);
                return;
            }

            handleClose(paymentSucceeded);
        } catch (error) {
            console.log(error);
            setError(FAILED_PAYMENT_MESSAGE);
        } finally {
            setProcessingPayment(false);
        }
    };

    const textFieldDefaultProps = {
        margin: "dense",
        variant: "outlined",
        className: classes.width500
    };

    return (
        <form className={classNames(classes.root, className)} onSubmit={handleSubmit}>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Cardholder name"
                        placeholder="Jane"
                        autoComplete="name"
                        value={cardHolderName}
                        error={cardHolderName === ""}
                        onChange={(e) => {
                            setCardHolderName(e.target.value)
                        }}
                        {...textFieldDefaultProps}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Street Address"
                        placeholder="185 Berry St"
                        autoComplete="address-line1"
                        value={address}
                        error={address === ""}
                        onChange={(e) => {
                            setAddress(e.target.value)
                        }}
                        {...textFieldDefaultProps}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="City"
                        placeholder="San Francisco"
                        autoComplete="address-level2"
                        value={city}
                        error={city === ""}
                        onChange={(e) => {
                            setCity(e.target.value)
                        }}
                        {...textFieldDefaultProps}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="State"
                        placeholder="CA"
                        autoComplete="address-level1"
                        value={state}
                        error={state === ""}
                        onChange={(e) => {
                            setState(e.target.value)
                        }}
                        {...textFieldDefaultProps}
                    />
                </Grid>
                {isDE && <>
                    <Grid item xs={12} className={classes.textAlignLeft}>
                        <Typography variant="caption">
                            Law requires we ask for your employer and occupation. If you are unemployed or are retired,
                            put N/A as your employer and either "unemployed" or "retired" as your occupation, and if you
                            are self-employed put "self-employed" in employer and describe your occupation.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.textAlignLeft}>
                        <Typography variant="caption">
                            To comply with Federal law, we must use best efforts to obtain, maintain, and submit the
                            name, mailing address, occupation, and name of employer of individuals whose contributions
                            exceed $200 for the election cycle. By providing this information, you will help us meet our
                            record keeping and reporting responsibilities, and we won’t have to follow-up to gather any
                            missing information.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Occupation"
                            value={occupation}
                            error={occupation === ""}
                            onChange={(e) => {
                                setOccupation(e.target.value)
                            }}
                            {...textFieldDefaultProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Employer"
                            value={employer}
                            error={employer === ""}
                            onChange={(e) => {
                                setEmployer(e.target.value)
                            }}
                            {...textFieldDefaultProps}
                        />
                    </Grid>
                </>}
                <Grid item xs={12}>
                    <CardElement onChange={e => setZip(e.value.postalCode)}/>
                </Grid>
                <Grid container item xs={12}>
                    <Divider className={classes.divider}/>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <Typography className={classes.textAlignLeft} variant="h6">Total</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textAlignRight} variant="h6">{`$${amount}`}</Typography>
                    </Grid>
                </Grid>
                <Grid item className={classes.textAlignLeft}>
                    <Typography variant="caption">The campaign will receive this
                        amount, minus processing fees.</Typography>
                </Grid>
                {isDE && <>
                    <Grid item className={classes.textAlignLeft}>
                        <Typography variant="caption">
                            Contributions are not deductible as charitable contributions for federal income tax
                            purposes.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.textAlignLeft}>
                        <Typography variant="caption">
                            Individuals may contribute up to $2,800 per election (up to $2,800 for the primary, plus up
                            to $2,800 for the general election). We ask that spouses contribute separately in order to
                            help us keep proper records.
                        </Typography>
                    </Grid>
                </>}
                <Grid container item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={anonymous} color={'primary'}/>}
                        classes={{label: classes.hideNameLabel}}
                        onChange={() => setAnonymous(!anonymous)}
                        label="Click here if you don’t want your name publicly displayed with your donation."
                    />
                </Grid>

                <Grid container item xs={12}>
                    <ActionButton variant="contained" loading={processingPayment} type="submit"
                                  buttonClassName={classes.width500} className={classes.width500} size="large"
                                  color="primary">DONATE NOW</ActionButton>
                </Grid>

                <Grid container item>
                    <img className={classes.stripe} src={poweredByStripe} alt="Powered by Stripe"/>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={!!error}
                onClose={() => setError(null)}
                autoHideDuration={6000}
                message={error}
            />
        </form>
    );
};

const CardDonateForm = injectStripe(withStyles(styles)(_CardDonateForm));

const Donate = ({className, amount, user, campaign, handleClose}) => {
    return <StripeDynamicProvider>
        <Elements>
            <CardDonateForm className={className} amount={amount} user={user} campaign={campaign}
                            handleClose={handleClose}/>
        </Elements>
    </StripeDynamicProvider>
}

export default Donate;