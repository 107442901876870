import useMediaQuery from "@material-ui/core/useMediaQuery";
import {createMuiTheme} from "@material-ui/core/styles";

let fontFamily ="'Montserrat', sans-serif";

export default () => {
// For the case when Android keyboard on mobile browser pushes layout up
    const superSmallScreen = useMediaQuery('(max-height:300px)')
    const smallScreen = useMediaQuery('(max-height:500px)');

    const theme = createMuiTheme({
        props: {
            MuiTypography: {
                variantMapping: {
                    subtitle1: 'span'
                },
            },
            MuiButtonBase: {
                // The properties to apply
                disableRipple: true // No more ripple, on the whole application!
            },
        },
        marginFromPageTop: superSmallScreen ? '5px' : (smallScreen ? '15px' : '30px'),
        marginFromPageLeft: '15px',
        marginFromPageRight: '15px',
        isSmallScreen: smallScreen,
        ActionsProgressBar: {
            customColors : {
                purplish: '#444',
                greyish: '#444',
            }
        },
        VividTemplate: {
            donationsContainer: {
                background: 'rgba(85,85,85,0.85)'
            },
            campaignContainer: {
                background: 'rgba(68, 68, 68, 0.85)'
            },
            joinButton: {
                background: '#e92200',
                backgroundHover: '#ba1602'
            }
        },
        palette: {
            background: {
                paper: '#fff',
                default: '#fff',
                appBar: '#444',
                banner: '#444',
                counter: '#444',
                templateOverlay: 'rgba(68,68,68,0.95)'
            },
            primary: {
                main:  '#e92200',
                dark: '#ba1602'
            },
            secondary: {
                main: '#009688'
            },
        },
        typography: {
            fontFamily: fontFamily
        },
        tip: {
            width: '100%',
            backgroundColor: '#EEEEEE',
            padding: 20,
            marginBottom: '10px',
            display: smallScreen ? 'none' : 'block',
        },
        overrides: {
            MuiInputBase: {
                input: {
                    fontFamily: fontFamily,
                    fontWeight: 400,
                    fontSize: '14px',
                    '&::placeholder': {
                        fontSize: '14px',
                        fontWeight: 400
                    },
                    '&$disabled': {
                        backgroundColor: '#e4e4e4'
                    }
                },
            },
            MuiTab: {
                wrapper: {
                    textTransform: 'none'
                },
                root: {
                    minHeight: '0',
                }
            },
            MuiList: {
                padding: {
                    paddingTop: 0
                }
            },
            MuiTabs: {
                root: {
                    paddingTop: '10px',
                    paddingBottom: '0px'
                },
                scroller: {
                    paddingBottom: '8px'
                }
            },
            PrivateTabIndicator: {
                colorSecondary: {
                    backgroundColor: '#e92200'
                }
            },
            MuiButton: {
                text: {
                    fontFamily: fontFamily,
                },
                label: {
                    textTransform: 'none',
                    fontWeight: 500,
                },
                contained: {
                    boxShadow: 'none'
                }
            },
            MuiDivider: {
                root: {
                    height: '1.1px'
                }
            },
            MuiTypography: {
                h3: {
                    fontSize: '30px',
                    fontWeight: 600
                },
                h4: {
                    fontSize: '28px',
                    fontWeight: 600
                },
                h5: {
                    fontSize: '20px',
                    fontWeight: 600
                },
                h6: {
                    fontSize: '17px',
                    fontWeight: 600
                },
                subtitle1: {
                    fontSize: '15px',
                    fontWeight: 800
                },
                caption: {
                    fontSize: '14px',
                    fontWeight: 300,
                },
                body2: {
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#979797'
                }
            }
        }
    })
    return [theme]
}
