import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import store, {history} from './store'
import {ConnectedRouter} from 'connected-react-router'
import './fonts/lineto-circular-pro-book.woff'
import './index.css';
import * as serviceWorker from './serviceWorker';
import './config/i18n';

import App from './components/App';

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
