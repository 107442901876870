// Reference: https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios/46981847#46981847
export const copyToClipboard = (text) => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();

    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        // save current contentEditable/readOnly status
        const oldContentEditable = el.contentEditable;
        const oldReadOnly = el.readOnly;

        // convert to editable with readonly to stop iOS keyboard opening
        el.contentEditable = true;
        el.readOnly = false;

        // create a selectable range
        const range = document.createRange();
        range.selectNodeContents(el);

        // select the range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        el.setSelectionRange(0, 999999);

        // restore contentEditable/readOnly to original state
        el.contentEditable = oldContentEditable;
        el.readOnly = oldReadOnly;
    }

    document.execCommand('copy');
    document.body.removeChild(el);
};