import firebase from "firebase/app";
import { auth } from './firebase';
import { apolloClient } from '../graphql';
import * as routes from "../constants/routes";
import gql from 'graphql-tag';
import { DOMAIN_AA, getDomain, prependDomainToAuthToken } from "../constants/domains";

const LOGIN_MUTATION = gql`
  mutation Login($userId: String!) {
    login(userId: $userId) {
        user {
          id
          domain
          firstName
          lastName
          email
          jwt
          organisations {
            id
            name
            googleAnalyticsId
            facebookPixelId
            verified
            verificationSubmitted
            allowSocialSignup
          }
          address {
            number
            street
            city
            state
            country
            zip
          }
          legislators {
            type
            divisionId
            bioId
            name
            greetingName
            party
            phone
            email
            url
            photoUrl
            socialMediaHandles {
              socialMediaType
              handle
            }
          }
        }
    }
  }
`;


export const doSignInWithEmail = async (email) => {
  const authResult = await auth.signInWithEmailLink(email, window.location.href);
  const idToken = await authResult.user.getIdToken();
  const loginResponse = await apolloClient.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      userId: authResult.user.uid
    },
    context: {
      headers: {
        Authorization: `Bearer ${prependDomainToAuthToken(idToken)}`
      }
    }
  });
  let user = loginResponse.data.login.user;
  const token = user.jwt;
  await auth.signInWithCustomToken(token);
  return user;
};

export const doSignIn = async (token) => {
  const userCredential = await auth.signInWithCustomToken(token);
  return userCredential.user.email;
};

export const doSignInWithEmailAndPassword = async (email, password) => {
  const authResult = await auth.signInWithEmailAndPassword(email, scrubBlankPassword(password));
  const idToken = await authResult.user.getIdToken();
  const loginResponse = await apolloClient.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      userId: authResult.user.uid
    },
    context: {
      headers: {
        Authorization: `Bearer ${prependDomainToAuthToken(idToken)}`
      }
    }
  });
  let user = loginResponse.data.login.user;
  const token = user.jwt;
  await auth.signInWithCustomToken(token);
  return user;
};

export const doSignOut = (history) => {
  if (history) {
    history.push(routes.ROOT);
  }
  auth.signOut();
};

export const scrubBlankPassword = (password) => {
  return password ? password : "DEFAULT"
};

const SEND_EMAIL_TO_LOGIN = gql`
  mutation SendEmailToLogin($email: String!, $link: String!) {
    sendEmailToLogin(email: $email, link: $link)
  }
`

const REGISTER_MUTATION = gql`
  mutation Register($hash: String!, $registrationInput: RegistrationInput!) {
    register(hash: $hash, registrationInput: $registrationInput) {
      user {
          id
          domain
          firstName
          lastName
          email
          jwt
      }
    }
  }
`;

const REGISTER_MUTATION_TAKE_ACTION = gql`
  mutation RegisterTakeAction($hash: String!, $registrationInput: RegistrationTakeActionInput!) {
    registerTakeAction(hash: $hash, registrationTakeActionInput: $registrationInput) {
      user {
          id
          domain
          firstName
          lastName
          email
          jwt
      }
    }
  }
`;

export const doSendEmailToLogin = async (email, link) => {
  await apolloClient.mutate({
    mutation: SEND_EMAIL_TO_LOGIN,
    variables: {
      email,
      link
    }
  })
}


export const doCreateUserToTakeAction = async (hash, namePrefix, firstName, lastName, email, phone, address) => {
  const registrationInput = {
    namePrefix,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone,
    address,
    domain: DOMAIN_AA
  };
  const registrationResponse = await apolloClient.mutate({
    mutation: REGISTER_MUTATION_TAKE_ACTION,
    variables: {
      hash,
      registrationInput
    }
  });
  let user = registrationResponse.data.registerTakeAction.user;
  const token = user.jwt;

  await auth.signInWithCustomToken(token); // TODO likely no need to do separate request
  return user;
};

export const doCreateUser = async (hash, firstName, lastName, email, password) => {
  const registrationInput = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password,
    domain: getDomain()
  };
  const registrationResponse = await apolloClient.mutate({
    mutation: REGISTER_MUTATION,
    variables: {
      hash,
      registrationInput
    }
  });
  let user = registrationResponse.data.register.user;
  const token = user.jwt;

  await auth.signInWithCustomToken(token); // TODO likely no need to do separate request
  return user;
};

/**
 * @param {firebase.auth.FacebookAuthProvider | firebase.auth.GoogleAuthProvider} provider
 */
const signInWithProvider = async (provider) => {
  await auth.signInWithRedirect(provider);
};

const continueSignInWithProvider = async (authMutation) => {
  const authResult = await auth.getRedirectResult();
  if (!authResult || !authResult.user) {
    return null;
  }
  const userId = authResult.user.uid;
  const idToken = await authResult.user.getIdToken();
  const splitName = authResult.user.displayName.split(" ");
  const firstName = splitName[0];
  const lastName = splitName.length > 1 ? splitName[splitName.length - 1] : "";
  const email = authResult.user.email;

  const authInput = {
    id: authResult.user.uid,
    firstName: firstName,
    lastName: lastName,
    email: email,
    domain: getDomain()
  };

  const user = await authMutation(authInput, userId, idToken);

  await auth.signInWithCustomToken(user.jwt);

  return user;
};

const FACEBOOK_AUTH_MUTATION = gql`
  mutation FacebookAuth($userId: String!, $facebookAuthInput: FacebookAuthInput!) {
    facebookAuth(userId: $userId, facebookAuthInput: $facebookAuthInput) {
        user {
          id
          domain
          firstName
          lastName
          email
          jwt
          organisations {
            id
            name
            googleAnalyticsId
            facebookPixelId
            verified
            verificationSubmitted
            allowSocialSignup
          }
          address {
            number
            street
            city
            state
            country
            zip
          }
          legislators {
            type
            divisionId
            bioId
            name
            greetingName
            party
            phone
            email
            url
            photoUrl
            socialMediaHandles {
              socialMediaType
              handle
            }
          }
        }
        errors {
          code
          message
        }
    }
  }
`;

export const signInWithFacebook = async () => {
  let provider = new firebase.auth.FacebookAuthProvider();
  return await signInWithProvider(provider);
};

export const continueSignInWithFacebook = async () => {
  return await continueSignInWithProvider(async (authInput, userId, idToken) => {
    if (!authInput.email) {
      throw Error("We didn't receive your email from Facebook. Please sign in with Google or an email address")
    }

    const facebookAuthResponse = await apolloClient.mutate({
      mutation: FACEBOOK_AUTH_MUTATION,
      variables: { userId, facebookAuthInput: authInput },
      context: {
        headers: {
          Authorization: `Bearer ${prependDomainToAuthToken(idToken)}`
        }
      }
    });

    const response = facebookAuthResponse.data.facebookAuth;
    if (response.errors) {
      throw response.errors[0]
    }
    return response.user;
  });
};

const GOOGLE_AUTH_MUTATION = gql`
  mutation GoogleAuth($userId: String!, $googleAuthInput: GoogleAuthInput!) {
    googleAuth(userId: $userId, googleAuthInput: $googleAuthInput) {
        user {
          id
          domain
          firstName
          lastName
          email
          jwt
          organisations {
            id
            name
            googleAnalyticsId
            facebookPixelId
            verified
            verificationSubmitted
            allowSocialSignup
          }
          address {
            number
            street
            city
            state
            country
            zip
          }
          legislators {
            type
            divisionId
            bioId
            name
            greetingName
            party
            phone
            email
            url
            photoUrl
            socialMediaHandles {
              socialMediaType
              handle
            }
          }
        }
        errors {
          code
          message
        }
    }
  }
`;

export const signInWithGoogle = async () => {
  let provider = new firebase.auth.GoogleAuthProvider();
  return await signInWithProvider(provider);
};

export const continueSignInWithGoogle = async () => {
  return await continueSignInWithProvider(async (authInput, userId, idToken) => {
    const googleAuthResponse = await apolloClient.mutate({
      mutation: GOOGLE_AUTH_MUTATION,
      variables: { userId, googleAuthInput: authInput },
      context: {
        headers: {
          Authorization: `Bearer ${prependDomainToAuthToken(idToken)}`
        }
      }
    });

    const response = googleAuthResponse.data.googleAuth;
    if (response.errors) {
      throw response.errors[0]
    }
    return response.user;
  });
};

export const doPasswordReset = (email, redirectAfterSigninUrl) => {
  const callbackUrl = redirectAfterSigninUrl ? `${window.location.origin}${redirectAfterSigninUrl}` : `${window.location.origin}/home`;
  const actionCodeSettings = {
    url: callbackUrl,
    handleCodeInApp: false
  };

  return auth.sendPasswordResetEmail(email, actionCodeSettings);
};

const GET_USER_REGISTRATION_STATUS_QUERY = gql`
  query getUserRegistrationStatus($domain: String, $email: String!) {
    userRegistrationStatus(domain: $domain, email: $email) {
      registered,
      verificationTokenSent,
      hash
      error {
        code
        message
      }
    }
  }
`;

export const getUserRegistrationStatus = async (email) => {
  const response = await apolloClient.query({
    query: GET_USER_REGISTRATION_STATUS_QUERY,
    variables: {
      domain: getDomain(),
      email
    }
  });
  return response.data.userRegistrationStatus;
};

const CREATE_USER_REGISTRATION_MUTATION = gql`
    mutation CreateUserRegistration($domain: String, $email: String!) {
        createUserRegistration(domain: $domain, email: $email) {
            hash,
            email
        }
    }
`;

export const createUserRegistration = async (email) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_USER_REGISTRATION_MUTATION,
    variables: {
      domain: getDomain(),
      email
    }
  });
  if (!response.data.createUserRegistration) {
    throw Error("Could not create user registration.");
  }
  return response.data.createUserRegistration;
};


const VERIFY_MUTATION = gql`
    mutation VerifyRegistration($hash: String!, $token: String!) {
      verifyRegistration(hash: $hash, token: $token)
    }
`;

export const verifyRegistration = async (hash, token) => {
  const response = await apolloClient.mutate({
    mutation: VERIFY_MUTATION,
    variables: { hash, token }
  });
  return response.data.verifyRegistration;
};

const RESEND_VERIFICATION_EMAIL = gql`
    mutation ResendVerificationEmail($hash: String!) {
      resendVerificationEmail(hash: $hash)
    }
`;

export const resendVerificationEmail = async (hash) => {
  const response = await apolloClient.mutate({
    mutation: RESEND_VERIFICATION_EMAIL,
    variables: { hash }
  });
  return response.data.resendVerificationEmail;
};
