import 'intersection-observer';
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IntersectionVisible from 'react-intersection-visible';
import {
    CopyButton,
    EmailButton,
    FacebookButton,
    GabButton,
    LinkedinButton,
    ParlerButton, SignalButton, TelegramButton,
    TwitterButton
} from "./shareButtons";
import {ActionsSection} from "./common";
import Link from "@material-ui/core/Link";
import images from "../../images/domainSpecificImages"
import {isDonationsEnabled} from './donations/utils';
import FreeRootsLogo from "../Landing/freeRootsLogo";
import * as ROUTES from "../../constants/routes";
import DonationSection from "./donations/donationsSection";
import classNames from 'classnames';
import verifiedbadge from '../../images/verifiedbadge.svg'
import {useTranslation} from "react-i18next";
import LinkifyExternal from "../Common/Linkify";

const colors = {
    greyish: '#a2a2a2',
    darkGreyish: '#595c63',
};

const useStyles = makeStyles(theme => ({
    separator: {
        width: "100%",
        height: 1,
        background: "#000000",
    },
    container: {
        margin: '0 auto',
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    content: {
        maxWidth: 800
    },
    indented: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.up('sm')]: {
            height: "500px",
            padding: "0 22px",
        },
        [theme.breakpoints.down('xs')]: {
            height: "auto",
            padding: "20px 22px",
        },
    },
    joinButton: {
        width: 230
    },
    joinButtonLarge: {
        fontSize: 18
    },
    imageContainer: {
        position: "relative",
        [theme.breakpoints.up('sm')]: {
            height: "500px",
        },
        [theme.breakpoints.down('xs')]: {
            height: "350px",
        },
    },
    image: {
        position: "absolute",
        width: '100%',
        objectFit: 'cover',
        height: "100%",
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '4rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '3rem',
        },
        fontWeight: 600,
        letterSpacing: '0rem',
        marginTop: '0.5rem',
        marginBottom: '1rem',
    },
    organisation: {
        maxWidth: 150,
    },
    organisationSection: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
    },
    organisationLogo: {
        width: 80,
        height: 80,
        marginRight: 10,
        objectFit: 'cover',
        borderRadius: "50%"
    },
    arrowForward: {
        fontSize: "1.25rem",
        marginLeft: '0.5rem',
    },
    actionsTakenBox: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        right: "40px",
        bottom: 0,
        marginBottom: "-25px",
        background: theme.palette.background.counter,
        borderRadius: "50%",
        [theme.breakpoints.up('sm')]: {
            width: "200px",
            height: "200px",
            fontSize: ""
        },
        [theme.breakpoints.down('xs')]: {
            width: "165px",
            height: "165px",
        },
    },
    actionsTakenNumber: {
        fontWeight: 600,
        color: "#ffffff",
        display: 'inline',
        [theme.breakpoints.up('sm')]: {
            fontSize: "2.5rem",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "2rem",
        },
    },
    actionsTakenLabel: {
        fontWeight: 600,
        color: "#ffffff",
        display: 'inline',
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.25rem",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "1rem",
        },
    },
    description: {
        maxWidth: 400,
        margin: "50px auto",
        whiteSpace: "pre-line",
        [theme.breakpoints.up('sm')]: {
            marginLeft: '20px',
            padding: "inherit",
            marginBottom: 50,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: "0",
            padding: "0 22px",
            marginBottom: 0,
        },
    },
    quoteWrapper: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            marginTop: 70,
            marginBottom: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 30,
            marginBottom: 30,
        },
    },
    quotePersonWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '1rem',
        paddingLeft: '1rem',
        borderLeft: '1px solid #000000',
    },
    quote: {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: 1.1,
        paddingLeft: '1rem',
        borderLeft: '1px solid #000000',
    },
    quoteImage: {
        width: 60,
        height: 60,
        objectFit: 'cover',
        marginRight: '0.25rem',
        borderRadius: '50%',
    },
    quoteNameWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    quoteName: {
        color: colors.darkGreyish,
    },
    shareBox: {
        margin: '30px 0',
        [theme.breakpoints.up('sm')]: {
            padding: 0,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 0px',
        },
    },
    shareTitle: {
        fontSize: '2.25rem',
        fontWeight: 600,
        marginBottom: '1rem',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '20px',
        },
    },
    sponsorsTitle: {
        fontSize: '1.75rem',
        fontWeight: 600,
        marginBottom: '1rem',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '20px',
        },
    },
    img: {
        top: 0,
        position: "absolute",
        width: '100%',
        objectFit: 'cover',
        height: "100%",
    },
    sponsorsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap"
    },
    sponsorLogoAndTitle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 120,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    logoImageContainer: {
        position: "relative",
        width: 100,
        height: 100,
        marginBottom: theme.spacing(1)
    },
    circular: {
        borderRadius: "50%"
    },
    shareIcon: {
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            marginBottom: 10,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 22,
        },
    },
    empoweredBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        [theme.breakpoints.up('sm')]: {
            marginLeft: 20,
            marginBottom: 20,
            padding: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: "0",
            marginBottom: 70,
            padding: '0 22px',
        },
    },
    spacer: {
        [theme.breakpoints.up('sm')]: {
            width: 181
        },
        [theme.breakpoints.down('xs')]: {
            width: 34
        },
    },
    empoweredBy: {
        display: 'inline',
        fontSize: '1rem',
        fontWeight: 400,
    },
    freeRoots: {
        display: 'inline',
        fontSize: '1rem',
        fontWeight: 500,
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
    fixedJoinButtonBar: {
        position: 'fixed',
        maxWidth: '1080px',
        margin: '0 auto',
        width: '100%',
        height: 50,
        boxShadow: '0 6px 4px -5px rgba(0,0,0,0.5)',
        background: '#ffffff',
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            top: 0,
            bottom: 'auto',
            boxShadow: '0 5px 4px -5px rgba(0,0,0,0.5)',
        },
        [theme.breakpoints.down('xs')]: {
            top: 'auto',
            bottom: 0,
            boxShadow: '0 -5px 4px -5px rgba(0,0,0,0.5)',
        },
    },
    fixedJoinButtonBarFreeRoots: {
        margin: '0 30px',
        fontWeight: 500,
        fontSize: '1.25rem',
    },
    fixedJoinButtonBarTitle: {
        fontWeight: 500
    },
    fixedJoinButtonBarButtonWrapper: {
        display: 'flex',
        flexGrow: 1,
        marginRight: '5px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'initial',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            justifyContent: 'center',
        },
    },
    verifiedLogo: {
        marginLeft: theme.spacing(4),
        marginRight: 5
    },
    verifiedText: {
        color: "#1AE3FF"
    }
}));

const SimpleTemplate = ({campaign, handleJoinCampaign, donationProps}) => {
    const classes = useStyles();
    const [isJoinButtonVisible, setJoinButtonVisible] = useState(true);
    const isDonationsVisible = isDonationsEnabled(campaign);

    return (
        <Box className={classes.container}>
            <FixedJoinButtonBar
                campaign={campaign}
                isVisible={!isJoinButtonVisible}
                handleJoinCampaign={handleJoinCampaign}
                classes={classes}
            />
            <CampaignSection
                campaign={campaign}
                handleJoinCampaign={handleJoinCampaign}
                setJoinButtonVisible={setJoinButtonVisible}
                classes={classes}
            />
            <Separator size="large" classes={classes}/>
            <div className={classes.content}>
                <DescriptionAndQuoteSection campaign={campaign} classes={classes}/>
                <Separator classes={classes}/>
                {isDonationsVisible && <div className={classes.indented}>
                    <DonationSection
                        campaign={campaign}
                        classes={classes}
                        donationProps={donationProps}
                    />
                </div>
                }
                {isDonationsVisible && <Separator classes={classes}/>}
                <ShareSection classes={classes} campaign={campaign}/>
                <Separator classes={classes}/>
                {campaign.sponsorsEnabled && !!campaign.sponsors && campaign.sponsors.length && <>
                    <SponsorsSection classes={classes} campaign={campaign}/>
                    <Separator classes={classes}/>
                </>}
                <EmpoweredSection organisation={campaign.organisation} classes={classes}/>
            </div>
        </Box>
    );
};

const FixedJoinButtonBar = ({campaign, isVisible, handleJoinCampaign, classes}) => {
    const {t} = useTranslation();

    if (!isVisible) {
        return null;
    }

    return (
        <Box className={classes.fixedJoinButtonBar}>
            <Hidden xsDown>
                <Typography className={classes.fixedJoinButtonBarFreeRoots}>
                    {t('common.name')}
                </Typography>
                <Typography className={classes.fixedJoinButtonBarTitle}>{campaign.title}</Typography>
            </Hidden>
            <Box className={classes.fixedJoinButtonBarButtonWrapper}>
                <JoinButton
                    size="small"
                    campaign={campaign}
                    handleJoinCampaign={handleJoinCampaign}
                    classes={classes}
                />
            </Box>
        </Box>
    );
};

const CampaignSection = ({classes, campaign, handleJoinCampaign, setJoinButtonVisible}) => {
    const {t} = useTranslation()
    const logoImageUrl = campaign.logoImageUrl || campaign.organisation.logoImageUrl || images.orgPlaceholderImg;

    return (
        <Grid container spacing={0}>
            <Grid container item xs={12} md={6} className={classes.titleContainer}>
                <Typography variant="h1" className={classes.title}>{campaign.title}</Typography>
                <div className={classes.organisationSection}>
                    <img src={logoImageUrl} alt="organization logo"
                         className={classes.organisationLogo}/>
                    <Typography variant="h5"
                        className={classes.organisation}>{campaign.organisationNameOverride || campaign.organisation.name}</Typography>
                    {campaign.organisation.verified && <>
                        <img src={verifiedbadge} alt={`verified by ${t('common.name')}`}
                             className={classes.verifiedLogo}/>
                        <Typography className={classes.verifiedText}>Verified</Typography>
                    </>}
                </div>
                <IntersectionVisible
                    onHide={() => setJoinButtonVisible(false)}
                    onShow={() => setJoinButtonVisible(true)}
                >
                    <JoinButton
                        campaign={campaign}
                        handleJoinCampaign={handleJoinCampaign}
                        classes={classes}
                    />
                </IntersectionVisible>
            </Grid>
            <Grid container item xs={12} md={6} className={classes.imageContainer}>
                <img alt={campaign.title} src={campaign.bannerImageUrl} className={classes.image}/>
                {campaign.actionsCountEnabled &&
                <ActionsSection campaign={campaign} classes={classes}/>
                }
            </Grid>
        </Grid>
    );
};

const DescriptionAndQuoteSection = ({campaign, classes}) =>
    <Grid container spacing={0}>
        <Grid item xs={12} md={8}>
            <Typography
                className={classes.description}><LinkifyExternal>{campaign.description}</LinkifyExternal></Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.quoteWrapper}>
            {!!campaign.quote && campaign.quoteEnabled &&
            <>
                <Typography className={classes.quote}>"{campaign.quote}"</Typography>
                <Box className={classes.quotePersonWrapper}>
                    {campaign.quoteImageUrl &&
                    <img
                        alt={campaign.quoteName}
                        className={classes.quoteImage}
                        src={campaign.quoteImageUrl}
                    />
                    }
                    <Box className={classes.quoteNameWrapper}>
                        <Typography className={classes.quoteName}>
                            {campaign.quoteName}{campaign.quoteTitle ? ',' : ''}
                        </Typography>
                        <Typography className={classes.quoteName}>{campaign.quoteTitle}</Typography>
                    </Box>
                </Box>
            </>
            }
        </Grid>
    </Grid>;

const ShareSection = ({classes, campaign}) =>
    <Grid container spacing={0} className={classes.shareBox}>
        <Grid item xs={12} md={6}>
            <Typography className={classes.shareTitle}>
                Spread the word!
            </Typography>
        </Grid>
        <Grid item container xs={12} md={6}>
        {campaign.shareFacebookEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <FacebookButton campaign={campaign}/>
        </Grid>
        }
        {campaign.organisation.id !== 12812 && campaign.organisation.id !== 564024 && campaign.shareParlerEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <ParlerButton campaign={campaign}/>
        </Grid>
        }
        {campaign.shareTwitterEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <TwitterButton campaign={campaign}/>
        </Grid>
        }
        {campaign.shareGabEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <GabButton campaign={campaign}/>
        </Grid>
        }
        {campaign.shareLinkedinEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <LinkedinButton campaign={campaign}/>
        </Grid>
        }
        {campaign.shareTelegramEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <TelegramButton campaign={campaign}/>
        </Grid>
        }
        {campaign.shareEmailEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <EmailButton campaign={campaign}/>
        </Grid>
        }
        {campaign.shareSignalEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <SignalButton campaign={campaign}/>
        </Grid>
        }
        {campaign.shareCopyEnabled &&
        <Grid item xs={4} md={3} className={classes.shareIcon}>
            <CopyButton campaign={campaign}/>
        </Grid>
        }
        </Grid>
    </Grid>;

const SponsorsSection = ({classes, campaign}) =>
    <Grid container spacing={0} className={classes.shareBox}>
        <Grid item xs={12}>
            <Typography className={classes.sponsorsTitle}>
                {campaign.sponsorsHeadingText || 'Thank you to our sponsors:'}
            </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sponsorsContainer}>
            {campaign.sponsors.map(sponsor => <div className={classes.sponsorLogoAndTitle}>
                <div className={classes.logoImageContainer}>
                    {!!sponsor.url &&
                    <a href={sponsor.url.startsWith("http") ? sponsor.url : "https://" + sponsor.url} target="_blank" rel="noopener noreferrer"><img src={sponsor.logoImageUrl} alt="sponsor logo"
                                                               className={classNames(classes.circular, classes.img)}/></a>}
                    {!sponsor.url && <img src={sponsor.logoImageUrl} alt="sponsor logo"
                                          className={classNames(classes.circular, classes.img)}/>}
                </div>
                <Typography variant="caption" color="textSecondary" align="center">
                    {sponsor.name}
                </Typography>
            </div>)}
        </Grid>
    </Grid>;

const EmpoweredSection = ({organisation, classes}) => {
    const {t} = useTranslation();

    return <Box className={classes.empoweredBox}>
        {!organisation.whiteLabelLogoImageUrl &&
        <Link href={ROUTES.ROOT}>
            <Hidden smUp>
                <FreeRootsLogo variant="image-only" color="#000000"/>
            </Hidden>
            <Hidden xsDown>
                <FreeRootsLogo color="#000000"/>
            </Hidden>
        </Link>
        }
        {!!organisation.whiteLabelLogoImageUrl &&
        <img src={organisation.whiteLabelLogoImageUrl} height={100} alt="logo"/>
        }
        <div>
            <Typography className={classes.empoweredBy}>{t('campaign.empoweredBy')} </Typography>
            <Link href="/" className={classes.freeRoots}>{t('common.name')}</Link>
        </div>
        <div className={classes.spacer}>&nbsp;</div>
    </Box>;
};

const Separator = ({classes}) =>
    <Grid container>
        <Grid item xs={12}>
            <Box className={classes.separator}/>
        </Grid>
    </Grid>;

const JoinButton = ({size, handleJoinCampaign, classes}) =>
    <Button
        onClick={handleJoinCampaign}
        variant="contained"
        color="primary"
        size="large"
        className={size === 'small' ? classes.joinButton : classNames(classes.joinButton, classes.joinButtonLarge)}
        style={{height: size === 'small' ? 40 : 70}}
        classes={{
            label: classes.joinButton
        }}
    >
        Take Action Now
        <ArrowForward className={classes.arrowForward}/>
    </Button>;

export default SimpleTemplate;
