import {makeStyles} from "@material-ui/core";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton, TelegramIcon, TelegramShareButton,
    TwitterIcon
} from "react-share";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import TwitterShareButton from "react-share/es/TwitterShareButton";
import Box from "@material-ui/core/Box";
import FileCopy from "@material-ui/icons/FileCopy";
import {getCampaignUrl} from "../../utils/link-utils";
import {copyToClipboard} from "../../utils/clipboard";
import {gaEvent} from "../../analytics/googleAnalytics";
import {facebookPixelEvent} from "../../analytics/facebookPixel";
import ParlerShareButton from "../Common/ParlerShareButton";
import GabShareButton from "../Common/GabShareButton";
import SignalShareButton from "../Common/SignalShareButton";

const colors = {
    greyish: '#a2a2a2'
};

const useStyles = makeStyles(theme => ({
    shareIconContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 90
    },
    shareLabel: {
        fontSize: '0.85rem',
        color: colors.greyish,
        marginTop: '5px'
    },
    copyIconWrapper: {
        background: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    copyIcon: {
        fill: '#ffffff',
    },
}));

const sendAnalyticsEvents = (campaign, actionType) => {
    gaEvent(campaign, {
        category: 'Social Shares',
        action: actionType,
        label: window.location.pathname
    });

    facebookPixelEvent(campaign, 'Social Shares', {
        action: actionType,
        label: window.location.pathname
    });
};

export const FacebookButton = ({campaign, shareText}) => {
    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    return <FacebookShareButton
        url={link}
        className={classes.shareIconContainer}
        quote={shareText || campaign.shareText || ''}
        beforeOnClick={() => sendAnalyticsEvents(campaign, "FACEBOOK")}
    >
        <FacebookIcon size={48} round={true}/>
        <Typography className={classes.shareLabel}>Facebook</Typography>
    </FacebookShareButton>
};

export const TwitterButton = ({campaign, shareText}) => {
    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    return <TwitterShareButton
        url={link}
        title={shareText || campaign.shareText || ''}
        className={classes.shareIconContainer}
        beforeOnClick={() => sendAnalyticsEvents(campaign, "TWITTER")}
    >
        <TwitterIcon size={48} round={true}/>
        <Typography className={classes.shareLabel}>Twitter</Typography>
    </TwitterShareButton>
};

export const ParlerButton = ({campaign, shareText}) => {
    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    return <ParlerShareButton
        link={link}
        message={shareText || campaign.shareText || ''}
        className={classes.shareIconContainer}
        beforeOnClick={() => sendAnalyticsEvents(campaign, "PARLER")}
    />
};

export const GabButton = ({campaign, shareText}) => {
    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    return <GabShareButton
        link={link}
        message={shareText || campaign.shareText || ''}
        className={classes.shareIconContainer}
        beforeOnClick={() => sendAnalyticsEvents(campaign, "GAB")}
    />
};

export const SignalButton = ({campaign, shareText}) => {
    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    return <SignalShareButton
        link={link}
        message={shareText || campaign.shareText || ''}
        className={classes.shareIconContainer}
        beforeOnClick={() => sendAnalyticsEvents(campaign, "SIGNAL")}
    />
};

export const TelegramButton = ({campaign}) => {

    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    return <TelegramShareButton
        url={link}
        className={classes.shareIconContainer}
        beforeOnClick={() => sendAnalyticsEvents(campaign, "TELEGRAM")}
    >
        <TelegramIcon size={48} round={true}/>
        <Typography className={classes.shareLabel}>Telegram</Typography>
    </TelegramShareButton>
};

export const LinkedinButton = ({campaign}) => {
    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    return <LinkedinShareButton
        url={link}
        className={classes.shareIconContainer}
        beforeOnClick={() => sendAnalyticsEvents(campaign, "LINKEDIN")}
    >
        <LinkedinIcon size={48} round={true}/>
        <Typography className={classes.shareLabel}>LinkedIn</Typography>
    </LinkedinShareButton>
};

export const EmailButton = ({campaign, shareText}) => {
    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    return <EmailShareButton
        url={link}
        body={shareText || campaign.shareText || ''}
        subject={campaign.title}
        className={classes.shareIconContainer}
        beforeOnClick={() => sendAnalyticsEvents(campaign, "EMAIL")}
    >
        <EmailIcon size={48} round={true}/>
        <Typography className={classes.shareLabel}>Email</Typography>
    </EmailShareButton>
};

export const CopyButton = ({campaign}) => {
    const [copyText, setCopyText] = useState("Copy");
    const classes = useStyles();
    const link = getCampaignUrl(campaign);

    const copy = () => {
        setCopyText("Copied");
        copyToClipboard(link);
        sendAnalyticsEvents(campaign, "COPYLINK")
    };

    return <Box className={classes.shareIconContainer} onClick={copy}>
        <CopyIcon size={48} round={true} classes={classes}/>
        <Typography className={classes.shareLabel}>{copyText}</Typography>
    </Box>
};

const CopyIcon = ({size, round, classes}) =>
    <Box
        className={classes.copyIconWrapper}
        style={{
            width: size,
            height: size,
            borderRadius: round ? '50%' : '0',
        }}
    >
        <FileCopy className={classes.copyIcon} />
    </Box>;