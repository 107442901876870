import {apolloClient} from '../../graphql'
import gql from "graphql-tag";

export const LIST_EVENT_EMAILS_BEGIN = "LIST_EVENT_EMAILS_BEGIN";
export const LIST_EVENT_EMAILS_SUCCESS = "LIST_EVENT_EMAILS_SUCCESS";
export const LIST_EVENT_EMAILS_FAILURE = "LIST_EVENT_EMAILS_FAILURE";
export const UPDATE_EVENT_EMAIL_BEGIN = "UPDATE_EVENT_EMAIL_BEGIN";
export const UPDATE_EVENT_EMAIL_SUCCESS = "UPDATE_EVENT_EMAIL_SUCCESS";
export const UPDATE_EVENT_EMAIL_FAILURE = "UPDATE_EVENT_EMAIL_FAILURE";

export const listEventEmailsBegin = () => ({
    type: LIST_EVENT_EMAILS_BEGIN
});

export const listEventEmailsSuccess = (eventEmails) => ({
    type: LIST_EVENT_EMAILS_SUCCESS,
    payload: {eventEmails}
});

export const listEventEmailsFailure = error => ({
    type: LIST_EVENT_EMAILS_FAILURE,
    payload: {error}
});

const LIST_EVENT_EMAILS_QUERY = gql`
  query ListEventEmails($organisationId: Int!, $eventId: Int!) {
    event(organisationId: $organisationId, eventId: $eventId) {
        eventEmails {
            id
            organisationId
            eventId
            subject
            message
            status
            replyTo
            rsvpEmail
        }
    }
  }
`;

export function listEventEmails(organisationId, eventId) {
    return async dispatch => {
        dispatch(listEventEmailsBegin());
        try {
            const listEventEmailsResponse = await apolloClient.query({
                query: LIST_EVENT_EMAILS_QUERY,
                variables: {
                    organisationId,
                    eventId
                }
            });
            const eventEmails = listEventEmailsResponse.data.event.eventEmails;
            dispatch(listEventEmailsSuccess(eventEmails));
        } catch (error) {
            dispatch(listEventEmailsFailure(error));
        }
    };
}

const SEND_TEST_EVENT_EMAIL_MUTATION = gql`
  mutation TestEventEmail($organisationId: Int!, $eventId: Int!, $eventEmailId: Int!, $testEmail: String!) {
    testEventEmail(organisationId: $organisationId, eventId: $eventId, eventEmailId: $eventEmailId, testEmail: $testEmail) {
        success
    }
  }
`;


export async function sendTestEmail(organisationId, eventId, eventEmailId, testEmail) {
    const sendTestEmailResponse = await apolloClient.mutate({
        mutation: SEND_TEST_EVENT_EMAIL_MUTATION,
        variables: {
            organisationId: organisationId,
            eventId: eventId,
            eventEmailId: eventEmailId,
            testEmail: testEmail
        }
    });
    return sendTestEmailResponse.data.testEventEmail.success;
}

const SEND_EVENT_EMAIL_MUTATION = gql`
  mutation SendEventEmail($organisationId: Int!, $eventId: Int!, $eventEmailId: Int!) {
    sendEventEmail(organisationId: $organisationId, eventId: $eventId, eventEmailId: $eventEmailId) {
        success,
        errors {
            code,
            message
        }
    }
  }
`;

export async function sendEventEmail(organisationId, eventId, eventEmailId) {
    const sendEventEmailResponse = await apolloClient.mutate({
        mutation: SEND_EVENT_EMAIL_MUTATION,
        variables: {
            organisationId: organisationId,
            eventId: eventId,
            eventEmailId: eventEmailId
        }
    });
    const response = sendEventEmailResponse.data.sendEventEmail;
    if (response.errors) {
        throw response.errors[0]
    }
    return response;
}

const UPDATE_EVENT_EMAIL_MUTATION = gql`
  mutation UpdateEventEmail($eventEmailInput: EventEmailInput!) {
    updateEventEmail(eventEmailInput: $eventEmailInput) {
        id
        organisationId
        eventId
        subject
        message
        status
        replyTo
        rsvpEmail
    }
  }
`;

export const updateEventEmailBegin = () => ({
    type: UPDATE_EVENT_EMAIL_BEGIN
});

export const updateEventEmailSuccess = (eventEmail) => ({
    type: UPDATE_EVENT_EMAIL_SUCCESS,
    payload: { eventEmail }
});

export const updateEventEmailFailure = error => ({
    type: UPDATE_EVENT_EMAIL_FAILURE,
    payload: { error }
});

export function updateEventEmail(eventEmail) {
    return async dispatch => {
        dispatch(updateEventEmailBegin());
        try {
            const input = {...eventEmail};
            delete input.status;
            const updateEventEmailResponse = await apolloClient.mutate({
                mutation: UPDATE_EVENT_EMAIL_MUTATION,
                variables: {
                    eventEmailInput: input,
                }
            });
            const updatedEvent = updateEventEmailResponse.data.updateEventEmail;
            dispatch(updateEventEmailSuccess(updatedEvent));
        } catch (error) {
            console.log(error);
            dispatch(updateEventEmailFailure(error));
        }
    };
}
