import {DOMAIN_FR, DOMAIN_DE, DOMAIN_AA, DOMAIN_AE, getDomain} from "../constants/domains";

import templateSimpleImg from "./template-simple.png";
import templateVividImg from "./template-vivid.png";
import templatePhotoFocusImg from "./template-photo-focus.png";
import directEngageTemplateSimpleImg from "./directeenagage-template-simple.png";
import directEngageTemplateVividImg from "./directengage-template-vivid.png";
import directEngageTemplatePhotoFocusImg from "./directengage-template-photofocus.png";

import organisation_placeholder from "./organisation_placeholder.svg";
import organisation_placeholder_align from "./organisation_placeholder_align.svg";

const imageSet = {
    templateSimpleImg,
    templateVividImg,
    templatePhotoFocusImg,
    orgPlaceholderImg: organisation_placeholder
}

switch (getDomain()) {
    case DOMAIN_DE:
        imageSet.templateSimpleImg = directEngageTemplateSimpleImg;
        imageSet.templateVividImg = directEngageTemplateVividImg;
        imageSet.templatePhotoFocusImg = directEngageTemplatePhotoFocusImg;
        break;
    case DOMAIN_AE:
    case DOMAIN_AA:
        imageSet.orgPlaceholderImg = organisation_placeholder_align;
        break;
    case DOMAIN_FR:
    default:
        // FreeRoots is the default
}

export default imageSet
