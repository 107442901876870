import {DOMAIN_AA, DOMAIN_AE, DOMAIN_CONFIG, DOMAIN_DE, DOMAIN_FR} from "./domains";

const keyOfDomain = key => {
    switch (key) {
        case DOMAIN_DE:
            return process.env.REACT_APP_ENV === "production"
                ? 'pk_live_8oq7vbHl7OQ177VHuvVeelwj001WRTs0GP'
                : 'pk_test_UHIIE4brYsySXWQgXuaoRWxn00yYZCOJKN'
        case DOMAIN_AA:
        case DOMAIN_AE:
        case DOMAIN_FR:
        default:
            return process.env.REACT_APP_ENV === "production"
                ? 'pk_live_oLftEEF1toHk1Su02CCEQo5A00GuP7WiyL'
                : 'pk_test_a0OdBrtFukLG4onX04jcejnl006QLiQMa5'
    }
}

export const STRIPE_PUBLISHABLE_KEY = keyOfDomain(DOMAIN_CONFIG.domainKey)
