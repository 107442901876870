import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {useCountUp} from "react-countup/build";

export const ActionsSection = ({campaign, classes}) => {
    const actionsTaken = (campaign.analytics.publicActionCount.doneCount || 0) + (campaign.actionsCountStart || 0);
    const {countUp: actionsTakenCount, update} = useCountUp({end: actionsTaken, duration: 3.5, separator: ","});

    useEffect(() => {
        update(actionsTaken);
    });

    return <Box className={classes.actionsTakenBox}>
        <Typography className={classes.actionsTakenNumber}>{actionsTakenCount}</Typography>
        <Typography className={classes.actionsTakenLabel}>actions taken</Typography>
    </Box>;
};
