import * as policymakerTypes from "../constants/policymakerTypes";
import {
    DEFAULT_STATE_REPRESENTATIVE_SALUTATION,
    stateAbbreviationToRepresentativeSalutation
} from "../components/Common/states";

function gatherLegislator(campaign, user, policymakers, legislatorType, legislatorTitle) {
    if (campaign.targetLegislators.includes(legislatorType)) {
        const legislators = user.legislators.filter(legislator => legislator.type === legislatorType);
        legislators.forEach(legislator => {
            const twitterSocialMediaHandle = legislator.socialMediaHandles.find(it => it.socialMediaType === 'TWITTER');
            const facebookSocialMediaHandle = legislator.socialMediaHandles.find(it => it.socialMediaType === 'FACEBOOK');
            policymakers.push({
                id: legislator.bioId || legislator.divisionId,
                name: legislator.name,
                greetingName: legislator.greetingName,
                type: legislator.type,
                title: legislatorTitle,
                divisionId: legislator.divisionId,
                bioId: legislator.bioId,
                photoUrl: legislator.photoUrl,
                phone: legislator.phone,
                email: legislator.email,
                twitter: twitterSocialMediaHandle ? twitterSocialMediaHandle.handle : null,
                facebook: facebookSocialMediaHandle ? facebookSocialMediaHandle.handle : null
            })
        });
    }
}

export const gatherPolicymakers = (campaign, user) => {
    const policymakers = [];
    if (!campaign) {
        return policymakers;
    }
    campaign.targetIndividualPolicymakers.forEach(individual => {
        if (!individual.deleted && (individual.organisationId || campaign.federalPolicymakersEnabled)) {
            policymakers.push({
                id: individual.id,
                name: individual.name,
                greetingName: individual.greetingName,
                type: policymakerTypes.INDIVIDUAL,
                title: individual.role,
                photoUrl: individual.imageUrl,
                email: individual.email,
                phone: individual.phone,
                twitter: individual.twitter,
                facebook: individual.facebook
            });
        }
    });
    if (campaign.federalPolicymakersEnabled) {
        gatherLegislator(campaign, user, policymakers, policymakerTypes.SENATOR, "Senator");
        gatherLegislator(campaign, user, policymakers, policymakerTypes.REPRESENTATIVE, "Representative");
    }
    if (campaign.statePolicymakersEnabled && user.address) {
        const stateRepresentativeSalutation = stateAbbreviationToRepresentativeSalutation[user.address.state] || DEFAULT_STATE_REPRESENTATIVE_SALUTATION
        gatherLegislator(campaign, user, policymakers, policymakerTypes.STATE_GOVERNOR, "Governor");
        gatherLegislator(campaign, user, policymakers, policymakerTypes.STATE_SENATOR, "State Senator");
        gatherLegislator(campaign, user, policymakers, policymakerTypes.STATE_REPRESENTATIVE, stateRepresentativeSalutation);
    }

    return policymakers;
};