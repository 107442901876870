import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const loadingIndicator = {
    marginTop: '150px',
    textAlign: 'center'
};

const FullScreenProgress = () =>
    <div style={loadingIndicator}>
        <CircularProgress/>
    </div>;

export default FullScreenProgress;
