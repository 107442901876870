export const ROOT = '/';
export const SIGN_UP_OR_SIGN_IN = '/start';
export const SIGN_UP = '/signup/:hash';
export const EMAIL_SIGNUP = '/register/email';
export const SIGN_IN = '/signin';
export const TERMS_OF_SERVICE = '/terms';
export const PRIVACY_POLICY = '/privacypolicy';
export const ADDRESS_REGISTRATION = '/address';
export const EMAIL_VERIFICATION = '/verify/:hash';
export const FORGOT_PASSWORD = '/forgotpassword';
export const HOME = '/home';
export const CAMPAIGN_MANAGER = '/home';
export const CONTACTS = '/home/contacts';
export const CONTACTS_MODAL = '/contacts';
export const ORGANISATION_PROFILE = '/home/organization/profile';
export const UPDATE_ORGANISATION = '/organization';
export const CAMPAIGN_WELCOME = '/manage/campaign/welcome';
export const CREATE_CAMPAIGN = '/manage/campaign/:id';
export const CREATE_CAMPAIGN_DESCRIBE = '/manage/campaign/:id/describe';
export const CREATE_CAMPAIGN_DIRECT = '/manage/campaign/:id/direct';
export const CREATE_CAMPAIGN_DESIGN = '/manage/campaign/:id/design';
export const CREATE_CAMPAIGN_DONATE = '/manage/campaign/:id/donate';
export const CREATE_EVENT = '/manage/event/:id';
export const CREATE_EVENT_DESCRIBE = '/manage/event/:id/describe';
export const EVENT_EMAIL_COMPOSE = '/manage/event/:id/email';
export const EVENT_EMAIL_SEND = '/manage/event/:id/send';
export const CAMPAIGN_EMAIL = '/manage/campaign/:id/promote/email/:emailId';
export const CAMPAIGN_PROMOTE = '/manage/campaign/:id/promote';
export const CAMPAIGN_PROMOTE_EMAIL = '/manage/campaign/:id/promote/email';
export const CAMPAIGN_PROMOTE_SMS = '/manage/campaign/:id/promote/sms';
export const CAMPAIGN_SMS = '/manage/campaign/:id/promote/sms/:smsId';
export const CAMPAIGN_PROMOTE_NETWORK = '/manage/campaign/:id/promote/network';
export const CAMPAIGN_PROMOTE_CELEBRITIES = '/manage/campaign/:id/promote/celebrities';
export const CAMPAIGN_PROMOTE_WEBSITE = '/manage/campaign/:id/promote/website';
export const CAMPAIGN_PROMOTE_SOCIAL = '/manage/campaign/:id/promote/social';
export const CAMPAIGN_PROMOTE_ALLIANCE = '/manage/campaign/:id/promote/alliance';
export const CAMPAIGN_ANALYTICS = '/manage/campaign/:id/analytics';
export const CAMPAIGN_ANALYTICS_GOOGLE = '/manage/campaign/:id/analytics/google';
export const CAMPAIGN_ANALYTICS_FACEBOOK = '/manage/campaign/:id/analytics/facebook';
export const PREVIEW_CAMPAIGN = '/preview/campaign/:id';
export const UNSUBSCRIBE_FROM_CAMPAIGN = '/unsubscribe/:campaignId/:contactId/:hash';
export const VIEW_CAMPAIGN = '/campaign/:link';
export const VIEW_INITIATIVE = '/initiative/:link';
export const VIEW_CAMPAIGN_SHORT = '/go/:link';
export const CAMPAIGN_INTRO = '/campaign/intro/:link';
export const COPY_CAMPAIGN = '/copycampaign/:link';
export const ADVOCATE_ACTIONS = '/campaign/:link/actions/:action';
export const ADVOCATE_ACTIONS_INITIATIVE = '/initiative/:link/actions/:action';
export const ADVOCATE_ACTIONS_SHORT = '/go/:link/actions/:action';
export const AUTH_STRIPE_SUCCESS = '/auth/stripe/success';
export const ADMIN = '/admin';
export const ADMIN_ORGANISATIONS = '/admin/organisations';
export const ADMIN_CAMPAIGNS = '/admin/campaigns';
export const ADMIN_STATES = '/admin/states';
export const ADMIN_SENATORS = '/admin/states/:stateAbbrev/senators';
export const ADMIN_POLICYMAKER_CATEGORIES = '/admin/policymakers/categories';
export const ADMIN_POLICYMAKER_INDIVIDUALS = '/admin/policymakers/individuals';
export const FAQ = '/faq';
export const COMPLIANCE = '/compliance';
export const ACCOUNT_SETTINGS = '/account';
export const ACCOUNT_VERIFY = '/verify';
/** @FIXME temporary url route */
export const DONATE = '/donate';
// Align4Freedom additional routes
export const MISSION = '/mission';
export const ABOUT = '/about';
// Featured and Trending Manage 
export const FEATURED_TRENDING_CAMPAIGNS = "/home/organization/featured-trending-campaigns"
export const TRENDING_CAMPAIGNS = '/trending';

export const viewCampaignRoute = () => {
    // if (isDE) {
    //     return VIEW_INITIATIVE;
    // } else {
    //     return VIEW_CAMPAIGN;
    // }
    return VIEW_CAMPAIGN_SHORT
}

export const advocateActionsRoute = () => {
    // if (isDE) {
    //     return ADVOCATE_ACTIONS_INITIATIVE;
    // } else {
    //     return ADVOCATE_ACTIONS;
    // }
    return ADVOCATE_ACTIONS_SHORT
}