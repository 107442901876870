import {
    CREATE_EVENT_BEGIN,
    CREATE_EVENT_FAILURE,
    CREATE_EVENT_SUCCESS,
    DELETE_EVENT_BEGIN,
    DELETE_EVENT_FAILURE,
    DELETE_EVENT_SUCCESS,
    LIST_EVENTS_BEGIN,
    LIST_EVENTS_FAILURE,
    LIST_EVENTS_SUCCESS,
    LOAD_EVENT_BEGIN,
    LOAD_EVENT_FAILURE,
    LOAD_EVENT_SUCCESS,
    UPDATE_EVENT_BEGIN,
    UPDATE_EVENT_FAILURE,
    UPDATE_EVENT_SUCCESS,
    UPLOAD_EVENT_BANNER_BEGIN,
    UPLOAD_EVENT_BANNER_FAILURE,
    UPLOAD_EVENT_BANNER_SUCCESS,
    UPLOAD_EVENT_LOGO_BEGIN,
    UPLOAD_EVENT_LOGO_FAILURE,
    UPLOAD_EVENT_LOGO_SUCCESS
} from "./eventActions";
import {ADMIN_ORGANISATION_SET, AUTH_USER_SET} from "../../session/sessionActions";

const initialState = {
    event: null,
    events: null,
    loading: false,
    error: null,
    logoUploading: false,
    bannerUploading: false
};

export default function eventReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_EVENT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case CREATE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                event: action.payload.event
            };

        case CREATE_EVENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case UPLOAD_EVENT_BANNER_BEGIN:
            return {
                ...state,
                bannerUploading: true,
                error: null
            };

        case UPLOAD_EVENT_BANNER_SUCCESS:
            return {
                ...state,
                bannerUploading: false,
                event: action.payload.event
            };

        case UPLOAD_EVENT_BANNER_FAILURE:
            return {
                ...state,
                bannerUploading: false,
                error: action.payload.error.message
            };

        case UPLOAD_EVENT_LOGO_BEGIN:
            return {
                ...state,
                logoUploading: true,
                error: null
            };

        case UPLOAD_EVENT_LOGO_SUCCESS:
            return {
                ...state,
                logoUploading: false,
                event: action.payload.event
            };

        case UPLOAD_EVENT_LOGO_FAILURE:
            return {
                ...state,
                logoUploading: false,
                error: action.payload.error.message
            };

        case UPDATE_EVENT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case UPDATE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                event: action.payload.event,
                events: (state.events || []).map((event) => {
                    if (event.id === action.payload.event.id) {
                        return action.payload.event;
                    }
                    return event;
                })
            };

        case UPDATE_EVENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case DELETE_EVENT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: (state.events || []).filter((event) => event.id !== action.payload.event.id)
            };

        case DELETE_EVENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case LOAD_EVENT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LOAD_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                event: action.payload.event
            };

        case LOAD_EVENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case LIST_EVENTS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LIST_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.payload.events
            };

        case LIST_EVENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case AUTH_USER_SET: {
            return action.authUser ? state : initialState
        }

        case ADMIN_ORGANISATION_SET: {
            return initialState
        }

        default:
            return state;
    }
}
