import {isDonationsEnabled} from "./utils";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowForward from "@material-ui/icons/ArrowForward";
import DonationsList from "./donationsList";
import React, {useEffect} from "react";
import {useCountUp} from "react-countup";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    donationsTitle: {
        fontSize: '3rem',
        fontWeight: 600,
        maxWidth: 500,
        letterSpacing: 0,
        lineHeight: '3rem',
        paddingTop: 40
    },
    donationsTitleCountPadded: {
        [theme.breakpoints.up('sm')]: {
            margin: '0px auto 30px 0',
            marginLeft: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '70px auto 30px 0',
            marginLeft: "0",
        },
    },
    donationsTitleNotCountPadded: {
        margin: '0px auto 30px 0',
        marginLeft: '20px',
    },
    donationsCountBox: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        right: "40px",
        top: 0,
        marginTop: "-25px",
        background: theme.palette.background.counter,
        borderRadius: "50%",
        width: "135px",
        height: "135px",
    },
    donationsCountNumber: {
        fontWeight: 600,
        color: "#ffffff",
        display: 'inline',
        fontSize: "2rem",
    },
    donationsCountLabel: {
        fontWeight: 600,
        color: "#ffffff",
        display: 'inline',
        fontSize: "1rem",
    },
    donationsDescription: {
        maxWidth: 400,
        marginRight: "auto",
        whiteSpace: "pre-line",
        marginBottom: theme.spacing(3)
    },
    donationsButton: {
        width: 230,
        height: 56,
        margin: '20px 0 30px 0',
    },
    width800marginAuto: {
        maxWidth: 800,
        margin: 'auto',
        justifyContent: 'space-between'
    },
    donationsRightPart: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 50,
        },
        alignSelf: 'flex-start'
    },
    arrowForward: {
        fontSize: "1.25rem",
        marginLeft: '0.5rem',
    },
    relative: {
        position: "relative",
    },
    textField: {
        width: 230
    },
    donationsList: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));

const DonationSection = ({ campaign, donationProps: { onChangeSelectedDonation, selectedDonation, onDonateClick } }) => {
    const { type, amount } = selectedDonation;

    const classes = useStyles();

    if (!isDonationsEnabled(campaign)) {
        return null;
    }

    const handleCheckbox = (option) => {
        onChangeSelectedDonation({ type: 'radio', amount: option });
    };

    const handleTextField = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        if (value > 0 || value === '') {
            onChangeSelectedDonation({ type: 'freeform', amount: value })
        }
    };
    const donationsList = (campaign.analytics.donations.publicPayments && campaign.analytics.donations.publicPayments.content) || [];
    const donateDisabled = !selectedDonation.amount;

    const titlePaddingClass = campaign.donationsAmountEnabled ? classes.donationsTitleCountPadded : classes.donationsTitleNotCountPadded

    return (
        <Box className={classes.relative}>
            {campaign.donationsAmountEnabled &&
            <DonationsCount campaign={campaign} classes={classes}/>
            }
            <Grid container spacing={0}>
                <Grid item xs={10}>
                    <Typography className={classNames(classes.donationsTitle, titlePaddingClass)}>
                        {campaign.donationsConfig.donationsTitle}
                    </Typography>
                </Grid>
                <Grid className={classes.width800marginAuto} container item xs={12}>
                    <Grid className={classes.donationsRightPart} container item xs={12}>
                        <Grid item xs={12} md={8}>
                            <Typography className={classes.donationsDescription}>
                                {campaign.donationsConfig.donationsDescription}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={4} className={classes.donationsRightPart}>
                        {campaign.donationsConfig.donationAmounts.map((option, i) =>
                            <Grid key={i} item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<RadioButtonUnchecked />}
                                            checkedIcon={<RadioButtonChecked />}
                                            color="primary"
                                            checked={amount === option && type === 'radio'}
                                            onChange={() => handleCheckbox(option)}
                                        />
                                    }
                                    label={`$${option}`}
                                />
                            </Grid>
                        )}
                        {campaign.donationsConfig.allowAnyAmount &&
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                label="Enter other amount"
                                variant="outlined"
                                margin="dense"
                                value={type === 'freeform' ? amount : ''}
                                onChange={(event) => handleTextField(event)}
                            />
                        </Grid>
                        }
                        <Button variant="contained" color="primary" className={classes.donationsButton} onClick={onDonateClick} disabled={donateDisabled}>
                            Donate
                            <ArrowForward className={classes.arrowForward} />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.donationsList}>
                        <DonationsList donations={donationsList} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

const DonationsCount = ({ campaign, classes }) => {
    const totalAmount = (campaign.analytics.donations.publicCampaignTotals.totalAmount || 0) + (campaign.donationsAmountStart || 0);
    const { countUp: donationsCount, update } = useCountUp({ end: totalAmount, duration: 3.5, separator: "," });

    useEffect(() => {
        update(totalAmount);
    });

    return <Box className={classes.donationsCountBox}>
        <Typography className={classes.donationsCountNumber}>${donationsCount}</Typography>
        <Typography className={classes.donationsCountLabel}>Donated</Typography>
    </Box>;
};

export default DonationSection;
