import {
    CREATE_INDIVIDUAL_POLICYMAKER_BEGIN,
    CREATE_INDIVIDUAL_POLICYMAKER_FAILURE,
    CREATE_INDIVIDUAL_POLICYMAKER_SUCCESS,
    LOAD_INDIVIDUAL_POLICYMAKERS_BEGIN,
    LOAD_INDIVIDUAL_POLICYMAKERS_FAILURE,
    LOAD_INDIVIDUAL_POLICYMAKERS_SUCCESS,
    UPDATE_INDIVIDUAL_POLICYMAKER_BEGIN,
    UPDATE_INDIVIDUAL_POLICYMAKER_FAILURE,
    UPDATE_INDIVIDUAL_POLICYMAKER_SUCCESS
} from "./policymakerActions";

const initialState = {
    individualPolicymakersTree: null,
    individualPolicymakersForOrganisation: [],
    individualPolicymakersTreeLoading: false,
    individualPolicymakersTreeError: null
};

export default function policymakerReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_INDIVIDUAL_POLICYMAKERS_BEGIN:
            return {
                ...state,
                individualPolicymakersTreeLoading: true,
                individualPolicymakersTreeError: null
            };

        case LOAD_INDIVIDUAL_POLICYMAKERS_SUCCESS:
            return {
                ...state,
                individualPolicymakersTreeLoading: false,
                individualPolicymakersTree: action.payload.individualPolicymakersTree,
                individualPolicymakersForOrganisation: action.payload.individualPolicymakersForOrganisation
            };

        case LOAD_INDIVIDUAL_POLICYMAKERS_FAILURE:
            return {
                ...state,
                individualPolicymakersTreeLoading: false,
                individualPolicymakersTreeError: action.payload.error.message
            };

        case CREATE_INDIVIDUAL_POLICYMAKER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case CREATE_INDIVIDUAL_POLICYMAKER_SUCCESS:
            return {
                ...state,
                loading: false,
                individualPolicymakersForOrganisation: [...state.individualPolicymakersForOrganisation, action.payload.policymaker]
            };
        
        case CREATE_INDIVIDUAL_POLICYMAKER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case UPDATE_INDIVIDUAL_POLICYMAKER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case UPDATE_INDIVIDUAL_POLICYMAKER_SUCCESS:
            return {
                ...state,
                loading: false,
                individualPolicymakersForOrganisation: action.payload.policymaker.deleted ?
                    state.individualPolicymakersForOrganisation.filter(policymaker => policymaker.id !== action.payload.policymaker.id) :
                    state.individualPolicymakersForOrganisation.map(policymaker => policymaker.id === action.payload.policymaker.id ? action.payload.policymaker : policymaker)
            };

        case UPDATE_INDIVIDUAL_POLICYMAKER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        default:
            return state;
    }
}
