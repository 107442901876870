import {
    CREATE_CAMPAIGN_BEGIN,
    CREATE_CAMPAIGN_FAILURE,
    CREATE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_BEGIN,
    DELETE_CAMPAIGN_FAILURE,
    DELETE_CAMPAIGN_SUCCESS,
    LIST_CAMPAIGNS_BEGIN,
    LIST_CAMPAIGNS_FAILURE,
    LIST_CAMPAIGNS_SUCCESS,
    LIST_FOLLOWED_CAMPAIGNS_BEGIN,
    LIST_FOLLOWED_CAMPAIGNS_FAILURE,
    LIST_FOLLOWED_CAMPAIGNS_SUCCESS,
    LOAD_CAMPAIGN_BEGIN,
    LOAD_CAMPAIGN_FAILURE,
    LOAD_CAMPAIGN_SUCCESS,
    UPDATE_ADVOCACY_ACTION_BEGIN,
    UPDATE_ADVOCACY_ACTION_FAILURE,
    UPDATE_ADVOCACY_ACTION_SUCCESS,
    UPDATE_CAMPAIGN_BEGIN,
    UPDATE_CAMPAIGN_FAILURE,
    UPDATE_CAMPAIGN_SUCCESS,
    UPLOAD_CAMPAIGN_BANNER_BEGIN,
    UPLOAD_CAMPAIGN_BANNER_FAILURE,
    UPLOAD_CAMPAIGN_BANNER_SUCCESS,
    UPLOAD_CAMPAIGN_LOGO_BEGIN,
    UPLOAD_CAMPAIGN_LOGO_FAILURE,
    UPLOAD_CAMPAIGN_LOGO_SUCCESS,
    UPLOAD_QUOTE_IMAGE_BEGIN,
    UPLOAD_QUOTE_IMAGE_FAILURE,
    UPLOAD_QUOTE_IMAGE_SUCCESS
} from "./campaignActions";
import {ADMIN_ORGANISATION_SET, AUTH_USER_SET} from "../../session/sessionActions";

const initialState = {
    campaign: null,
    campaigns: null,
    followedCampaigns: null,
    loading: false,
    error: null,
    logoUploading: false,
    bannerUploading: false,
    quoteUploading: false
};

export default function campaignReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_CAMPAIGN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case CREATE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                campaign: action.payload.campaign
            };

        case CREATE_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case UPLOAD_CAMPAIGN_BANNER_BEGIN:
            return {
                ...state,
                bannerUploading: true,
                error: null
            };

        case UPLOAD_CAMPAIGN_BANNER_SUCCESS:
            return {
                ...state,
                bannerUploading: false,
                campaign: action.payload.campaign
            };

        case UPLOAD_CAMPAIGN_BANNER_FAILURE:
            return {
                ...state,
                bannerUploading: false,
                error: action.payload.error.message
            };

        case UPLOAD_CAMPAIGN_LOGO_BEGIN:
            return {
                ...state,
                logoUploading: true,
                error: null
            };

        case UPLOAD_CAMPAIGN_LOGO_SUCCESS:
            return {
                ...state,
                logoUploading: false,
                campaign: action.payload.campaign
            };

        case UPLOAD_CAMPAIGN_LOGO_FAILURE:
            return {
                ...state,
                logoUploading: false,
                error: action.payload.error.message
            };

        case UPLOAD_QUOTE_IMAGE_BEGIN:
            return {
                ...state,
                quoteUploading: true,
                error: null
            };

        case UPLOAD_QUOTE_IMAGE_SUCCESS:
            return {
                ...state,
                quoteUploading: false,
                campaign: action.payload.campaign
            };

        case UPLOAD_QUOTE_IMAGE_FAILURE:
            return {
                ...state,
                quoteUploading: false,
                error: action.payload.error.message
            };

        case UPDATE_ADVOCACY_ACTION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case UPDATE_ADVOCACY_ACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                campaign: { ...state.campaign, advocacyActions: [action.payload.advocacyAction, ...state.campaign.advocacyActions.filter(it => it.actionType !== action.payload.advocacyAction.actionType)] }
            };

        case UPDATE_ADVOCACY_ACTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case UPDATE_CAMPAIGN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case UPDATE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                campaign: action.payload.campaign,
                campaigns: (state.campaigns || []).map((campaign) => {
                    if (campaign.id === action.payload.campaign.id) {
                        return action.payload.campaign;
                    }
                    return campaign;
                })
            };

        case UPDATE_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case DELETE_CAMPAIGN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case DELETE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                campaigns: (state.campaigns || []).filter((campaign) => campaign.id !== action.payload.campaign.id)
            };

        case DELETE_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case LOAD_CAMPAIGN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LOAD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                campaign: action.payload.campaign
            };

        case LOAD_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case LIST_CAMPAIGNS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LIST_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                loading: false,
                campaigns: action.payload.campaigns
            };

        case LIST_CAMPAIGNS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case LIST_FOLLOWED_CAMPAIGNS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LIST_FOLLOWED_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                loading: false,
                followedCampaigns: action.payload.campaigns
            };

        case LIST_FOLLOWED_CAMPAIGNS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case AUTH_USER_SET: {
            return action.authUser ? state : initialState
        }

        case ADMIN_ORGANISATION_SET: {
            return initialState
        }

        default:
            return state;
    }
}
