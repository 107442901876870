import {Dialog, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    container: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    button: {
        width: "100%"
    },
    centered: {
        display: "flex",
        justifyContent: "center"
    }
}));

const DonationThanksModal = ({open, setOpen}) => {

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={e => setOpen(false)}>
            <Grid container className={classes.root}>
                <Grid container item xs={12} spacing={4}
                      className={classnames(classes.container, classes.centered)}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="textPrimary">
                            Thank You!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textPrimary">
                            Your donation has been processed. We’ve sent you an email receipt confirming the contribution amount.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={e => setOpen(false)}
                                size="large"
                                variant="contained"
                                color="primary"
                                className={classes.button}>
                            Dismiss
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
};

export default DonationThanksModal;