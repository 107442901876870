import React, {useEffect} from 'react';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {loadCampaign} from '../viewCampaignActions';
import FullScreenProgress from "../../Common/FullScreenProgress";
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import FreeRootsLogo from "../../Landing/freeRootsLogo";
import {getCampaignUrl} from "../../../utils/link-utils";

const CampaignIntroPage = ({match, campaign, loadCampaign}) => {
    useEffect(() => {
        loadCampaign(match.params.link);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.link]);

    if (!campaign) {
        return <div>
            {<FullScreenProgress/>}
        </div>;
    }

    return (
        <CampaignIntro campaign={campaign}/>
    );
};

const useStyles = makeStyles(theme => ({
    screen: {
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    container: {
        maxWidth: 640,
        flex: 1,
        display: "flex"
    },
    img: {
        maxHeight: 320,
        width: 320,
        objectFit: "cover"
    },
    centered: {
        display: "flex",
        justifyContent: "center"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 320,
        overflow: "hidden",
        justifyContent: "space-between"
    },
    logo: {},
    title: {
        fontSize: 32,
        paddingTop: theme.spacing(2),
    },
    descriptionSection: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(2.5),
    },
    description: {
        display: "box",
        lineClamp: 3,
        boxOrient: "vertical",
        textOverflow: "ellipsis",
        maxHeight: 95,
        overflow: "hidden"
    },
    button: {
        height: 70,
        fontSize: "22px",

    }
}))

const CampaignIntro = ({campaign, history}) => {
    const classes = useStyles();

    return <div className={classes.screen}>
        <div className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className={classes.centered}>
                    <div className={classes.content}>
                        <div className={classes.logo}>
                            <FreeRootsLogo color={"black"}/>
                        </div>
                        <Typography variant="h3" color="textPrimary"
                                    className={classes.title}>{campaign.title}</Typography>
                        <div className={classes.descriptionSection}>
                            <Typography variant="body1" color="textPrimary"
                                        className={classes.description}>{campaign.description}</Typography>
                        </div>
                        <Button size={"large"} variant={"contained"} color={"primary"}
                                className={classes.button} href={getCampaignUrl(campaign)} target="_blank" rel="noopener noreferrer">
                            Take Action Now
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.centered}>
                    <img src={campaign.bannerImageUrl} alt="campaign" className={classes.img}/>
                </Grid>
            </Grid>
        </div>
    </div>
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadCampaign,
        },
        dispatch
    );

const mapStateToProps = state => ({
    campaign: state.viewCampaign.campaign,
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(CampaignIntroPage);
