import {
    PUBLIC_LOAD_CAMPAIGN_BEGIN,
    PUBLIC_LOAD_CAMPAIGN_FAILURE,
    PUBLIC_LOAD_CAMPAIGN_SUCCESS,
} from "./viewCampaignActions";

const initialState = {
    campaign: null,
    loading: false,
    error: null,
    following: null
};

export default function viewCampaignReducer(state = initialState, action) {
    switch (action.type) {
        case PUBLIC_LOAD_CAMPAIGN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case PUBLIC_LOAD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                campaign: action.payload.campaign
            };

        case PUBLIC_LOAD_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message || action.payload.error
            };

        default:
            return state;
    }
}
