import React, { useState, useEffect } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { STRIPE_PUBLISHABLE_KEY } from '../../constants/keys';

const promiseStripeJsLoaded = () => {
    return new Promise((resolve, reject) => {
        if (window.Stripe) {
            return resolve(window.Stripe);
        }
        const script = document.querySelector('#stripe-js');
        if (!script) {
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://js.stripe.com/v3/';
            script.id = 'stripe-js';
            document.body.appendChild(script);
        }
        document.querySelector('#stripe-js').addEventListener('load', () => {
            // stripe-js loaded
            resolve(window.Stripe);
        });
    });
}

/**
 * Dynamic StripeProvider that retrieves stripe publishable key and creates Stripe.js object when it's ready.
 */
const StripeDynamicProvider = ({ children }) => {
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        (async () => {
            const Stripe = await promiseStripeJsLoaded();
            setStripe(Stripe(STRIPE_PUBLISHABLE_KEY));
        })()
    }, []);

    return (
        <StripeProvider stripe={stripe}>
            {children}
        </StripeProvider>
    );
};

export default StripeDynamicProvider;