import {isAA, isAE} from "../../../constants/domains";
import Loadable from "react-loadable";
import FullScreenProgress from "../../Common/FullScreenProgress";
import React from "react";

const isAlign4FreedomSite = isAA || isAE

const DefaultLoadableAsyncRoutes = Loadable({
    loader: () => import('./defaultAsyncRoutes'),
    loading: () => <FullScreenProgress/>
});

const AlignXLoadableAsyncRoutes = Loadable({
    loader: () => import('./alingxAsyncRoutes'),
    loading: () => <FullScreenProgress/>
});

export default isAlign4FreedomSite ? AlignXLoadableAsyncRoutes : DefaultLoadableAsyncRoutes;