import {apolloClient} from '../../graphql'
import gql from "graphql-tag";

export const FOLLOW_CAMPAIGN_ACTION_MUTATION = gql`
  mutation CreateFollowing($followingInput: FollowingInput!) {
    createFollowing(followingInput: $followingInput) {
        id
        userId
        organisationId
    }
  }
`;

export function followCampaign(user, organisationId) {
    return async dispatch => {
        dispatch(followCampaignBegin());
        try {
            const followingInput = {
                userId: user.id,
                organisationId: organisationId,
                email: user.email,
                phone: user.phone,
                firstName: user.firstName,
                lastName: user.lastName
            };
            await apolloClient.mutate({
                mutation: FOLLOW_CAMPAIGN_ACTION_MUTATION,
                variables: {
                    followingInput
                },
            });
            dispatch(followCampaignSuccess());
        } catch (error) {
            dispatch(followCampaignFailure(error));
        }
    };
}

export function setPolicymakerCalled(policymaker) {
    return async dispatch => {
        dispatch(createSetPolicymakerCalled(policymaker));
    };
}

export function setPolicymakerTaggedOnFacebook(policymaker) {
    return async dispatch => {
        dispatch(createSetPolicymakerTaggedOnFacebook(policymaker));
    };
}

export const FOLLOW_CAMPAIGN_BEGIN = "FOLLOW_CAMPAIGN_BEGIN";
export const FOLLOW_CAMPAIGN_SUCCESS = "FOLLOW_CAMPAIGN_SUCCESS";
export const FOLLOW_CAMPAIGN_FAILURE = "FOLLOW_CAMPAIGN_FAILURE";

export const SET_POLICYMAKER_CALLED = "SET_POLICYMAKER_CALLED";
export const SET_POLICYMAKER_TAGGED_ON_FACEBOOK = "SET_POLICYMAKER_TAGGED_ON_FACEBOOK";

const followCampaignBegin = () => ({
    type: FOLLOW_CAMPAIGN_BEGIN,
});

const followCampaignSuccess = () => ({
    type: FOLLOW_CAMPAIGN_SUCCESS,
});

const followCampaignFailure = error => ({
    type: FOLLOW_CAMPAIGN_FAILURE,
    payload: {error}
});

const createSetPolicymakerCalled = policymaker => ({
    type: SET_POLICYMAKER_CALLED,
    payload: {policymakerId: policymaker.id}
});

const createSetPolicymakerTaggedOnFacebook = policymaker => ({
    type: SET_POLICYMAKER_TAGGED_ON_FACEBOOK,
    payload: {policymakerId: policymaker.id}
});