import {
    UPDATE_ORGANISATION_BEGIN,
    UPDATE_ORGANISATION_FAILURE,
    UPDATE_ORGANISATION_SUCCESS,
    UPLOAD_LOGO_IMAGE_BEGIN,
    UPLOAD_LOGO_IMAGE_FAILURE,
    UPLOAD_LOGO_IMAGE_SUCCESS,
    UPLOAD_WHITE_LABEL_LOGO_IMAGE_BEGIN,
    UPLOAD_WHITE_LABEL_LOGO_IMAGE_FAILURE,
    UPLOAD_WHITE_LABEL_LOGO_IMAGE_SUCCESS
} from "../components/Organisation/organisationActions";
import {
    ADMIN_ORGANISATION_SET,
    AUTH_USER_SET,
    REDIRECT_AFTER_SIGNIN_URL_SET,
    SESSION_EMAIL_SET,
    UPDATE_USER_BEGIN,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS
} from "./sessionActions";

const INITIAL_STATE = {
    authUser: null,
    redirectAfterSigninUrl: null,
    user: null,
    organisation: null,
    userLoaded: false,
    loading: false,
    logoUploading: false,
    whiteLabelLogoUploading: false,
    error: null
};

const applySetAuthUser = (state, action) => {
    const userChanging = action.user == null || state.user == null || action.user.id !== state.user.id;
    return {
        ...state,
        authUser: action.authUser,
        user: action.user,
        organisation: userChanging ? (action.user == null ? null : action.user.organisations[0]) : state.organisation,
        userLoaded: true
    }
};

const applySetSessionEmail = (state, action) => ({
    ...state,
    email: action.email
});

const applySetRedirectAfterSigninUrl = (state, action) => ({
    ...state,
    redirectAfterSigninUrl: action.redirectAfterSigninUrl.pathname ?
        action.redirectAfterSigninUrl.pathname + (action.redirectAfterSigninUrl.search || '') + (action.redirectAfterSigninUrl.hash || '') :
        action.redirectAfterSigninUrl
});

function sessionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_ORGANISATION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case UPDATE_ORGANISATION_SUCCESS:
            return {
                ...state,
                loading: false,
                user: {...state.user, organisations: [action.payload.organisation]},
                organisation: action.payload.organisation
            };

        case UPDATE_ORGANISATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case UPLOAD_LOGO_IMAGE_BEGIN:
            return {
                ...state,
                logoUploading: true,
                error: null
            };

        case UPLOAD_LOGO_IMAGE_SUCCESS:
            return {
                ...state,
                logoUploading: false,
                user: {...state.user, organisations: [action.payload.organisation]},
                organisation: action.payload.organisation
            };

        case UPLOAD_LOGO_IMAGE_FAILURE:
            return {
                ...state,
                logoUploading: false,
                error: action.payload.error.message
            };

        case UPLOAD_WHITE_LABEL_LOGO_IMAGE_BEGIN:
            return {
                ...state,
                whiteLabelLogoUploading: true,
                error: null
            };

        case UPLOAD_WHITE_LABEL_LOGO_IMAGE_SUCCESS:
            return {
                ...state,
                whiteLabelLogoUploading: false,
                user: {...state.user, organisations: [action.payload.organisation]},
                organisation: action.payload.organisation
            };

        case UPLOAD_WHITE_LABEL_LOGO_IMAGE_FAILURE:
            return {
                ...state,
                whiteLabelLogoUploading: false,
                error: action.payload.error.message
            };

        case UPDATE_USER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: {...action.payload.user},
                organisation: action.payload.user.organisations[0]
            };

        case UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case AUTH_USER_SET: {
            return applySetAuthUser(state, action);
        }
        case ADMIN_ORGANISATION_SET: {
            return {
                ...state,
                organisation: action.organisation
            }
        }
        case SESSION_EMAIL_SET: {
            return applySetSessionEmail(state, action);
        }
        case REDIRECT_AFTER_SIGNIN_URL_SET: {
            return applySetRedirectAfterSigninUrl(state, action);
        }
        default:
            return state;
    }
}

export default sessionReducer;
