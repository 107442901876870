import {
    LOAD_CAMPAIGN_EMAIL_BEGIN,
    LOAD_CAMPAIGN_EMAIL_FAILURE,
    LOAD_CAMPAIGN_EMAIL_SUCCESS,
    CREATE_CAMPAIGN_EMAIL_BEGIN,
    CREATE_CAMPAIGN_EMAIL_FAILURE,
    CREATE_CAMPAIGN_EMAIL_SUCCESS,
    SEND_TEST_EMAIL_BEGIN,
    SEND_TEST_EMAIL_FAILURE,
    SEND_TEST_EMAIL_SUCCESS,
    LIST_CAMPAIGN_EMAILS_BEGIN,
    LIST_CAMPAIGN_EMAILS_FAILURE,
    LIST_CAMPAIGN_EMAILS_SUCCESS,
} from "./campaignEmailActions";

const initialState = {
    currentEmail: null,
    campaignEmails: null,
    emailCounts: null,
    loading: false,
    error: null,
};

export default function campaignEmailsReducer(state = initialState, action) {

    switch (action.type) {
        case LOAD_CAMPAIGN_EMAIL_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LOAD_CAMPAIGN_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                currentEmail: action.payload.campaignWithEmailAndCounts.campaignEmails[0],
                emailCounts: action.payload.campaignWithEmailAndCounts.organisation
            };

        case LOAD_CAMPAIGN_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };
        case CREATE_CAMPAIGN_EMAIL_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case CREATE_CAMPAIGN_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                currentEmail: action.payload.campaignEmail
            };

        case CREATE_CAMPAIGN_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };
        case SEND_TEST_EMAIL_BEGIN:
            return {
                ...state,
                testEmailSending: true,
                error: null
            };

        case SEND_TEST_EMAIL_SUCCESS:
            return {
                ...state,
                testEmailSending: false,
            };
        case SEND_TEST_EMAIL_FAILURE:
            return {
                ...state,
                testEmailSending: false,
                error: action.payload.error.message
            };
        case LIST_CAMPAIGN_EMAILS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LIST_CAMPAIGN_EMAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                emails: action.payload.campaignEmails
            };

        case LIST_CAMPAIGN_EMAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        default:
            return state;
    }
}
