import {
    LOAD_USER_NOTIFICATION_SETTINGS_SUCCESS,
    LOAD_USER_NOTIFICATION_SETTINGS_BEGIN,
    LOAD_USER_NOTIFICATION_SETTINGS_FAILURE,
    UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
    SET_SKIP_SIGN_IN_REDIRECT,
} from "./accountSettingsActions";

const initialState = {
    settings: null,
    loading: false,
    error: null,
    skipSignInRedirect: false,
};

export default function userSettingsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_USER_NOTIFICATION_SETTINGS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LOAD_USER_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload.settings
            };

        case LOAD_USER_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message
            };

        case UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: [action.payload.settings, ...state.settings.filter(s => s.organisationId !== action.payload.settings.organisationId)]
            };

        case SET_SKIP_SIGN_IN_REDIRECT:
            return {
                ...state,
                skipSignInRedirect: action.payload.skipSignInRedirect,
            };

        default:
            return state;
    }
}

export const isSubscribedForEmailToOrganisation = (organisationId, userNotificationSettings) => {
    let organisationSettings = userNotificationSettings.filter(setting => setting.organisationId === organisationId);
    return organisationSettings.length === 0 || organisationSettings[0].campaignEmailsEnabled;
};

export const isSubscribedForSmsToOrganisation = (organisationId, userNotificationSettings) => {
    let organisationSettings = userNotificationSettings.filter(setting => setting.organisationId === organisationId);
    return organisationSettings.length && organisationSettings[0].campaignSmsEnabled;
};
